import clsx from "clsx"
import React from "react"

export const Switch = ({
  label,
  description,
  value,
  onChange,
  disabled,
}: {
  label: string
  description?: string
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}) => {
  const id = React.useId()
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col items-start gap-1">
        <label
          className={clsx({
            "cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
          })}
          htmlFor={`${id}-switch-input`}
        >
          {label}
        </label>
        {description && <p className="pr-4 text-sm text-text-primary-muted">{description}</p>}
      </div>

      <label
        className={clsx("inline-flex items-center", {
          "cursor-not-allowed opacity-50": disabled,
          "cursor-pointer": !disabled,
        })}
        htmlFor={`${id}-switch-input`}
      >
        <input
          id={`${id}-switch-input`}
          type="checkbox"
          checked={value}
          onChange={(event) => onChange(event.target.checked)}
          className="peer sr-only"
          disabled={disabled}
          aria-labelledby={`${id}-switch-label`}
        />
        <div
          className={clsx(
            "relative h-6 w-11 rounded-full bg-gray-500 transition-all duration-300 ease-in-out peer-checked:bg-brand-primary",
            {"cursor-not-allowed opacity-50": disabled, "cursor-pointer": !disabled},
          )}
        >
          <div
            className={clsx(
              "absolute top-[2px] left-[2px] h-5 w-5 transform-gpu rounded-full bg-white shadow-md transition-transform duration-300 ease-in-out",
              {"translate-x-full": value},
            )}
          />
        </div>
      </label>
    </div>
  )
}
