import invariant from "tiny-invariant"
import {Endpoints} from "~/Endpoints"
import * as AttachmentActionCreators from "~/actions/AttachmentActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {MessageDeleteFailedModal} from "~/components/alerts/MessageDeleteFailedModal"
import {MessageDeleteTooQuickModal} from "~/components/alerts/MessageDeleteTooQuickModal"
import {MessageEditFailedModal} from "~/components/alerts/MessageEditFailedModal"
import {MessageEditTooQuickModal} from "~/components/alerts/MessageEditTooQuickModal"
import {MessageSendFailedModal} from "~/components/alerts/MessageSendFailedModal"
import {MessageSendTooQuickModal} from "~/components/alerts/MessageSendTooQuickModal"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import type {HttpResponse} from "~/lib/HttpClient"
import {Logger} from "~/lib/Logger"
import type {Message} from "~/records/MessageRecord"
import DeveloperOptionsStore from "~/stores/DeveloperOptionsStore"
import type {UploadAttachment} from "~/stores/UploadAttachmentStore"

const logger = new Logger("MessageActionCreators")
const pendingDeletePromises = new Map()

type FetchMessagesResult = {
  channelId: string
  messages: Array<Message>
  isBefore: boolean
  isAfter: boolean
  hasMoreBefore: boolean
  hasMoreAfter: boolean
}

type SendMessageParams = {
  content: string
  nonce: string
  uploadAttachments: Array<UploadAttachment>
  allowedMentions?: {replied_user: boolean}
  messageReference?: {message_id: string}
  flags?: number
}

type MessageQueueItem = {
  channelId: string
  params: SendMessageParams
  resolve: (value: Message | PromiseLike<Message>) => void
  reject: (reason?: any) => void
}

const messageQueue: Array<MessageQueueItem> = []
let inFlightMessages = 0

const MAX_INFLIGHT_MESSAGES = 3

const processQueue = async () => {
  if (inFlightMessages > MAX_INFLIGHT_MESSAGES) {
    ModalActionCreators.push(MessageSendTooQuickModal)
    return
  }

  if (messageQueue.length === 0) {
    return
  }

  const {channelId, params, resolve, reject} = messageQueue.shift()!
  inFlightMessages++

  try {
    const message = await sendMessage(channelId, params)
    resolve(message)
  } catch (error) {
    reject(error)
  } finally {
    inFlightMessages--
    processQueue()
  }
}

const sendMessage = async (channelId: string, params: SendMessageParams): Promise<Message> => {
  if (DeveloperOptionsStore.getSlowMessageSend()) {
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }

  AttachmentActionCreators.replace({channelId, attachments: []})

  try {
    const {body} = await HttpClient.post<Message>({
      url: Endpoints.CHANNEL_MESSAGES(channelId),
      body: {
        content: params.content,
        nonce: params.nonce,
        attachments: params.uploadAttachments.map((attachment) => {
          invariant(attachment.uploadFilename, "Attachment uploadFilename is required")
          return {
            id: attachment.id,
            description: attachment.description,
            filename: attachment.filename,
            title: attachment.filename,
            upload_filename: attachment.uploadFilename,
          }
        }),
        allowed_mentions: params.allowedMentions,
        message_reference: params.messageReference,
        flags: params.flags,
      },
    })

    return body
  } catch (error) {
    logger.error(`Failed to send message to ${channelId}`, error)

    Dispatcher.dispatch({
      type: "MESSAGE_SEND_ERROR",
      channelId,
      nonce: params.nonce,
    })

    if (params.uploadAttachments != null) {
      AttachmentActionCreators.replace({channelId, attachments: params.uploadAttachments})
    }

    if ((error as HttpResponse).status === 429) {
      ModalActionCreators.push(MessageSendTooQuickModal)
    } else {
      ModalActionCreators.push(MessageSendFailedModal)
    }

    throw error
  }
}

export const send = async (channelId: string, params: SendMessageParams): Promise<Message> =>
  new Promise((resolve, reject) => {
    messageQueue.push({channelId, params, resolve, reject})
    processQueue()
  })

export const edit = async (channelId: string, messageId: string, params: Partial<Message>): Promise<Message> => {
  try {
    const {body} = await HttpClient.patch<Message>({
      url: Endpoints.CHANNEL_MESSAGE(channelId, messageId),
      body: params,
    })
    return body
  } catch (error) {
    if ((error as HttpResponse).status === 429) {
      ModalActionCreators.push(MessageEditTooQuickModal)
    } else {
      ModalActionCreators.push(MessageEditFailedModal)
    }
    throw error
  }
}

export const remove = async (channelId: string, messageId: string): Promise<void> => {
  const pendingPromise = pendingDeletePromises.get(messageId)
  if (pendingPromise) {
    return pendingPromise
  }

  const deletePromise = (async () => {
    try {
      await HttpClient.del({url: Endpoints.CHANNEL_MESSAGE(channelId, messageId)})
    } catch (error) {
      if ((error as HttpResponse).status === 429) {
        ModalActionCreators.push(MessageDeleteTooQuickModal)
      } else if ((error as HttpResponse).status === 404) {
        /* Message already deleted */
      } else {
        ModalActionCreators.push(MessageDeleteFailedModal)
      }
      throw error
    } finally {
      pendingDeletePromises.delete(messageId)
    }
  })()

  pendingDeletePromises.set(messageId, deletePromise)
  return deletePromise
}

export const fetch = async (
  channelId: string,
  params: {limit?: number; around?: string; before?: string; after?: string},
): Promise<FetchMessagesResult> => {
  if (DeveloperOptionsStore.getSlowMessageLoad()) {
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }

  try {
    Dispatcher.dispatch({
      type: "CHANNEL_STATE_UPDATE",
      channelId,
      state: {isLoading: true},
    })

    const timeStart = Date.now()
    const {body: messages} = await HttpClient.get<Array<Message>>({
      url: Endpoints.CHANNEL_MESSAGES(channelId),
      query: params,
    })

    const isBefore = params.before != null
    const isAfter = params.after != null
    const isReplacement = params.before == null && params.after == null
    const hasMoreBefore = params.around != null || (messages.length === params.limit && (isBefore || isReplacement))
    const hasMoreAfter = params.around != null || (isAfter && messages.length === params.limit)

    logger.info(`Fetched ${messages.length} messages for ${channelId}, took ${Date.now() - timeStart}ms`)

    Dispatcher.dispatch({
      type: "MESSAGES_FETCH_SUCCESS",
      channelId,
      messages,
    })

    Dispatcher.dispatch({
      type: "CHANNEL_STATE_UPDATE",
      channelId,
      state: {
        isLoading: false,
        hasMoreMessages: hasMoreBefore || hasMoreAfter,
        failedToLoadMessages: false,
      },
    })

    return {
      channelId,
      messages,
      isBefore,
      isAfter,
      hasMoreBefore,
      hasMoreAfter,
    }
  } catch (error) {
    logger.error(`Failed to fetch messages for ${channelId}`, error)

    Dispatcher.dispatch({
      type: "CHANNEL_STATE_UPDATE",
      channelId,
      state: {failedToLoadMessages: true},
    })

    throw error
  } finally {
    Dispatcher.dispatch({
      type: "CHANNEL_STATE_UPDATE",
      channelId,
      state: {isLoading: false, hasLoadedFirstPage: true},
    })
  }
}
