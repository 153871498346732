import debounce from "lodash/debounce"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  popouts: Record<string, Record<string, any>>
}

const initialState: State = {
  popouts: {},
}

class PopoutStore extends Store<State> {
  private isProcessing = false

  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "POPOUT_OPEN":
        return this.handleOpenPopout(action)
      case "POPOUT_CLOSE":
        return this.handleClosePopout(action)
      case "POPOUT_CLOSE_ALL":
        return this.handleCloseAll()
      case "POPOUT_NEEDS_RERENDER":
        return this.handleNeedRerender(action)
      case "POPOUT_DID_RERENDER":
        return this.handleDidRerender(action)
      default:
        return false
    }
  }

  isOpen(key: string | number) {
    return this.state.popouts[key] != null
  }

  getPopouts() {
    return Object.values(this.state.popouts)
  }

  private handleOpenPopout = debounce(({popout}: {popout: Record<string, any>}) => {
    if (this.isProcessing) {
      return
    }
    this.isProcessing = true

    this.setState((prev) => {
      const newPopouts = popout.dependsOn ? {...prev.popouts} : {}
      newPopouts[popout.key] = popout
      this.isProcessing = false
      return {...prev, popouts: newPopouts}
    })
  }, 100)

  private handleNeedRerender({key}: {key: string | number}) {
    this.setState((prev) => {
      if (!prev.popouts[key]) {
        return prev
      }
      return {
        ...prev,
        popouts: {
          ...prev.popouts,
          [key]: {
            ...prev.popouts[key],
            needsRerender: true,
          },
        },
      }
    })
  }

  private handleDidRerender({key}: {key: string | number}) {
    this.setState((prev) => {
      if (!prev.popouts[key]) {
        return prev
      }
      return {
        ...prev,
        popouts: {
          ...prev.popouts,
          [key]: {
            ...prev.popouts[key],
            needsRerender: false,
          },
        },
      }
    })
  }

  private handleClosePopout = debounce(({key}: {key?: string | number}) => {
    if (this.isProcessing) {
      return
    }
    this.isProcessing = true

    this.setState((prev) => {
      if (key == null) {
        this.isProcessing = false
        return {...prev, popouts: {}}
      }

      if (!prev.popouts[key] || this.hasDependencies(key, prev.popouts)) {
        this.isProcessing = false
        return prev
      }

      const newPopouts = {...prev.popouts}
      delete newPopouts[key]
      this.isProcessing = false
      return {...prev, popouts: newPopouts}
    })
  }, 100)

  private handleCloseAll() {
    this.setState((prev) => {
      const newPopouts = {...prev.popouts}
      return {...prev, popouts: newPopouts, lockedPopoutKey: null}
    })
  }

  private hasDependencies(key: string | number, popouts: Record<string, any>) {
    return Object.values(popouts).some((popout) => popout.dependsOn === key)
  }
}

export default new PopoutStore()
