import React from "react"
import * as TooltipActionCreators from "~/actions/TooltipActionCreators"
import type {TooltipPosition, TooltipType} from "~/components/uikit/Tooltip"
import {useMergeRefs} from "~/hooks/useMergeRefs"
import TooltipStore from "~/stores/TooltipStore"

type TooltipProps = {
  text: string | (() => React.ReactNode)
  type?: TooltipType
  position?: TooltipPosition
  align?: "center" | "top" | "bottom" | "left" | "right"
  nudge?: number
  delay?: number
  padding?: number
  maxWidth?: "default" | "xl" | "none"
  children?: React.ReactElement
}

export const Tooltip = ({
  text,
  type = "normal",
  position = "top",
  align = "center",
  nudge = 0,
  delay = 0,
  padding = 8,
  maxWidth = "default",
  children,
}: TooltipProps) => {
  const id = React.useId()
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)
  const tooltipRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (TooltipStore.isOpen(id)) {
      showTooltip()
    }
    return () => {
      hideTooltip()
    }
  }, [id])

  const showDelayedTooltip = () => {
    if (!timerRef.current) {
      timerRef.current = setTimeout(showTooltip, delay)
    }
  }

  const showTooltip = () => {
    const node = tooltipRef.current
    if (!node) {
      return
    }
    const {top, left, right, bottom} = node.getBoundingClientRect()
    clearTimer()
    TooltipActionCreators.show(id, {
      position,
      align,
      text,
      type,
      targetWidth: right - left,
      targetHeight: bottom - top,
      x: left,
      y: top,
      nudge,
      padding,
      maxWidth,
    })
  }

  const hideTooltip = () => {
    clearTimer()
    if (TooltipStore.isOpen(id)) {
      TooltipActionCreators.hide(id)
    }
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }

  if (children) {
    const child = React.Children.only(children)
    const {onMouseOver, onMouseOut, onClick, onContextMenu} = child.props
    const mergedRef = useMergeRefs([tooltipRef, (child as any).ref])

    return React.cloneElement(child, {
      ref: mergedRef,
      "aria-describedby": id,
      "aria-haspopup": "true",
      "aria-expanded": TooltipStore.isOpen(id) ? "true" : "false",
      "aria-controls": TooltipStore.isOpen(id) ? id : undefined,
      "aria-label": typeof text === "string" ? text : undefined,
      "aria-labelledby": typeof text === "string" ? undefined : id,
      onMouseEnter: (event: React.MouseEvent) => {
        if (delay) {
          showDelayedTooltip()
        } else {
          showTooltip()
        }
        onMouseOver?.(event)
      },
      onMouseLeave: (event: React.MouseEvent) => {
        hideTooltip()
        onMouseOut?.(event)
      },
      onClick: (event: React.MouseEvent) => {
        hideTooltip()
        onClick?.(event)
      },
      onContextMenu: (event: React.MouseEvent) => {
        hideTooltip()
        onContextMenu?.(event)
      },
    })
  }

  return null
}
