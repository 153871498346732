import type {SpaceRoleRecord} from "~/records/SpaceRoleRecord"
import type {UserPartial} from "~/records/UserRecord"
import {UserRecord} from "~/records/UserRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import SpaceStore from "~/stores/SpaceStore"
import * as ColorUtils from "~/utils/ColorUtils"

export type SpaceMember = {
  user: UserPartial
  avatar: string | null
  nickname: string | null
  roles: Array<string>
  joined_at: number
}

export class SpaceMemberRecord {
  spaceId: string
  user: UserRecord
  avatar: string | null
  nickname: string | null
  roles: Set<string>
  joinedAt: number

  constructor(spaceId: string, spaceMember: SpaceMember) {
    this.spaceId = spaceId
    this.user = new UserRecord(spaceMember.user)
    this.avatar = spaceMember.avatar
    this.nickname = spaceMember.nickname
    this.roles = new Set(spaceMember.roles)
    this.joinedAt = spaceMember.joined_at
  }

  toJSON(): SpaceMember {
    return {
      user: this.user.toJSON(),
      avatar: this.avatar,
      nickname: this.nickname,
      roles: [...this.roles],
      joined_at: this.joinedAt,
    }
  }

  getSortedRoles(): Array<SpaceRoleRecord> {
    const space = SpaceStore.getSpace(this.spaceId)
    if (!space) {
      return []
    }
    return [...this.roles]
      .sort()
      .map((roleId) => space.roles[roleId])
      .sort((a, b) => a.position - b.position)
  }

  isCurrentUser(): boolean {
    return this.user.id === AuthenticationStore.getId()
  }

  getHighestRoleColor(): string | undefined {
    const space = SpaceStore.getSpace(this.spaceId)
    if (!space) {
      return
    }
    const highestRole = this.getSortedRoles().find((role) => role.color)
    if (!highestRole) {
      return
    }
    return ColorUtils.int2rgb(highestRole.color)
  }
}
