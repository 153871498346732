import * as PopoutActionCreators from "~/actions/PopoutActionCreators"
import * as SpaceMemberActionCreators from "~/actions/SpaceMemberActionCreators"
import {ScrollArea} from "~/components/uikit/ScrollArea"
import {i18n} from "~/i18n"
import type {SpaceMemberRecord} from "~/records/SpaceMemberRecord"
import type {SpaceRecord} from "~/records/SpaceRecord"
import * as ColorUtils from "~/utils/ColorUtils"

export const RolePopout = ({
  popoutId,
  space,
  member,
}: {
  popoutId: string | number
  space: SpaceRecord
  member: SpaceMemberRecord
}) => {
  const roles = Object.values(space.roles)
    .filter((role) => !(role.isEveryone || member.roles.has(role.id)))
    .sort((a, b) => a.position - b.position)

  return (
    <div className="w-[250px] overflow-hidden rounded-md border border-background-header-secondary bg-background-primary p-2">
      <ScrollArea className="flex h-[280px] flex-col gap-1">
        {roles.length === 0 && (
          <div className="text-center text-text-secondary">{i18n.Messages.NO_ROLES_AVAILABLE}</div>
        )}
        {roles.map((role) => (
          <button
            key={role.id}
            type="button"
            className="flex w-full cursor-pointer flex-row items-center gap-2 rounded-md px-[8px] py-[6px] font-medium text-text-tertiary hover:bg-background-modifier-hover"
            onClick={() => {
              SpaceMemberActionCreators.update(space.id, member.user.id, {roles: [...member.roles, role.id]})
              PopoutActionCreators.close(popoutId)
            }}
          >
            <div className="h-4 w-4 rounded-full" style={{backgroundColor: ColorUtils.int2rgb(role.color)}} />
            {role.name}
          </button>
        ))}
      </ScrollArea>
    </div>
  )
}
