export type AuthSession = {
  id: string
  approx_last_used_at: number
  client_os: string
  client_platform: string
  client_location: string
}

export class AuthSessionRecord {
  id: string
  approxLastUsedAt: number
  clientOs: string
  clientPlatform: string
  clientLocation: string

  constructor(data: AuthSession) {
    this.id = data.id
    this.approxLastUsedAt = data.approx_last_used_at
    this.clientOs = data.client_os
    this.clientPlatform = data.client_platform
    this.clientLocation = data.client_location
  }
}
