import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {UserSettingsModal} from "~/components/modals/UserSettingsModal"
import Dispatcher from "~/flux/Dispatcher"
import type {UserSettingsTabType} from "~/stores/UserSettingsModalStore"

export const openUserSettingsTab = (tab: UserSettingsTabType) => {
  selectTab(tab)
  ModalActionCreators.push(UserSettingsModal)
}

export const selectTab = (tab: UserSettingsTabType) => {
  Dispatcher.dispatch({type: "USER_SETTINGS_MODAL_TAB_SELECT", tab})
}
