import type React from "react"
import {
  ChannelTypes,
  type MessageEmbedType,
  MessageFlags,
  type MessageState,
  MessageStates,
  type MessageType,
  MessageTypes,
} from "~/Constants"
import type {ChannelRecord} from "~/records/ChannelRecord"
import type {UserPartial} from "~/records/UserRecord"
import {UserRecord} from "~/records/UserRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import ChannelStore from "~/stores/ChannelStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import SpaceStore from "~/stores/SpaceStore"
import UserStore from "~/stores/UserStore"
import * as InviteUtils from "~/utils/InviteUtils"
import * as MarkupUtils from "~/utils/MarkupUtils"
import {type ReactionEmoji, emojiEquals} from "~/utils/ReactionUtils"

export type EmbedAuthor = {
  name: string
  url?: string
  icon_url?: string
  proxy_icon_url?: string
}

export type EmbedFooter = {
  text: string
  icon_url?: string
  proxy_icon_url?: string
}

export type EmbedMedia = {
  url: string
  proxy_url?: string
  width?: number
  height?: number
  placeholder?: string
  flags: number
}

export type EmbedField = {
  name: string
  value: string
  inline: boolean
}

export type MessageEmbed = {
  id: string
  type: MessageEmbedType
  url?: string
  title?: string
  color?: number
  timestamp?: number
  description?: string
  author?: EmbedAuthor
  image?: EmbedMedia
  thumbnail?: EmbedMedia
  footer?: EmbedFooter
  fields?: Array<EmbedField>
  provider?: EmbedAuthor
  video?: EmbedMedia
  audio?: EmbedMedia
  flags?: number
}

export type MessageReference = {
  message_id: string
  channel_id: string
  space_id?: string
}

export type MessageReaction = {
  emoji: ReactionEmoji
  count: number
  me: boolean
}

export type MessageAttachment = {
  id: string
  filename: string
  title?: string
  description?: string
  content_type?: string
  size: number
  url: string
  proxy_url: string
  width?: number
  height?: number
  duration?: number
  placeholder?: string
  flags: number
}

export type Message = {
  id: string
  channel_id: string
  author: UserPartial | UserRecord
  webhook_id?: string
  type: MessageType
  flags: number
  content: string
  created_at: number
  edited_at: number | null
  mentions: Array<UserPartial | UserRecord>
  mention_roles: Array<string>
  embeds: Array<MessageEmbed>
  attachments: Array<MessageAttachment>
  reactions?: Array<MessageReaction>
  message_reference?: MessageReference
  referenced_message?: Message | null
  allowed_mentions?: {replied_user: boolean}
  state?: MessageState
  nonce?: string
}

export type MessagePartial = Pick<Message, "id" | "channel_id" | "embeds" | "attachments">

const MAX_EMOJI_TO_BE_JUMBO = 27

export const checkForJumboEmojiWithInline = (ast: any, inline: boolean) => {
  if (inline) {
    return checkForJumboEmoji(ast)
  }
  if (ast[0].type !== "paragraph") {
    return ast
  }
  if (Array.isArray(ast[0].content)) {
    ast[0].content = checkForJumboEmoji(ast[0].content)
  }
  return ast
}

const checkForJumboEmoji = (ast: any) => {
  const hasAnyNonEmoji = ast.some((e: any) => {
    if (e.type === "emoji" || e.type === "customEmoji") {
      return false
    }
    if (typeof e.content === "string" && e.content.trim() === "") {
      return false
    }
    return true
  })

  if (hasAnyNonEmoji) {
    return ast
  }

  let numEmoji = 0
  for (const e of ast) {
    if (e.type === "emoji" || e.type === "customEmoji") {
      numEmoji += 1
    }

    if (numEmoji > MAX_EMOJI_TO_BE_JUMBO) {
      return ast
    }
  }

  if (numEmoji > MAX_EMOJI_TO_BE_JUMBO) {
    return ast
  }

  for (const e of ast) {
    e.jumboable = true
  }

  return ast
}

export const messageMentionsCurrentUser = (message: Message) => {
  const channel = ChannelStore.getChannel(message.channel_id)
  if (!channel || channel.type === ChannelTypes.SPACE_DOCUMENT) {
    return false
  }
  if (message.flags & MessageFlags.MENTION_EVERYONE) {
    return true
  }
  if (message.mentions.some((user) => user.id === AuthenticationStore.getId())) {
    return true
  }
  if (!channel.spaceId) {
    return false
  }
  const space = SpaceStore.getSpace(channel.spaceId)
  if (!space) {
    return false
  }
  const spaceMember = SpaceMemberStore.getMember(space.id, AuthenticationStore.getId())
  if (!spaceMember) {
    return false
  }
  return message.mention_roles.some((roleId) => spaceMember.roles.has(roleId))
}

export class MessageRecord {
  id: string
  channelId: string
  author: UserRecord
  webhookId?: string
  type: MessageType
  flags: number
  content: string
  contentParsed?: React.ReactNode
  invites: Array<string>
  createdAt: number
  editedAt: number | null
  mentions: Array<UserRecord>
  mentionRoles: Array<string>
  embeds: Array<MessageEmbed>
  attachments: Array<MessageAttachment>
  reactions: Array<MessageReaction>
  messageReference?: MessageReference
  allowedMentions?: {replied_user: boolean}
  state: MessageState
  nonce?: string

  constructor(message: Message) {
    this.id = message.id
    this.channelId = message.channel_id
    this.author = message.webhook_id
      ? message.author instanceof UserRecord
        ? message.author
        : new UserRecord(message.author)
      : UserStore.getUser(message.author.id)!
    this.webhookId = message.webhook_id
    this.type = message.type
    this.flags = message.flags
    this.content = message.content
    this.contentParsed = MarkupUtils.parse(
      message.content,
      undefined,
      {messageId: message.id, channelId: message.channel_id},
      (ast, inline) => checkForJumboEmojiWithInline(ast, inline),
    )
    this.invites = InviteUtils.findInvites(message.content)
    this.createdAt = message.created_at
    this.editedAt = message.edited_at
    this.mentions = message.mentions.map((user) => (user instanceof UserRecord ? user : new UserRecord(user)))
    this.mentionRoles = message.mention_roles
    this.embeds = message.embeds
    this.attachments = message.attachments
    this.reactions = message.reactions ?? []
    this.messageReference = message.message_reference
    this.allowedMentions = message.allowed_mentions
    this.state = message.state ?? MessageStates.SENT
    this.nonce = message.nonce
  }

  getChannel(): ChannelRecord {
    return ChannelStore.getChannel(this.channelId)!
  }

  updateMessage(newMessage: Partial<Message>): MessageRecord {
    const message = new MessageRecord(this.toJSON())
    if (newMessage.author != null) {
      message.author = newMessage.author instanceof UserRecord ? newMessage.author : new UserRecord(newMessage.author)
    }
    if (newMessage.flags != null) {
      message.flags = newMessage.flags
    }
    if (newMessage.content != null) {
      message.content = newMessage.content
      message.contentParsed = MarkupUtils.parse(
        newMessage.content,
        undefined,
        {messageId: this.id, channelId: this.channelId},
        (ast, inline) => checkForJumboEmojiWithInline(ast, inline),
      )
      message.invites = InviteUtils.findInvites(newMessage.content)
    }
    if (newMessage.edited_at != null) {
      message.editedAt = newMessage.edited_at
    }
    if (newMessage.mentions != null) {
      message.mentions = newMessage.mentions.map((user) => (user instanceof UserRecord ? user : new UserRecord(user)))
    }
    if (newMessage.mention_roles != null) {
      message.mentionRoles = newMessage.mention_roles
    }
    if (newMessage.embeds != null) {
      message.embeds = newMessage.embeds
    }
    if (newMessage.attachments != null) {
      message.attachments = newMessage.attachments
    }
    return message
  }

  getReaction(emoji: ReactionEmoji): MessageReaction | undefined {
    return this.reactions.find((r) => emojiEquals(r.emoji, emoji))
  }

  addReaction(emoji: ReactionEmoji, me = false): MessageRecord {
    let index = -1
    const newReactions = this.reactions.map((reaction, i) => {
      if (emojiEquals(reaction.emoji, emoji)) {
        // biome-ignore lint/style/noParameterAssign: <explanation>
        reaction = {
          ...reaction,
          count: me && reaction.me ? reaction.count : reaction.count + 1,
          me: me ? true : reaction.me,
        }
        index = i
      }
      return reaction
    })

    if (index === -1) {
      newReactions.push({
        emoji,
        me,
        count: 1,
      })
    }

    this.reactions = newReactions
    return this
  }

  removeReaction(emoji: ReactionEmoji, me = false): MessageRecord {
    let index = -1
    const newReactions = this.reactions.map((reaction, i) => {
      if (emojiEquals(reaction.emoji, emoji)) {
        // biome-ignore lint/style/noParameterAssign: <explanation>
        reaction = {
          ...reaction,
          count: me && !reaction.me ? reaction.count : reaction.count - 1,
          me: me ? false : reaction.me,
        }
        index = i
      }
      return reaction
    })

    if (index !== -1 && newReactions[index].count <= 0) {
      newReactions.splice(index, 1)
    }

    this.reactions = newReactions
    return this
  }

  removeReactionEmoji(emoji: ReactionEmoji): MessageRecord {
    this.reactions = this.reactions.filter((reaction) => !emojiEquals(reaction.emoji, emoji))
    return this
  }

  toJSON(): Message {
    return {
      id: this.id,
      channel_id: this.channelId,
      author: this.author.toJSON(),
      webhook_id: this.webhookId,
      type: this.type,
      flags: this.flags,
      content: this.content,
      created_at: this.createdAt,
      edited_at: this.editedAt,
      mentions: this.mentions.map((user) => user.toJSON()),
      mention_roles: this.mentionRoles,
      embeds: this.embeds,
      attachments: this.attachments,
      reactions: this.reactions,
      message_reference: this.messageReference,
      allowed_mentions: this.allowedMentions,
      state: this.state,
      nonce: this.nonce,
    }
  }

  isUserMessage(): boolean {
    return this.type === MessageTypes.DEFAULT || this.type === MessageTypes.REPLY
  }

  isAuthor(userId: string): boolean {
    return this.author.id === userId
  }

  isCurrentUserAuthor(): boolean {
    return this.isAuthor(AuthenticationStore.getId())
  }

  isMentioned(): boolean {
    return (
      this.mentions.some((user) => user.id === AuthenticationStore.getId()) ||
      (this.flags & MessageFlags.MENTION_EVERYONE) !== 0 ||
      this.mentionRoles.some((roleId) =>
        SpaceMemberStore.getMember(this.getChannel().spaceId, AuthenticationStore.getId())?.roles.has(roleId),
      )
    )
  }

  get shouldSuppressEmbeds(): boolean {
    return (this.flags & MessageFlags.SUPPRESS_EMBEDS) !== 0
  }
}
