import React from "react"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {Message} from "~/components/channel/Message"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button, type ButtonProps} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import type {MessageRecord} from "~/records/MessageRecord"

export const ConfirmModal = ({
  title,
  description,
  message,
  primaryText,
  primaryVariant = "danger",
  secondaryText = i18n.Messages.NEVERMIND,
  size = "small",
  onPrimary,
}: {
  title: string
  description: React.ReactNode
  message?: MessageRecord
  primaryText?: string
  primaryVariant?: ButtonProps["variant"]
  secondaryText?: string
  size?: Modal.ModalProps["size"]
  onPrimary?: () => Promise<void>
}) => {
  const [submitting, setSubmitting] = React.useState(false)
  const initialFocusRef = React.useRef<HTMLButtonElement | null>(null)

  const handlePrimaryClick = React.useCallback(async () => {
    if (!onPrimary) {
      return
    }
    setSubmitting(true)
    try {
      await onPrimary()
      ModalActionCreators.pop()
    } finally {
      setSubmitting(false)
    }
  }, [onPrimary])

  return (
    <Modal.Root label={title} size={size} initialFocusRef={initialFocusRef}>
      <Modal.Header title={title} />
      <Modal.Content className={styles.content}>
        {description}
        {message && (
          <div className="pointer-events-none relative overflow-hidden rounded-md border border-background-header-secondary bg-background-chat-primary py-2">
            <Message channel={message.getChannel()} message={message} isPreview={true} />
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className={styles.footer}>
          <Button onClick={ModalActionCreators.pop} variant="ghost">
            {secondaryText}
          </Button>
          {onPrimary && primaryText && (
            <Button onClick={handlePrimaryClick} submitting={submitting} variant={primaryVariant} ref={initialFocusRef}>
              {primaryText}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal.Root>
  )
}
