import clsx from "clsx"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as TextCopyActionCreators from "~/actions/TextCopyActionCreators"
import {BackupCodesRegenerateModal} from "~/components/modals/BackupCodesRegenerateModal"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import {CheckFatIcon} from "~/icons/CheckFatIcon"
import {CopyIcon} from "~/icons/CopyIcon"
import {DownloadIcon} from "~/icons/DownloadIcon"
import type {BackupCode} from "~/records/UserRecord"
import UserStore from "~/stores/UserStore"

export const BackupCodesModal = ({backupCodes}: {backupCodes: Array<BackupCode>}) => {
  const user = UserStore.getCurrentUser()!
  return (
    <Modal.Root label={i18n.Messages.BACKUP_CODES} size="small">
      <Modal.Header title={i18n.Messages.BACKUP_CODES} />

      <Modal.Content className={styles.content}>
        <p>{i18n.Messages.BACKUP_CODES_MODAL_DESCRIPTION_1}</p>
        <p>{i18n.Messages.BACKUP_CODES_MODAL_DESCRIPTION_2}</p>

        <div className="my-2 grid grid-cols-2 gap-4">
          {backupCodes.map(({code, consumed}) => (
            <div key={code} className="flex items-center gap-2">
              <div
                className={clsx(
                  "relative flex h-5 w-5 items-center justify-center rounded-md",
                  consumed ? "bg-brand-primary" : "border border-text-primary-muted",
                )}
              >
                {consumed && <CheckFatIcon className="h-3 w-3" />}
              </div>
              <pre className={clsx("select-text text-sm text-text-primary", consumed && "line-through")}>{code}</pre>
            </div>
          ))}
        </div>

        <div className="flex gap-3">
          <Button
            variant="primary"
            leftIcon={<DownloadIcon className="h-5 w-5" />}
            small={true}
            onClick={() => {
              const blob = new Blob([backupCodes.map(({code}) => code).join("\n")], {type: "text/plain"})
              const url = URL.createObjectURL(blob)
              const a = document.createElement("a")
              a.href = url
              a.download = `fluxer_${user.email}_backup_codes.txt`
              a.click()
              URL.revokeObjectURL(url)
            }}
          />

          <Button
            variant="secondary"
            small={true}
            leftIcon={<CopyIcon className="h-5 w-5" />}
            onClick={() => TextCopyActionCreators.copy(backupCodes.map(({code}) => code).join("\n"))}
          />

          <Button
            variant="danger-outline"
            small={true}
            onClick={() => ModalActionCreators.push(() => <BackupCodesRegenerateModal />)}
          >
            {i18n.Messages.REGENERATE_BACKUP_CODES}
          </Button>
        </div>
      </Modal.Content>

      <Modal.Footer>
        <div className={styles.footer}>
          <Button onClick={ModalActionCreators.pop} variant="brand">
            {i18n.Messages.I_HAVE_SAVED_THE_CODES}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Root>
  )
}
