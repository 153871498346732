import type {TooltipItemProps} from "~/components/uikit/Tooltip"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  tooltips: Record<string, TooltipItemProps>
}

const initialState: State = {
  tooltips: {},
}

class TooltipStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "TOOLTIP_SHOW":
        return this.handleShowTooltip(action)
      case "TOOLTIP_HIDE":
        return this.handleHideTooltip(action)
      default:
        return false
    }
  }

  getTooltips() {
    return this.state.tooltips
  }

  isOpen(id: string) {
    return this.state.tooltips[id] != null
  }

  private handleShowTooltip({id, tooltip}: {id: string; tooltip: TooltipItemProps}) {
    this.setState((state) => ({tooltips: {...state.tooltips, [id]: tooltip}}))
  }

  private handleHideTooltip({id}: {id: string}) {
    const {[id]: _, ...tooltips} = this.state.tooltips
    this.setState({tooltips})
  }
}

export default new TooltipStore()
