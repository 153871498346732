export const IdIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 56C24 47.1634 31.1634 40 40 40H216C224.837 40 232 47.1634 232 56V200C232 208.837 224.837 216 216 216H40C31.1634 216 24 208.837 24 200V56ZM139 80H117.667C114.838 80 112.125 81.1238 110.124 83.1242C108.124 85.1246 107 87.8377 107 90.6667V165.333C107 168.162 108.124 170.875 110.124 172.876C112.125 174.876 114.838 176 117.667 176H138.107C164.333 176 186.4 155.36 187 129.16C187.155 122.76 186.027 116.393 183.685 110.435C181.342 104.477 177.831 99.0473 173.358 94.4665C168.885 89.8857 163.541 86.2459 157.641 83.7615C151.74 81.2771 145.402 79.9981 139 80ZM138.347 154.667H128.333V101.333H139C142.59 101.331 146.144 102.053 149.448 103.457C152.752 104.861 155.739 106.917 158.229 109.503C160.719 112.089 162.661 115.151 163.939 118.506C165.217 121.861 165.805 125.439 165.667 129.027C165.12 143.453 152.8 154.667 138.36 154.667H138.347ZM90.3243 164.955V90.6849C90.3275 89.2233 90.0302 87.7767 89.4508 86.4349C88.8714 85.0931 88.0223 83.8848 86.9562 82.8849C85.8901 81.8851 84.6299 81.1152 83.2537 80.6229C81.8775 80.1306 80.4149 79.9266 78.9565 80.0234C76.2103 80.2592 73.6557 81.5278 71.8082 83.5733C69.9606 85.6187 68.9575 88.2887 69.0014 91.0447V165.315C68.9982 166.777 69.2955 168.223 69.8749 169.565C70.4543 170.907 71.3034 172.115 72.3695 173.115C73.4356 174.115 74.6958 174.885 76.072 175.377C77.4481 175.869 78.9108 176.073 80.3692 175.977C83.1154 175.741 85.67 174.472 87.5175 172.427C89.3651 170.381 90.3681 167.711 90.3243 164.955Z"
      fill="currentColor"
    />
  </svg>
)
