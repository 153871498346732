import _ from "lodash"
import type React from "react"
import {UserSettingsModal} from "~/components/modals/UserSettingsModal"
import Dispatcher from "~/flux/Dispatcher"
import ModalStore from "~/stores/ModalStore"

export type ModalRender = React.ComponentType<any>

export const push = (modal: ModalRender, customId?: string, props?: Record<string, any>) => {
  if (modal === UserSettingsModal && ModalStore.isModalOpen(UserSettingsModal)) {
    return
  }
  const key = customId ?? _.uniqueId("modal")
  Dispatcher.dispatch({type: "MODAL_PUSH", modal, props, key})
}

export const update = (key: string, props: Record<string, any>, partial = true) =>
  Dispatcher.dispatch({type: "MODAL_UPDATE", key, props, partial})

export const pop = () => Dispatcher.dispatch({type: "MODAL_POP"})

export const popWithKey = (key: string) => Dispatcher.dispatch({type: "MODAL_POP", key})
