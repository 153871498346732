import * as AvatarUtils from "~/utils/AvatarUtils"

export type SpaceEmoji = {
  id: string
  name: string
  animated: boolean
}

export class SpaceEmojiRecord {
  id: string
  spaceId: string
  name: string
  uniqueName: string
  allNamesString: string
  url: string
  animated: boolean

  constructor(spaceId: string, data: SpaceEmoji) {
    this.id = data.id
    this.spaceId = spaceId
    this.name = data.name
    this.uniqueName = data.name
    this.allNamesString = `:${data.name}:`
    this.url = AvatarUtils.getEmojiURL({id: data.id, animated: data.animated})
    this.animated = data.animated
  }
}
