import {Endpoints} from "~/Endpoints"
import * as ToastActionCreators from "~/actions/ToastActionCreators"
import Dispatcher from "~/flux/Dispatcher"
import {i18n} from "~/i18n"
import * as HttpClient from "~/lib/HttpClient"
import type {Message} from "~/records/MessageRecord"

export const fetch = async () => {
  Dispatcher.dispatch({type: "SAVED_MESSAGES_FETCH_PENDING"})
  try {
    const {body} = await HttpClient.get<Array<Message>>({url: Endpoints.USER_SAVED_MESSAGES})
    Dispatcher.dispatch({
      type: "SAVED_MESSAGES_FETCH_SUCCESS",
      messages: body,
    })
  } catch (error) {
    Dispatcher.dispatch({type: "SAVED_MESSAGES_FETCH_ERROR", error})
    throw error
  }
}

export const create = async (channelId: string, messageId: string) => {
  await HttpClient.post({
    url: Endpoints.USER_SAVED_MESSAGES,
    body: {channel_id: channelId, message_id: messageId},
  })
  ToastActionCreators.createToast({
    type: "success",
    children: i18n.Messages.SAVED_FOR_LATER,
  })
}

export const remove = async (messageId: string) => {
  Dispatcher.dispatch({type: "SAVED_MESSAGE_DELETE", messageId})
  await HttpClient.del({url: Endpoints.USER_SAVED_MESSAGE(messageId)})
}
