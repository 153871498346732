import {MessageStates} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import Dispatcher from "~/flux/Dispatcher"
import {Store} from "~/flux/Store"
import type {Channel} from "~/records/ChannelRecord"
import {type Message, type MessagePartial, MessageRecord} from "~/records/MessageRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import ChannelStore from "~/stores/ChannelStore"
import type {ReactionEmoji} from "~/utils/ReactionUtils"

type State = {
  messages: {[key: string]: Array<MessageRecord>}
}

const initialState: State = {
  messages: {},
}

class MessageStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen()
      case "MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      case "MESSAGE_UPDATE":
        return this.handleMessageUpdate(action)
      case "MESSAGE_REACTION_ADD":
        return this.handleMessageReactionAdd(action)
      case "MESSAGE_REACTION_REMOVE":
        return this.handleMessageReactionRemove(action)
      case "MESSAGE_REACTION_REMOVE_ALL":
        return this.handleMessageReactionRemoveAll(action)
      case "MESSAGE_REACTION_REMOVE_EMOJI":
        return this.handleMessageReactionRemoveEmoji(action)
      case "MESSAGE_DELETE":
        return this.handleMessageDelete(action)
      case "MESSAGE_DELETE_BULK":
        return this.handleMessageDeleteBulk(action)
      case "CHANNEL_DELETE":
        return this.handleChannelDelete(action)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "MESSAGE_SEND_ERROR":
        return this.handleMessageSendError(action)
      case "MESSAGES_FETCH_SUCCESS":
        return this.handleMessagesFetchSuccess(action)
      default:
        return false
    }
  }

  getMessage(channelId: string, messageId: string): MessageRecord | null {
    const channelMessages = this.state.messages[channelId]
    if (!channelMessages) {
      return null
    }
    return channelMessages.find((message) => message.id === messageId) ?? null
  }

  getLastEditableMessage(channelId: string): MessageRecord | null {
    const channelMessages = this.state.messages[channelId]
    if (!channelMessages) {
      return null
    }
    const lastMessage = [...channelMessages].reverse().find((message) => {
      return (
        message.isUserMessage() &&
        message.author.id === AuthenticationStore.getId() &&
        message.state === MessageStates.SENT
      )
    })
    return lastMessage ?? null
  }

  getCountBelowMessage(channelId: string, messageId: string, mentionsOnly = false): number {
    const channelMessages = this.state.messages[channelId]
    if (!channelMessages) {
      return 0
    }
    const messageIndex = channelMessages.findIndex((message) => message.id === messageId)
    if (messageIndex === -1) {
      return 0
    }
    if (mentionsOnly) {
      const messagesAfterGivenId = channelMessages.slice(messageIndex + 1)
      return messagesAfterGivenId.filter((message) => message.isMentioned()).length
    }
    return channelMessages.length - 1 - messageIndex
  }

  useMessages(channelId: string): Array<MessageRecord> {
    const {messages} = this.useStore()
    return messages[channelId] ?? []
  }

  useMessage(channelId: string, messageId: string): MessageRecord | null {
    const {messages} = this.useStore()
    const channelMessages = messages[channelId]
    if (!channelMessages) {
      return null
    }
    return channelMessages.find((message) => message.id === messageId) ?? null
  }

  private handleConnectionOpen() {
    this.setState(initialState)
  }

  private handleMessageCreate({message, optimistic}: {message: Message; optimistic?: boolean}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[message.channel_id] ?? []
      const existingMessageById = channelMessages.find((m) => m.id === message.id)
      if (existingMessageById) {
        return prevState
      }

      if (!optimistic && message.nonce != null && message.author.id === AuthenticationStore.getId()) {
        const existingMessageByNonceIndex = channelMessages.findIndex((m) => m.nonce === message.nonce)
        if (existingMessageByNonceIndex !== -1) {
          channelMessages[existingMessageByNonceIndex].id = message.id
          channelMessages[existingMessageByNonceIndex].state = MessageStates.SENT
          channelMessages[existingMessageByNonceIndex] = new MessageRecord(message)
          return {
            messages: {
              ...prevState.messages,
              [message.channel_id]: [...channelMessages],
            },
          }
        }
      }

      channelMessages.push(new MessageRecord(message))
      return {
        messages: {
          ...prevState.messages,
          [message.channel_id]: [...channelMessages],
        },
      }
    })
  }

  private handleMessageUpdate({message}: {message: Message | MessagePartial}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[message.channel_id] ?? []
      const existingMessageIndex = channelMessages.findIndex((m) => m.id === message.id)
      if (existingMessageIndex !== -1) {
        const existingMessage = channelMessages[existingMessageIndex]
        const updatedMessage = existingMessage.updateMessage(message)
        channelMessages[existingMessageIndex] = updatedMessage
        return {
          messages: {
            ...prevState.messages,
            [message.channel_id]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessageReactionAdd({
    channelId,
    messageId,
    userId,
    emoji,
  }: {channelId: string; messageId: string; userId: string; emoji: ReactionEmoji}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const existingMessageIndex = channelMessages.findIndex((m) => m.id === messageId)
      if (existingMessageIndex !== -1) {
        const existingMessage = channelMessages[existingMessageIndex]
        const updatedMessage = existingMessage.addReaction(emoji, userId === AuthenticationStore.getId())
        channelMessages[existingMessageIndex] = updatedMessage
        return {
          messages: {
            ...prevState.messages,
            [channelId]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessageReactionRemove({
    channelId,
    messageId,
    userId,
    emoji,
  }: {channelId: string; messageId: string; userId: string; emoji: ReactionEmoji}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const existingMessageIndex = channelMessages.findIndex((m) => m.id === messageId)
      if (existingMessageIndex !== -1) {
        const existingMessage = channelMessages[existingMessageIndex]
        const updatedMessage = existingMessage.removeReaction(emoji, userId === AuthenticationStore.getId())
        channelMessages[existingMessageIndex] = updatedMessage
        return {
          messages: {
            ...prevState.messages,
            [channelId]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessageReactionRemoveAll({channelId, messageId}: {channelId: string; messageId: string}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const existingMessageIndex = channelMessages.findIndex((m) => m.id === messageId)
      if (existingMessageIndex !== -1) {
        const existingMessage = channelMessages[existingMessageIndex]
        existingMessage.reactions = []
        channelMessages[existingMessageIndex] = existingMessage
        return {
          messages: {
            ...prevState.messages,
            [channelId]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessageReactionRemoveEmoji({
    channelId,
    messageId,
    emoji,
  }: {channelId: string; messageId: string; emoji: ReactionEmoji}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const existingMessageIndex = channelMessages.findIndex((m) => m.id === messageId)
      if (existingMessageIndex !== -1) {
        const existingMessage = channelMessages[existingMessageIndex]
        const updatedMessage = existingMessage.removeReactionEmoji(emoji)
        channelMessages[existingMessageIndex] = updatedMessage
        return {
          messages: {
            ...prevState.messages,
            [channelId]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessageDelete({channelId, messageId}: {channelId: string; messageId: string}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const updatedMessages = channelMessages.filter((m) => m.id !== messageId)
      return {
        messages: {
          ...prevState.messages,
          [channelId]: updatedMessages,
        },
      }
    })
  }

  private handleMessageDeleteBulk({channelId, messageIds}: {channelId: string; messageIds: Array<string>}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const updatedMessages = channelMessages.filter((m) => !messageIds.includes(m.id))
      return {
        messages: {
          ...prevState.messages,
          [channelId]: updatedMessages,
        },
      }
    })
  }

  private handleChannelDelete({channel}: {channel: Channel}) {
    this.setState((prevState) => {
      const updatedMessages = {...prevState.messages}
      delete updatedMessages[channel.id]
      Dispatcher.dispatch({
        type: "REPLY_DELETE_MESSAGE_IDS",
        messageIds: prevState.messages[channel.id]?.map((message) => message.id) ?? [],
      })
      return {messages: updatedMessages}
    })
  }

  private handleSpaceDelete({spaceId}: {spaceId: string}) {
    const spaceChannels = ChannelStore.getSpaceChannels(spaceId)
    this.setState((prevState) => {
      const updatedMessages = {...prevState.messages}
      for (const channelId in updatedMessages) {
        if (spaceChannels.some((channel) => channel.id === channelId)) {
          delete updatedMessages[channelId]
        }
      }
      return {messages: updatedMessages}
    })
  }

  private handleMessageSendError({channelId, nonce}: {channelId: string; nonce: string}) {
    this.setState((prevState) => {
      const channelMessages = prevState.messages[channelId] ?? []
      const messageIndex = channelMessages.findIndex((m) => m.nonce === nonce)
      if (messageIndex !== -1) {
        channelMessages[messageIndex].state = MessageStates.FAILED
        return {
          messages: {
            ...prevState.messages,
            [channelId]: [...channelMessages],
          },
        }
      }
      return prevState
    })
  }

  private handleMessagesFetchSuccess({channelId, messages}: {channelId: string; messages: Array<Message>}) {
    this.setState((prevState) => {
      const existingMessages = prevState.messages[channelId] ?? []
      const existingMessageIds = new Set(existingMessages.map((m) => m.id))

      const newMessages = messages
        .map((message) => new MessageRecord(message))
        .filter((m) => !existingMessageIds.has(m.id))

      const updatedMessages = [...existingMessages, ...newMessages].sort((a, b) => a.createdAt - b.createdAt)

      return {
        messages: {
          ...prevState.messages,
          [channelId]: updatedMessages,
        },
      }
    })
  }
}

export default new MessageStore()
