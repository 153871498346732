import {type StatusType, StatusTypes} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import IdleStore from "~/stores/IdleStore"
import UserSettingsStore from "~/stores/UserSettingsStore"

type State = {
  status: StatusType
  since: number
}

const initialState: State = {
  status: StatusTypes.ONLINE,
  since: 0,
}

class LocalPresenceStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "SESSION_START":
      case "CONNECTION_OPEN":
      case "CONNECTION_CLOSED":
      case "USER_SETTINGS_UPDATE":
      case "IDLE": {
        return this.handleUpdate()
      }
      default:
        return false
    }
  }

  getStatus(): StatusType {
    return this.state.status
  }

  getPresence() {
    return {status: this.state.status, since: this.state.since}
  }

  private handleUpdate() {
    const since = IdleStore.getIdleSince()
    let {status} = UserSettingsStore.getState()

    if (status === StatusTypes.ONLINE && since > 0) {
      status = StatusTypes.IDLE
    }

    this.setState({status, since})
  }
}

export default new LocalPresenceStore()
