import {clsx} from "clsx"
import React from "react"
import {Permissions, UserTypes} from "~/Constants"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as SpaceMemberActionCreators from "~/actions/SpaceMemberActionCreators"
import * as UserProfileActionCreators from "~/actions/UserProfileActionCreators"
import * as UserSettingsModalActionCreators from "~/actions/UserSettingsModalActionCreators"
import {UserTag} from "~/components/channel/UserTag"
import {FluxerIcon} from "~/components/icons/FluxerIcon"
import {UserProfileModal} from "~/components/modals/UserProfileModal"
import {RolePopout} from "~/components/popouts/RolePopout"
import {SpaceIcon} from "~/components/popouts/SpaceIcon"
import styles from "~/components/popouts/UserProfilePopout.module.css"
import {Avatar} from "~/components/uikit/Avatar"
import {Button} from "~/components/uikit/Button/Button"
import {Popout} from "~/components/uikit/Popout/Popout"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import {useForceUpdate} from "~/hooks/useForceUpdate"
import {useHover} from "~/hooks/useHover"
import {i18n} from "~/i18n"
import {AddIcon} from "~/icons/AddIcon"
import {ClearIcon} from "~/icons/ClearIcon"
import {EditIcon} from "~/icons/EditIcon"
import {LocationIcon} from "~/icons/LocationIcon"
import {MessageIcon} from "~/icons/MessageIcon"
import {NoteIcon} from "~/icons/NoteIcon"
import {ScheduleIcon} from "~/icons/ScheduleIcon"
import {UserIcon} from "~/icons/UserIcon"
import type {ProfileRecord} from "~/records/ProfileRecord"
import type {UserRecord} from "~/records/UserRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import DeveloperOptionsStore from "~/stores/DeveloperOptionsStore"
import PresenceStore from "~/stores/PresenceStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import UserNoteStore from "~/stores/UserNoteStore"
import avatarStyles from "~/styles/Avatar.module.css"
import markupStyles from "~/styles/Markup.module.css"
import * as ColorUtils from "~/utils/ColorUtils"
import * as DateUtils from "~/utils/DateUtils"
import * as MarkupUtils from "~/utils/MarkupUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"
import * as PermissionUtils from "~/utils/PermissionUtils"
import * as TimezoneUtils from "~/utils/TimezoneUtils"

type UserProfileSectionProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  label: string
  children: React.ReactNode
}

export const UserProfileSection = ({icon: Icon, label, children}: UserProfileSectionProps) => (
  <div className="-mx-0.5 flex items-center gap-1">
    <Tooltip text={label}>
      <div className="cursor-pointer">
        <Icon aria-label={label} className="mt-px h-4 w-4 text-text-primary-muted" />
      </div>
    </Tooltip>
    {children}
  </div>
)

type UserProfilePopoutProps = {
  popoutId: string | number
  user: UserRecord
  isWebhook: boolean
  spaceId: string
}

export const UserProfilePopout = ({popoutId, user, isWebhook, spaceId}: UserProfilePopoutProps) => {
  const [hoverRef, isHovering] = useHover()
  const [profile, setProfile] = React.useState<ProfileRecord | null>(null)
  const forceUpdate = useForceUpdate()
  const memberRoles = profile?.spaceMember?.getSortedRoles() ?? []
  const status = PresenceStore.useUserStatus(user.id)
  const timeAndOffset = profile?.timezoneOffset ? TimezoneUtils.getTimeAndOffset(profile.timezoneOffset) : null
  const userNote = UserNoteStore.useUserNote(user.id)
  const canManageRoles = PermissionUtils.can(Permissions.MANAGE_ROLES, {spaceId})

  const openFullProfile = React.useCallback(
    (autoFocusNote?: boolean) => {
      if (!profile) {
        return
      }
      ModalActionCreators.push(() => (
        <UserProfileModal userId={user.id} profile={profile} autoFocusNote={autoFocusNote} />
      ))
    },
    [profile, user.id],
  )

  const fetchProfile = React.useCallback(async () => {
    if (isWebhook) {
      return
    }
    const isSpaceMember = spaceId ? SpaceMemberStore.getMember(spaceId, user.id) : false
    if (DeveloperOptionsStore.getSlowProfileLoad()) {
      await new Promise((resolve) => setTimeout(resolve, 3000))
    }
    const profile = await UserProfileActionCreators.fetch(user.id, isSpaceMember ? spaceId : undefined)
    setProfile(profile)
  }, [spaceId, user.id, isWebhook])

  React.useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  React.useEffect(() => {
    if (profile?.timezoneOffset == null) {
      return
    }
    const updateOnFullMinute = () => {
      forceUpdate()
      setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000))
    }
    const timeout = setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000))
    return () => clearTimeout(timeout)
  }, [forceUpdate, profile?.timezoneOffset])

  const isCurrentUser = user.id === AuthenticationStore.getId()
  return (
    <div
      ref={hoverRef}
      className="relative flex w-[300px] flex-col gap-4 overflow-hidden rounded-md border-2 border-brand-primary bg-background-primary pb-1"
    >
      <header className="h-[140px]">
        <div className="h-[105px]">
          <div className="h-[105px] w-full bg-brand-primary" />
        </div>
        <div
          role="button"
          onClick={() => openFullProfile()}
          onKeyDown={(event) => event.key === "Enter" && openFullProfile()}
          tabIndex={0}
          className={clsx(
            "absolute top-[61px] left-[10px] z-0 rounded-full border-[6px] border-background-primary bg-background-primary",
            !isWebhook && avatarStyles.clickable,
          )}
        >
          <Avatar forceAnimate={true} size={80} status={isWebhook ? undefined : status} user={user} />
        </div>
      </header>

      <div className="flex flex-col gap-3 px-4">
        <div className="select-text">
          <div className="flex items-center gap-0.5">
            <span
              role="button"
              onClick={() => openFullProfile()}
              onKeyDown={(event) => event.key === "Enter" && openFullProfile()}
              tabIndex={0}
              className={clsx(
                "inline whitespace-normal break-words break-all align-middle font-semibold text-text-primary text-xl leading-6",
                !isWebhook && "cursor-pointer hover:underline",
              )}
            >
              {NicknameUtils.getNickname(user, profile?.spaceId)}
            </span>

            <div className="inline">
              {(user.type === UserTypes.AUTOMATED || isWebhook) && <UserTag className="mt-[.4em]" />}
            </div>

            <div
              className={clsx("ml-0.5 transition-opacity duration-300 ease-in-out", {
                "opacity-100": !isWebhook && isHovering,
                "pointer-events-none opacity-0": isWebhook || !isHovering,
              })}
            >
              <Tooltip
                text={userNote ? () => <div className="max-w-52 text-center">{userNote}</div> : i18n.Messages.ADD_NOTE}
                maxWidth="none"
              >
                <div
                  role="button"
                  onClick={() => openFullProfile(true)}
                  onKeyDown={(event) => event.key === "Enter" && openFullProfile(true)}
                  tabIndex={0}
                  className="cursor-pointer"
                >
                  <NoteIcon className="h-4 w-4 pt-1 text-text-primary-muted" />
                </div>
              </Tooltip>
            </div>
          </div>

          {!isWebhook && (
            <div className="block overflow-hidden font-medium text-[14px] text-text-tertiary leading-[18px]">
              <span
                role="button"
                onClick={() => openFullProfile()}
                onKeyDown={(event) => event.key === "Enter" && openFullProfile()}
                tabIndex={0}
                className="inline cursor-pointer overflow-auto whitespace-normal break-all align-middle hover:underline"
              >
                @{user.handle}
              </span>

              {user.pronouns && (
                <div
                  aria-hidden={true}
                  className="ml-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
                />
              )}

              {user.pronouns && (
                <Tooltip text={i18n.Messages.PRONOUNS}>
                  <span className="ml-1 align-middle">{user.pronouns}</span>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        {profile && profile.badges.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {profile.badges.map((badge) => (
              <span
                key={badge.id}
                className="inline-flex items-center gap-1 rounded-md border border-brand-primary-light px-2 py-1 font-semibold text-brand-primary-light text-xs"
              >
                <UserIcon className="h-3 w-3" aria-hidden={true} />
                <span>{badge.name}</span>
                <span aria-hidden={true} className="h-1 w-1 rounded-full bg-brand-primary-light" />
                <span className="font-medium opacity-75">{profile.domain}</span>
              </span>
            ))}
          </div>
        )}

        {(profile?.userProfile.biography || (profile && (profile.userProfile.location || timeAndOffset))) && (
          <div className="flex flex-col gap-2">
            {profile?.userProfile.biography && (
              <div className={clsx(markupStyles.markup, markupStyles.biography)}>
                {MarkupUtils.parseProfileBio(profile.userProfile.biography)}
              </div>
            )}
            {(profile.userProfile.location || timeAndOffset) && (
              <div className="flex flex-col gap-0.5">
                {profile.userProfile.location && (
                  <UserProfileSection icon={LocationIcon} label={i18n.Messages.LOCATION}>
                    <span className="select-text text-sm text-text-primary">{profile.userProfile.location}</span>
                  </UserProfileSection>
                )}
                {timeAndOffset && (
                  <UserProfileSection icon={ScheduleIcon} label={i18n.Messages.LOCAL_TIME}>
                    <span className="select-text text-sm text-text-primary">
                      {timeAndOffset.time} <span className="text-text-primary-muted">— {timeAndOffset.offset}</span>
                    </span>
                  </UserProfileSection>
                )}
              </div>
            )}
          </div>
        )}

        {profile?.space && profile.spaceMember ? (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-sm text-text-primary">{i18n.Messages.MEMBER_SINCE}</span>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1">
                <Tooltip text="Fluxer">
                  <div className="cursor-pointer">
                    <FluxerIcon className="h-4 w-4 text-text-chat" />
                  </div>
                </Tooltip>
                <span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
              </div>
              <div className="flex items-center gap-1">
                <Tooltip text={profile.space.name}>
                  <div className="cursor-pointer">
                    <SpaceIcon
                      id={profile.space.id}
                      name={profile.space.name}
                      icon={profile.space.icon}
                      className="h-4 w-4 text-[8px]"
                    />
                  </div>
                </Tooltip>
                <span className="text-sm text-text-chat">
                  {DateUtils.getFormattedShortDate(profile.spaceMember.joinedAt)}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-sm text-text-primary">{i18n.Messages.FLUXER_MEMBER_SINCE}</span>
            <span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
          </div>
        )}

        {!isWebhook && profile?.space && profile?.spaceMember && (memberRoles.length > 0 || canManageRoles) && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-sm text-text-primary">{i18n.Messages.ROLES}</span>
            <div aria-label={i18n.Messages.ROLES} className="relative flex flex-wrap gap-1">
              {memberRoles.map((role) => (
                <div
                  aria-label={role.name}
                  className={clsx(
                    "flex h-6 max-w-[268px] items-center rounded-[4px] border border-background-header-secondary bg-background-primary p-1 font-medium text-xs",
                    styles.role,
                  )}
                  key={role.id}
                >
                  {canManageRoles ? (
                    <Tooltip text={i18n.Messages.REMOVE_ROLE} position="top">
                      <button
                        type="button"
                        className={clsx("relative cursor-pointer", styles.roleRemoveButton)}
                        onClick={() => SpaceMemberActionCreators.removeRole(spaceId, user.id, role.id)}
                      >
                        <ClearIcon
                          className={clsx(
                            "-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 h-[10px] w-[10px] transform opacity-0",
                            styles.roleRemoveIcon,
                          )}
                        />
                        <span
                          className="flex h-[12px] w-[12px] items-center justify-center rounded-full p-0"
                          style={{backgroundColor: ColorUtils.int2rgb(role.color)}}
                        />
                      </button>
                    </Tooltip>
                  ) : (
                    <div className="relative">
                      <span
                        className="flex h-[12px] w-[12px] items-center justify-center rounded-full p-0"
                        style={{backgroundColor: ColorUtils.int2rgb(role.color)}}
                      />
                    </div>
                  )}
                  <div
                    aria-hidden={true}
                    className="mr-0.5 max-w-[200px] truncate font-medium text-[12px] text-text-primary leading-[1.33]"
                  >
                    {role.name}
                  </div>
                </div>
              ))}

              {canManageRoles && (
                <Popout
                  dependsOn={popoutId}
                  tooltip={memberRoles.length > 0 ? i18n.Messages.ADD_ROLE : undefined}
                  tooltipPosition="top"
                  render={(popoutId) =>
                    profile?.space &&
                    profile?.spaceMember && (
                      <RolePopout popoutId={popoutId} space={profile.space} member={profile.spaceMember} />
                    )
                  }
                  position="bottom"
                >
                  <div className="flex h-6 w-fit cursor-pointer items-center rounded-[4px] border border-background-header-secondary p-1 font-medium text-text-tertiary text-xs">
                    <AddIcon className="h-4 w-4" />
                    {memberRoles.length === 0 && i18n.Messages.ADD_ROLE}
                  </div>
                </Popout>
              )}
            </div>
          </div>
        )}
      </div>

      {isWebhook ? (
        <div />
      ) : (
        <footer className="flex flex-col px-4 pb-3">
          {isCurrentUser ? (
            <Button
              small={true}
              leftIcon={<EditIcon className="h-4 w-4" />}
              onClick={() => UserSettingsModalActionCreators.openUserSettingsTab("edit_profile")}
            >
              {i18n.Messages.EDIT_PROFILE}
            </Button>
          ) : (
            <Button small={true} leftIcon={<MessageIcon className="h-4 w-4" />}>
              {i18n.Messages.MESSAGE}
            </Button>
          )}
        </footer>
      )}
    </div>
  )
}
