import markdown, {
  type SingleASTNode,
  type ReactOutput,
  type State,
  type Capture,
  type ParserRule,
  type ReactOutputRule,
} from "@khanacademy/simple-markdown"
import clsx from "clsx"
import highlight from "highlight.js"
import _ from "lodash"
import punycode from "punycode/"
import React from "react"
import {type ChannelType, ChannelTypes, SPACE_TEXT_CHANNEL_TYPES} from "~/Constants"
import * as InviteActionCreators from "~/actions/InviteActionCreators"
import * as TextCopyActionCreators from "~/actions/TextCopyActionCreators"
import {UserProfilePopout} from "~/components/popouts/UserProfilePopout"
import {Popout} from "~/components/uikit/Popout/Popout"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import {i18n} from "~/i18n"
import {CopyIcon} from "~/icons/CopyIcon"
import * as UnicodeEmojis from "~/lib/UnicodeEmojis"
import ChannelStore from "~/stores/ChannelStore"
import EmojiStore from "~/stores/EmojiStore"
import SpaceStore from "~/stores/SpaceStore"
import UserStore from "~/stores/UserStore"
import markupStyles from "~/styles/Markup.module.css"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as ChannelUtils from "~/utils/ChannelUtils"
import * as ColorUtils from "~/utils/ColorUtils"
import * as EmojiUtils from "~/utils/EmojiUtils"
import * as InviteUtils from "~/utils/InviteUtils"
import * as markupAstUtils from "~/utils/MarkupASTUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"
import * as RouterUtils from "~/utils/RouterUtils"

const depunycodeLink = (target: string) => {
  try {
    const url = new URL(target)
    url.hostname = punycode.toASCII(url.hostname)
    return url.toString()
  } catch {
    return target
  }
}

const parseLink = (capture: Capture): SingleASTNode => {
  const target = depunycodeLink(capture[1])
  return {
    type: "link",
    content: [{type: "text", content: target}],
    target,
    title: undefined,
  }
}

const smartOutput = (node: SingleASTNode, output: ReactOutput, state: State): React.ReactNode =>
  typeof node.content === "string" ? node.content : output(node.content, state)

const BLOCKQUOTE_REGEX = /^( *>>> +([\s\S]*))|^( *>(?!>>) +[^\n]*(\n *>(?!>>) +[^\n]*)*\n?)/
const EMPTY_LINE_REGEX = /^$|\n *$/
const TRIPLE_GREATER_THAN_REGEX = /^ *>>> ?/
const SINGLE_GREATER_THAN_REGEX = /^ *> ?/gm

const blockQuoteRule = {
  ...markdown.defaultRules.blockQuote,
  match: (source, state) => {
    const {prevCapture, inQuote, nested} = state
    if (inQuote || nested) {
      return null
    }
    if (prevCapture == null) {
      return BLOCKQUOTE_REGEX.exec(source)
    }

    const previousCapture = prevCapture[0]
    return EMPTY_LINE_REGEX.test(previousCapture) ? BLOCKQUOTE_REGEX.exec(source) : null
  },
  parse: (capture, parse, state) => {
    const rawContent = capture[0]
    const isTripleGreaterThan = TRIPLE_GREATER_THAN_REGEX.exec(rawContent)
    const regexToUse = isTripleGreaterThan ? TRIPLE_GREATER_THAN_REGEX : SINGLE_GREATER_THAN_REGEX
    const content = rawContent.replace(regexToUse, "")

    const previousInQuote = state.inQuote
    const previousInline = state.inline

    state.inQuote = true
    if (!isTripleGreaterThan) {
      state.inline = true
    }

    const parsedContent = parse(content, state)

    state.inQuote = previousInQuote
    state.inline = previousInline

    if (parsedContent.length === 0) {
      parsedContent.push({
        type: "text",
        content: " ",
      })
    }

    return {
      content: parsedContent,
      type: "blockQuote",
    }
  },
  react: (node, output, state) => (
    <div key={state.key} className={markupStyles.blockquoteContainer}>
      <div className={markupStyles.blockquoteDivider} />
      <blockquote>{smartOutput(node, output, state)}</blockquote>
    </div>
  ),
} satisfies ParserRule & ReactOutputRule

const CHANNEL_REGEX = /^<#(\d+)>/

const createChannelNode = (channelType: ChannelType, content: string, onClick?: () => void) => ({
  channelType,
  type: "channel",
  content: [{type: "text", content}],
  onClick,
})

const channelRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => CHANNEL_REGEX.exec(source),
  parse: (capture) => {
    const channel = ChannelStore.getChannel(capture[1])
    if (!channel) {
      return createChannelNode(ChannelTypes.SPACE_TEXT, i18n.Messages.UNKNOWN_CHANNEL_PLACEHOLDER)
    }

    const {type, name, url, spaceId, id} = channel

    if (type === ChannelTypes.SPACE_LINK) {
      return createChannelNode(ChannelTypes.SPACE_LINK, name, () => url && window.open(url, "_blank"))
    }

    if (!SPACE_TEXT_CHANNEL_TYPES.has(channel.type as any)) {
      return createChannelNode(ChannelTypes.SPACE_TEXT, i18n.Messages.UNKNOWN_CHANNEL_PLACEHOLDER)
    }

    return createChannelNode(type, name, () => RouterUtils.transitionTo(`/channels/${spaceId}/${id}`))
  },
  react: (node, output, state) => {
    const Icon = ChannelUtils.getIcon(node.channelType)
    return (
      <span
        key={state.key}
        className={markupStyles.mention}
        onClick={node.onClick}
        onKeyDown={node.onClick}
        tabIndex={0}
        role="button"
      >
        <div className="-mt-[.2em] inline-flex items-center align-middle">
          <Icon className="mr-px h-4 w-4" />
          {smartOutput(node, output, state)}
        </div>
      </span>
    )
  },
} satisfies ParserRule & ReactOutputRule

// biome-ignore lint/correctness/noEmptyCharacterClassInRegex: <explanation>
const CODEBLOCK_REGEX = /^```(?:([a-z0-9_+\-.#]+?)\n)?\n*([^\n][^]*?)\n*```/i

const codeBlockRule = {
  ...markdown.defaultRules.codeBlock,
  match: (source) => CODEBLOCK_REGEX.exec(source),
  parse: (capture, _parse, state) => {
    const language = capture[1] != null ? capture[1] : ""
    const content = capture[2] != null ? capture[2] : ""
    return {
      lang: language,
      content: content,
      inQuote: state.inQuote,
    }
  },
  react: (node, output, state) => {
    let children: React.ReactNode
    if (node.lang && highlight.getLanguage(node.lang) != null) {
      const code = highlight.highlight(node.content, {language: node.lang, ignoreIllegals: true})
      children = (
        <code
          className={clsx(markupStyles.hljs, node.lang)}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{__html: code.value}}
        />
      )
    } else {
      children = <code className={markupStyles.hljs}>{smartOutput(node, output, state)}</code>
    }
    return (
      <pre key={state.key}>
        <div className={markupStyles.codeContainer}>
          <div className={markupStyles.codeActions}>
            <button type="button" onClick={() => TextCopyActionCreators.copy(node.content)}>
              <CopyIcon className="h-4 w-4" />
            </button>
          </div>
          {children}
        </div>
      </pre>
    )
  },
} satisfies ParserRule & ReactOutputRule

const EMOTICON_REGEX = /^(¯\\_\(ツ\)_\/¯)/

const emoticonRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => EMOTICON_REGEX.exec(source),
  parse: (capture) => ({type: "text", content: capture[1]}),
} satisfies ParserRule

const HEADING_REGEX = /^ *(#{1,3})(?:\s+)((?![#]+)[^\n]+?)#*\s*(?:\n|$)/
const NEWLINE_SUFFIX_REGEX = /\n$/

const headingRule = {
  ...markdown.defaultRules.lheading,
  parse: (capture, _parse, state) => {
    return {
      content: capture[2],
      inlineFormat: state.inReply,
      level: capture[1].length,
    }
  },
  match: (source, state, prevCapture) => {
    if (prevCapture === null || prevCapture === "" || NEWLINE_SUFFIX_REGEX.test(prevCapture)) {
      return markdown.anyScopeRegex(HEADING_REGEX)(source, state, prevCapture)
    }
    return null
  },
  react: (node, _output, state) => {
    const Tag = `h${node.level}` as "h1" | "h2" | "h3"
    return (
      <Tag key={state.key} className={clsx(node.inlineFormat && markupStyles.inlineFormat)}>
        {node.content}
      </Tag>
    )
  },
} satisfies ParserRule & ReactOutputRule

const inlineCodeRule = {
  ...markdown.defaultRules.inlineCode,
  react: (node, _output, state) => (
    <code key={state.key} className={markupStyles.inline}>
      {node.content.replace(/\s+/g, " ")}
    </code>
  ),
} satisfies ParserRule & ReactOutputRule

const LINK_TEXT_REGEX = /^(?:\[(.*?)\]\((https?:\/\/[^\s<]+[^<.,:;"'\]\s])\)|(https?:\/\/[^\s<]+[^<.,:;"'\]\s]))/g

const linkRule = {
  ...markdown.defaultRules.link,
  match: (source) => LINK_TEXT_REGEX.exec(source),
  parse: (capture, parse, state) => {
    const text = capture[1]
    const target = depunycodeLink(markdown.unescapeUrl(capture[2]))

    if (URL.canParse(text)) {
      return {
        type: "text",
        content: `[${text}](${target})`,
      }
    }

    return {
      content: parse(text, state),
      target,
      title: capture[3],
    }
  },
  react: (node, output, state) => {
    let onClick: ((event: React.MouseEvent) => void) | undefined

    const code = InviteUtils.findInvite(node.target)
    const content = output(node.content, state)
    const title = node.title || markupAstUtils.astToString(node.content)

    if (code != null) {
      onClick = (event) => {
        if (event) {
          event.preventDefault()
        }
        InviteActionCreators.acceptAndTransitionToChannel(code)
      }
    } else {
      const url = new URL(node.target)
      if (url.host === location.host) {
        onClick = (event) => {
          if (event) {
            event.preventDefault()
          }
          RouterUtils.transitionTo(url.pathname)
        }
      }
    }

    const sanitizedTarget = markdown.sanitizeUrl(node.target)
    if (sanitizedTarget == null) {
      return content
    }

    return (
      <a
        key={state.key}
        title={title}
        href={sanitizedTarget}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {content}
      </a>
    )
  },
} satisfies ParserRule & ReactOutputRule

const MENTION_REGEX = /^<@!?(\d+)>|^(@(?:everyone|here))/

const getUserInfo = (userId: string, channelId: string) => {
  if (!userId) {
    return {userId: null, name: null}
  }

  const user = UserStore.getUser(userId)
  if (!user) {
    return {userId, name: userId}
  }

  let name = user.displayName
  const channel = ChannelStore.getChannel(channelId)
  if (channel) {
    name = NicknameUtils.getNickname(user, channel.spaceId) || name
  }

  return {userId: user.id, name}
}

const mentionRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => MENTION_REGEX.exec(source),
  parse: (capture, _parse, state) => {
    const mentionType = capture[2]
    if (mentionType === "@everyone" || mentionType === "@here") {
      return {
        type: "mention",
        content: [{type: "text", content: mentionType.slice(1)}],
      }
    }

    const userId = capture[1]
    const {userId: resolvedUserId, name} = getUserInfo(userId, state.channelId)
    return {
      userId: resolvedUserId,
      type: "mention",
      content: [{type: "text", content: name ?? ""}],
      channelId: state.channelId,
    }
  },
  react: (node, output, state) => {
    const content = (
      <span className="-mt-[.2em] inline-flex items-center align-middle">
        {"@"}
        {smartOutput(node, output, state)}
      </span>
    )

    if (!node.userId) {
      return (
        <span
          key={state.key}
          className={markupStyles.mention}
          style={{
            color: node.color ? ColorUtils.int2rgb(node.color) : undefined,
            backgroundColor: node.color ? ColorUtils.int2rgba(node.color, 0.1) : undefined,
          }}
        >
          {content}
        </span>
      )
    }

    const user = UserStore.getUser(node.userId)
    if (!user) {
      return (
        <span key={state.key} className={markupStyles.mention}>
          {content}
        </span>
      )
    }

    const selectedChannel = ChannelStore.getChannel(node.channelId)!
    return (
      <span key={state.key} className="inline-flex items-center">
        <Popout
          render={(popoutId) => (
            <UserProfilePopout popoutId={popoutId} user={user} isWebhook={false} spaceId={selectedChannel.spaceId} />
          )}
          position="right-start"
        >
          <span className={clsx(markupStyles.mention, markupStyles.interactive)}>{content}</span>
        </Popout>
      </span>
    )
  },
} satisfies ParserRule & ReactOutputRule

const paragraphRule = {
  ...markdown.defaultRules.paragraph,
  react: (node, output, state) => <React.Fragment key={state.key}>{smartOutput(node, output, state)}</React.Fragment>,
} satisfies ParserRule & ReactOutputRule

const ROLE_MENTION_REGEX = /^<@&(\d+)>/

const roleMentionRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => ROLE_MENTION_REGEX.exec(source),
  parse: ([, roleId], _parse, state) => {
    const selectedChannel = ChannelStore.getChannel(state.channelId)
    const spaceId = selectedChannel ? selectedChannel.spaceId : null
    const space = spaceId ? SpaceStore.getSpace(spaceId) : null
    const role = space ? space.roles[roleId] : null
    if (role == null) {
      return {type: "text", content: i18n.Messages.UNKNOWN_ROLE_PLACEHOLDER}
    }
    return {
      type: "mention",
      color: role.color,
      content: [{type: "text", content: role.name}],
    }
  },
} satisfies ParserRule

const STRIKE_REGEX = /^~~([\s\S]+?)~~(?!_)/

const sRule = {
  ...markdown.defaultRules.u,
  match: markdown.inlineRegex(STRIKE_REGEX),
  react: (node, output, state) => <s key={state.key}>{output(node.content, state)}</s>,
} satisfies ParserRule & ReactOutputRule

const LINK_REGEX = /^((?:https?|steam):\/\/[^\s<>,:;"'\]\[\])]*[^\s<>,:;"'\]\[\])])/

const urlRule = {
  ...markdown.defaultRules.url,
  match(source, state) {
    if (!state.inline) {
      return null
    }

    const match = LINK_REGEX.exec(source)
    if (match != null) {
      let openParensCount = 0
      let url = match[0]

      for (let i = url.length - 1; i >= 0 && url[i] === ")"; i--) {
        const openParenIndex = url.indexOf("(", openParensCount)
        if (openParenIndex === -1) {
          url = url.slice(0, url.length - 1)
          break
        }
        openParensCount = openParenIndex + 1
      }

      match[0] = match[1] = url
    }

    return match
  },
  parse: parseLink,
} satisfies ParserRule

const LIST_BULLET = "(?:[*-]|\\d+\\.)"
const LIST_ITEM_PREFIX = `( *)(${LIST_BULLET}) +`
const LIST_ITEM_PREFIX_REGEX = new RegExp(`^${LIST_ITEM_PREFIX}`)
const LIST_ITEM_REGEX = new RegExp(`${LIST_ITEM_PREFIX}[^\\n]*(?:\\n(?!\\1${LIST_BULLET} )[^\\n]*)*(\\n|$)`, "gm")
const LIST_ITEM_END_REGEX = / *\n+$/
const LIST_REGEX = new RegExp(`^( *)(${LIST_BULLET}) [\\s\\S]+?(?:(\\n)(?! )(?!\\1${LIST_BULLET} )\\n*|\\s*\\n*$)`)
const LIST_LOOKBEHIND_REGEX = /(?:^|\n)( *)$/

const listRule = {
  ...markdown.defaultRules.list,
  match: (source, state) => {
    const previousCapture = state.prevCapture?.[0] ?? ""
    const isStartOfLine = LIST_LOOKBEHIND_REGEX.exec(previousCapture)
    if (isStartOfLine) {
      const adjustedSource = isStartOfLine[1] + source
      return LIST_REGEX.exec(adjustedSource)
    }
    return null
  },
  parse: (capture, parse, state) => {
    const bullet = capture[2]
    const ordered = bullet.length > 1
    const start = ordered ? +bullet : undefined
    const items = capture[0].match(LIST_ITEM_REGEX) || []
    const itemContent = items.map((item) => {
      const prefixMatch = LIST_ITEM_PREFIX_REGEX.exec(item)
      const leadingSpaces = prefixMatch ? prefixMatch[0].length : 0
      const leadingSpacesRegex = new RegExp(`^ {1,${leadingSpaces}}`, "gm")
      const content = item
        .replace(leadingSpacesRegex, "")
        .replace(LIST_ITEM_PREFIX_REGEX, "")
        .replace(LIST_ITEM_END_REGEX, "")
      return parse(content, state)
    })
    return {
      ordered: ordered,
      start: start,
      items: itemContent,
    }
  },
} satisfies ParserRule

const emojiRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => UnicodeEmojis.EMOJI_NAME_RE.exec(source),
  parse: ([content, name]) => {
    const surrogate = UnicodeEmojis.convertNameToSurrogate(name)
    if (!surrogate) {
      return {type: "text", content}
    }
    return {name: `:${name}:`, surrogate, src: EmojiUtils.getURL(surrogate, true)}
  },
  react: (node, _output, state) => {
    if (!node.src) {
      return <span key={state.key}>{node.surrogate}</span>
    }
    return (
      <Tooltip key={state.key} text={node.name} delay={750}>
        <img
          draggable={false}
          className={clsx(markupStyles.emoji, node.jumboable && markupStyles.jumboable)}
          alt={node.name}
          src={node.src}
        />
      </Tooltip>
    )
  },
} as ParserRule & ReactOutputRule

const textRule = {
  ...markdown.defaultRules.text,
  react: (node, output, state) => <React.Fragment key={state.key}>{smartOutput(node, output, state)}</React.Fragment>,
} satisfies ParserRule & ReactOutputRule

// const textRule = {
// 	order: markdown.defaultRules.text.order,
// 	match: (source) => /^[\s\S]+?(?=[\\<!\[_*`]|https?:\/\/| {2,}\n|$)/.exec(source),
// 	parse: (capture) => {
// 		const text = capture[0];
// 		const nodes = [];
// 		let index = 0;

// 		const segments = UnicodeEmojis.findInlineEmojisFromSurrogates(text);
// 		for (const segment of segments) {
// 			if (segment.type === 'text') {
// 				nodes.push({
// 					type: 'text',
// 					content: segment.text,
// 					originalMatch: {index, 0: segment.text},
// 				});
// 				index += segment.text.length;
// 			} else if (segment.type === 'emoji') {
// 				nodes.push({
// 					type: 'emoji',
// 					name: segment.emojiName,
// 					surrogate: segment.text,
// 					src: EmojiUtils.getURL(segment.text, true),
// 					originalMatch: {index, 0: segment.text},
// 				});
// 				index += segment.text.length;
// 			}
// 		}

// 		return nodes;
// 	},
// 	react: (node, _output, state) => {
// 		if (node.type === 'text') {
// 			return node.content;
// 		}
// 		if (node.type === 'emoji') {
// 			return (
// 				<Tooltip key={state.key} text={node.name} delay={750}>
// 					<img
// 						draggable={false}
// 						className={clsx(markupStyles.emoji, node.jumboable && markupStyles.jumboable)}
// 						alt={node.name}
// 						src={node.src}
// 					/>
// 				</Tooltip>
// 			);
// 		}
// 		return null;
// 	},
// } satisfies ParserRule & ReactOutputRule;

const CUSTOM_EMOJI_REGEX = /^<a?:(\w+):(\d+)>/

const customEmojiRule = {
  order: markdown.defaultRules.text.order,
  match: (source) => CUSTOM_EMOJI_REGEX.exec(source),
  parse: ([_, name, emojiId], _parse, {messageId, channelId, disableAnimatedEmoji}) => {
    const channel = ChannelStore.getChannel(channelId)
    const emoji = EmojiStore.getDisambiguatedEmojiContext(channel?.spaceId).getById(emojiId)
    if (emoji) {
      name = emoji.name
    }
    const animated = !disableAnimatedEmoji && emoji?.animated
    return {
      emojiId,
      name: `:${name}:`,
      animated,
      src: AvatarUtils.getEmojiURL({id: emojiId, animated}),
      messageId,
    }
  },
  react: (node, _output, {key, spaceId}) => (
    <Tooltip
      key={key}
      text={() => {
        const emoji = EmojiStore.getDisambiguatedEmojiContext(spaceId).getById(node.emojiId)
        return emoji ? `:${emoji.name}:` : node.name
      }}
      delay={750}
      position="top"
    >
      <img
        draggable={false}
        className={clsx(markupStyles.emoji, node.jumboable && markupStyles.jumboable)}
        alt={node.name}
        src={`${node.src}?size=${node.jumboable ? 240 : 96}&quality=lossless`}
        data-message-id={node.messageId}
        data-emoji-id={node.emojiId}
        data-animated={node.animated}
      />
    </Tooltip>
  ),
} as ParserRule & ReactOutputRule

const RULES: Record<string, ParserRule> = {
  autolink: urlRule,
  blockQuote: blockQuoteRule,
  br: markdown.defaultRules.br,
  channel: channelRule,
  codeBlock: codeBlockRule,
  customEmoji: customEmojiRule,
  del: markdown.defaultRules.del,
  em: markdown.defaultRules.em,
  emoji: emojiRule,
  emoticon: emoticonRule,
  escape: markdown.defaultRules.escape,
  heading: headingRule,
  inlineCode: inlineCodeRule,
  link: linkRule,
  list: listRule,
  mention: mentionRule,
  newline: markdown.defaultRules.newline,
  paragraph: paragraphRule,
  roleMention: roleMentionRule,
  s: sRule,
  strong: markdown.defaultRules.strong,
  text: textRule,
  u: markdown.defaultRules.u,
  url: urlRule,
} as const

const CHANNEL_TOPIC_RULES = _.omit(RULES, ["inlineCode", "codeBlock", "br", "blockQuote"])

const EMBED_TITLE_RULES = _.omit(RULES, [
  "autolink",
  "br",
  "channel",
  "codeBlock",
  "link",
  "mention",
  "newline",
  "roleMention",
  "url",
])

const INLINE_REPLY_RULES = _.omit(
  {
    ...RULES,
    text: {
      ...textRule,
      parse: (capture: Capture) => ({content: capture[0].replace(/\s+/g, " ")}),
    },
    inlineCode: {
      ...inlineCodeRule,
      match: (source: string, state: State, prevCapture: string) => {
        const codeBlockMatch = codeBlockRule.match(source)
        if (codeBlockMatch != null) {
          return codeBlockMatch
        }
        return inlineCodeRule.match(source, state, prevCapture)
      },
    },
  },
  ["blockQuote", "codeBlock", "br"],
)

const PROFILE_BIO_RULES = _.omit(RULES, [
  "codeBlock",
  "br",
  "mention",
  "roleMention",
  "channel",
  "paragraph",
  "newline",
])

export const parse = markupAstUtils.reactParserFor(RULES)
export const parseChannelTopic = markupAstUtils.reactParserFor(CHANNEL_TOPIC_RULES)
export const parseEmbedTitle = markupAstUtils.reactParserFor(EMBED_TITLE_RULES)
export const parseInlineReply = markupAstUtils.reactParserFor(INLINE_REPLY_RULES)
export const parseProfileBio = markupAstUtils.reactParserFor(PROFILE_BIO_RULES)
