import {useForm} from "react-hook-form"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as UserActionCreators from "~/actions/UserActionCreators"
import {Form} from "~/components/form/Form"
import {Input} from "~/components/form/Input"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import type {HttpResponse} from "~/lib/HttpClient"
import * as FormUtils from "~/utils/FormUtils"

type FormInputs = {
  password: string
  new_password: string
  confirm_password: string
}

export const PasswordChangeModal = () => {
  const form = useForm<FormInputs>()

  const onSubmit = async (data: FormInputs) => {
    if (data.new_password !== data.confirm_password) {
      form.setError("confirm_password", {message: i18n.Messages.PASSWORDS_DO_NOT_MATCH})
      return
    }

    try {
      await UserActionCreators.update({password: data.password, new_password: data.new_password})
      ModalActionCreators.pop()
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "password")
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Modal.Root label={i18n.Messages.CHANGE_PASSWORD_MODAL_TITLE} size="small">
        <Modal.Header title={i18n.Messages.CHANGE_PASSWORD_MODAL_TITLE} />
        <Modal.Content className={styles.content}>
          {i18n.Messages.CHANGE_PASSWORD_MODAL_DESCRIPTION}
          <Input
            {...form.register("password")}
            autoFocus={true}
            autoComplete="current-password"
            error={form.formState.errors.password?.message}
            label={i18n.Messages.CURRENT_PASSWORD}
            maxLength={128}
            minLength={8}
            placeholder={"•".repeat(32)}
            required={true}
            type="password"
          />

          <Input
            {...form.register("new_password")}
            autoComplete="new-password"
            error={form.formState.errors.new_password?.message}
            label={i18n.Messages.NEW_PASSWORD}
            maxLength={128}
            minLength={8}
            placeholder={"•".repeat(32)}
            required={true}
            type="password"
          />

          <Input
            {...form.register("confirm_password")}
            autoComplete="new-password"
            error={form.formState.errors.confirm_password?.message}
            label={i18n.Messages.CONFIRM_NEW_PASSWORD}
            maxLength={128}
            minLength={8}
            placeholder={"•".repeat(32)}
            required={true}
            type="password"
          />
        </Modal.Content>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button onClick={ModalActionCreators.pop} variant="ghost">
              {i18n.Messages.CANCEL}
            </Button>
            <Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
              {i18n.Messages.DONE}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Root>
    </Form>
  )
}
