import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type BetaCode, BetaCodeRecord} from "~/records/BetaCodeRecord"

type State = {
  betaCodes: Array<BetaCodeRecord>
  fetchStatus: "idle" | "pending" | "success" | "error"
  isCreateError: boolean
  isDeleteError: boolean
}

const initialState: State = {
  betaCodes: [],
  fetchStatus: "idle",
  isCreateError: false,
  isDeleteError: false,
}

class BetaCodeStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "BETA_CODES_FETCH_PENDING":
        return this.handleBetaCodesFetchPending()
      case "BETA_CODES_FETCH_SUCCESS":
        return this.handleBetaCodesFetchSuccess(action)
      case "BETA_CODES_FETCH_ERROR":
        return this.handleBetaCodesFetchError()

      case "BETA_CODE_CREATE_PENDING":
        return this.handleBetaCodeCreatePending()
      case "BETA_CODE_CREATE_SUCCESS":
        return this.handleBetaCodeCreateSuccess(action)
      case "BETA_CODE_CREATE_ERROR":
        return this.handleBetaCodeCreateError()

      case "BETA_CODE_DELETE_PENDING":
        return this.handleBetaCodeDeletePending()
      case "BETA_CODE_DELETE_SUCCESS":
        return this.handleBetaCodeDeleteSuccess(action)
      case "BETA_CODE_DELETE_ERROR":
        return this.handleBetaCodeDeleteError()

      default:
        return false
    }
  }

  private handleBetaCodesFetchPending() {
    this.setState((prevState) => ({...prevState, fetchStatus: "pending"}))
  }

  private handleBetaCodesFetchSuccess({betaCodes}: {betaCodes: Array<BetaCode>}) {
    this.setState((prevState) => ({
      ...prevState,
      betaCodes: betaCodes.map((betaCode) => new BetaCodeRecord(betaCode)),
      fetchStatus: "success",
    }))
  }

  private handleBetaCodesFetchError() {
    this.setState((prevState) => ({...prevState, fetchStatus: "error"}))
  }

  private handleBetaCodeCreatePending() {
    this.setState((prevState) => ({...prevState, isCreateError: false}))
  }

  private handleBetaCodeCreateSuccess({betaCode}: {betaCode: BetaCodeRecord}) {
    this.setState((prevState) => ({
      ...prevState,
      betaCodes: [...prevState.betaCodes, betaCode],
    }))
  }

  private handleBetaCodeCreateError() {
    this.setState((prevState) => ({...prevState, isCreateError: true}))
  }

  private handleBetaCodeDeletePending() {
    this.setState((prevState) => ({...prevState, isDeleteError: false}))
  }

  private handleBetaCodeDeleteSuccess({code}: {code: string}) {
    this.setState((prevState) => ({
      ...prevState,
      betaCodes: prevState.betaCodes.filter((betaCode) => betaCode.code !== code),
    }))
  }

  private handleBetaCodeDeleteError() {
    this.setState((prevState) => ({...prevState, isDeleteError: true}))
  }
}

export default new BetaCodeStore()
