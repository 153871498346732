import invariant from "tiny-invariant"
import {ALL_PERMISSIONS, Permissions} from "~/Constants"
import type {ChannelRecord} from "~/records/ChannelRecord"
import type {SpaceMemberRecord} from "~/records/SpaceMemberRecord"
import type {SpaceRecord} from "~/records/SpaceRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import ChannelStore from "~/stores/ChannelStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import SpaceStore from "~/stores/SpaceStore"

export const computeBasePermissions = ({space, member}: {space: SpaceRecord; member: SpaceMemberRecord}): bigint => {
  if (space.isOwner(member.user.id)) {
    return ALL_PERMISSIONS
  }

  const everyoneRole = space.roles[space.id]
  if (!everyoneRole) {
    throw new Error("No @everyone role found")
  }

  let permissions = everyoneRole.permissions

  for (const roleId of member.roles) {
    const role = space.roles[roleId]
    if (role) {
      permissions |= role.permissions
    }
  }

  if (permissions & Permissions.ADMINISTRATOR) {
    return ALL_PERMISSIONS
  }

  return permissions
}

export const computeOverwrites = ({
  basePermissions,
  member,
  channel,
}: {
  basePermissions: bigint
  member: SpaceMemberRecord
  channel: ChannelRecord
}): bigint => {
  if (basePermissions & Permissions.ADMINISTRATOR) {
    return ALL_PERMISSIONS
  }

  let permissions = basePermissions

  const everyoneOverwrite = channel.overwrites[member.spaceId]
  if (everyoneOverwrite) {
    permissions &= ~everyoneOverwrite.deny
    permissions |= everyoneOverwrite.allow
  }

  let allow = 0n
  let deny = 0n

  for (const roleId of member.roles) {
    const roleOverwrite = channel.overwrites[roleId]
    if (roleOverwrite) {
      allow |= roleOverwrite.allow
      deny |= roleOverwrite.deny
    }
  }

  permissions &= ~deny
  permissions |= allow

  const memberOverwrite = channel.overwrites[member.user.id]
  if (memberOverwrite) {
    permissions &= ~memberOverwrite.deny
    permissions |= memberOverwrite.allow
  }

  return permissions
}

export const computePermissions = ({
  space,
  member,
  channel,
}: {
  space: SpaceRecord
  member: SpaceMemberRecord
  channel: ChannelRecord | null
}): bigint => {
  const basePermissions = computeBasePermissions({space, member})
  if (!channel) {
    return basePermissions
  }
  return computeOverwrites({basePermissions, member, channel})
}

export const getMaxRolePosition = (member: SpaceMemberRecord) => {
  const space = SpaceStore.getSpace(member.spaceId)
  invariant(space, `Space ${member.spaceId} not found`)
  const intersection = Object.values(space.roles).filter((role) => member.roles.has(role.id))
  return Math.min(...intersection.map((role) => role.position))
}

export const checkTargetMember = (origin: SpaceMemberRecord, target: SpaceMemberRecord) => {
  const space = SpaceStore.getSpace(origin.spaceId)
  invariant(space, `Space ${origin.spaceId} not found`)
  if (space.isOwner(origin.user.id)) {
    return true
  }
  if (space.isOwner(target.user.id)) {
    return false
  }
  const originMax = getMaxRolePosition(origin)
  const targetMax = getMaxRolePosition(target)
  return originMax < targetMax || originMax === targetMax
}

export const checkTargetRole = (origin: SpaceMemberRecord, targetRoleId: string) => {
  const space = SpaceStore.getSpace(origin.spaceId)
  invariant(space, `Space ${origin.spaceId} not found`)
  const targetRole = Object.values(space.roles).find((role) => role.id === targetRoleId)
  if (!targetRole || targetRole.isEveryone) {
    return false
  }
  if (space.isOwner(origin.user.id)) {
    return true
  }
  const originMax = getMaxRolePosition(origin)
  return originMax >= targetRole.position
}

export const checkMoveRoleFromTo = (origin: SpaceMemberRecord, from: number, to: number) => {
  const space = SpaceStore.getSpace(origin.spaceId)
  invariant(space, `Space ${origin.spaceId} not found`)
  if (space.isOwner(origin.user.id)) {
    return true
  }
  const fromRole = Object.values(space.roles).find((role) => role.position === from)
  const toRole = Object.values(space.roles).find((role) => role.position === to)
  if (!(fromRole && toRole)) {
    return false
  }
  const originMax = getMaxRolePosition(origin)
  return originMax >= fromRole.position && originMax >= toRole.position
}

export const getAssignableRoleIds = (origin: SpaceMemberRecord) => {
  const space = SpaceStore.getSpace(origin.spaceId)
  invariant(space, `Space ${origin.spaceId} not found`)
  const originMax = getMaxRolePosition(origin)
  return new Set(
    Object.values(space.roles)
      .filter((role) => role.position > originMax)
      .map((role) => role.id),
  )
}

export const can = (
  permission: bigint,
  {spaceId, userId, channelId}: {spaceId: string; userId?: string; channelId?: string | null},
): boolean => {
  const space = SpaceStore.getSpace(spaceId)
  const member = SpaceMemberStore.getMember(spaceId, userId ?? AuthenticationStore.getId())
  const channel = channelId ? ChannelStore.getChannel(channelId)! : null
  if (!(space && member)) {
    return false
  }
  const permissions = computePermissions({space, member, channel})
  return (permissions & permission) === permission
}
