import {FastForward, Pause, Play, Rewind, Volume2, VolumeX} from "lucide-react"
import {
  MediaControlBar,
  MediaController,
  MediaMuteButton,
  MediaPlayButton,
  MediaPlaybackRateButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaTimeDisplay,
  MediaTimeRange,
  MediaVolumeRange,
} from "media-chrome/react"
import type {FC} from "react"

const AUDIO_CONFIG = {
  PLAYBACK_RATES: [0.5, 1, 1.25, 1.5, 2],
  MAX_WIDTH: 32,
} as const

type EmbedAudioProps = {
  src: string
  title?: string
}

const mediaControllerStyles = {
  "--media-controls-transition":
    "transform 200ms cubic-bezier(0.4, 0, 0.2, 1), opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)",
  "--media-control-background": "transparent",
  "--media-control-hover-background": "rgba(255, 255, 255, 0.1)",
  "--media-primary-color": "white",
  "--media-secondary-color": "rgba(255, 255, 255, 0.7)",
  "--media-tooltip-background-color": "rgba(20, 20, 30, 0.9)",
  "--media-text-color": "white",
} as const

const timeRangeStyles = {
  "--media-range-track-background": "rgba(255, 255, 255, 0.15)",
  "--media-range-bar-color": "rgba(255, 255, 255, 0.9)",
  "--media-range-track-border-radius": "9999px",
  "--media-range-thumb-background": "white",
  "--media-range-thumb-width": "12px",
  "--media-range-thumb-height": "12px",
  "--media-range-thumb-border-radius": "9999px",
  "--media-preview-time-background": "rgb(30, 27, 75)",
  "--media-preview-time-margin-bottom": "16px",
  "--media-preview-time-padding": "4px 8px",
  "--media-preview-time-border-radius": "6px",
  "--media-preview-time-font-size": "12px",
} as const

const volumeRangeStyles = {
  "--media-range-track-background": "rgba(255, 255, 255, 0.15)",
  "--media-range-bar-color": "rgba(255, 255, 255, 0.9)",
  "--media-range-track-border-radius": "9999px",
  "--media-range-thumb-background": "white",
  "--media-range-thumb-width": "8px",
  "--media-range-thumb-height": "8px",
  "--media-range-thumb-border-radius": "9999px",
} as const

const controlBarStyles = {
  "--media-control-padding": "0",
  "--media-control-hover-background": "transparent",
} as const

const PlaybackControls: FC = () => (
  <div className="flex items-center gap-2">
    <MediaPlayButton className="flex h-10 w-10 items-center justify-center rounded-full bg-white/10 transition-all hover:bg-white/20 active:scale-95">
      <span slot="play">
        <Play className="ml-1 h-5 w-5" />
      </span>
      <span slot="pause">
        <Pause className="h-5 w-5" />
      </span>
    </MediaPlayButton>

    <div className="flex items-center gap-1">
      <MediaSeekBackwardButton className="flex h-8 w-8 items-center justify-center rounded-full transition-all hover:bg-white/10 active:scale-95">
        <Rewind className="h-4 w-4" />
      </MediaSeekBackwardButton>

      <MediaSeekForwardButton className="flex h-8 w-8 items-center justify-center rounded-full transition-all hover:bg-white/10 active:scale-95">
        <FastForward className="h-4 w-4" />
      </MediaSeekForwardButton>
    </div>

    <MediaTimeDisplay className="font-mono text-white/90 text-xs" showduration />
  </div>
)

const VolumeControls: FC = () => (
  <div className="flex items-center gap-1">
    <MediaMuteButton className="flex h-8 w-8 items-center justify-center rounded-full transition-all hover:bg-white/10 active:scale-95">
      <span slot="unmute">
        <VolumeX className="h-4 w-4" />
      </span>
      <span slot="mute">
        <Volume2 className="h-4 w-4" />
      </span>
    </MediaMuteButton>

    <MediaVolumeRange className="h-1.5 w-20" style={volumeRangeStyles} />
  </div>
)

const AudioControls: FC = () => (
  <MediaControlBar className="flex items-center justify-between gap-3" style={controlBarStyles}>
    <PlaybackControls />

    <div className="flex items-center gap-2">
      <VolumeControls />

      <MediaPlaybackRateButton
        className="flex h-7 min-w-[2rem] items-center justify-center rounded-full bg-white/10 font-medium text-white/90 text-xs transition-all hover:bg-white/20 active:scale-95"
        rates={AUDIO_CONFIG.PLAYBACK_RATES}
      />
    </div>
  </MediaControlBar>
)

const EmbedAudio: FC<EmbedAudioProps> = ({src, title}) => {
  return (
    <div className="w-full max-w-lg select-none">
      <MediaController
        className="group relative overflow-hidden rounded-lg bg-gradient-to-br from-indigo-950 to-purple-900 shadow-xl"
        audio
        defaultsubtitles
        style={mediaControllerStyles}
      >
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <audio slot="media" src={src} preload="metadata" />

        <div className="space-y-4 p-4">
          {title && <div className="font-medium text-sm text-white/90">{title}</div>}

          <MediaTimeRange className="h-2 w-full" style={timeRangeStyles} />

          <AudioControls />
        </div>
      </MediaController>
    </div>
  )
}

export default EmbedAudio
