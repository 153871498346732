import type {Parser, SingleASTNode} from "@khanacademy/simple-markdown"
import markdown from "@khanacademy/simple-markdown"

const STOP_PROCESSING = {}

const constrainAst = (
  content: SingleASTNode | Array<SingleASTNode>,
  state: {limit: number} = {limit: 200},
): SingleASTNode | Array<SingleASTNode> | typeof STOP_PROCESSING => {
  if (Array.isArray(content)) {
    const length = content.length
    for (let index = 0; index < length; index++) {
      const result = constrainAst(content[index], state)
      if (result === STOP_PROCESSING) {
        content.length = index
        break
      }
      content[index] = result as SingleASTNode
    }
  } else if (content.type !== "text") {
    state.limit -= 1
    if (state.limit <= 0) {
      return STOP_PROCESSING
    }
    if (Array.isArray(content.content)) {
      content.content = constrainAst(content.content, state) as Array<SingleASTNode>
    }
    if (content.type === "list" && Array.isArray(content.items)) {
      content.items = content.items.map((item) => constrainAst(item, state) as SingleASTNode)
    }
  }
  return content
}

export const astToString = (content: SingleASTNode | Array<SingleASTNode>): string => {
  const textContents: Array<string> = []
  traverseAndCollectText(content, textContents)
  return textContents.join("")
}

const traverseAndCollectText = (content: SingleASTNode | Array<SingleASTNode>, collector: Array<string>): void => {
  if (Array.isArray(content)) {
    for (const item of content) {
      traverseAndCollectText(item, collector)
    }
  } else {
    if (typeof content.content === "string") {
      collector.push(content.content)
    } else if (Array.isArray(content.content)) {
      traverseAndCollectText(content.content, collector)
    }
  }
}

export const flattenContent = (
  content: SingleASTNode | Array<SingleASTNode>,
  typeFilter: string | null = null,
): SingleASTNode | Array<SingleASTNode> => {
  if (Array.isArray(content)) {
    const flattened: Array<SingleASTNode> = []
    for (const item of content) {
      flattenHelper(item, flattened)
    }
    return flattened
  }

  if (content.content && typeof content.content !== "string") {
    content.content = flattenContent(content.content, content.type) as Array<SingleASTNode>
  }

  if (content.type === "list" && Array.isArray(content.items)) {
    content.items = content.items.map((item) => {
      if (Array.isArray(item)) {
        return flattenContent(item, null) as SingleASTNode
      }
      return item
    })
  }

  if (typeFilter && content.type === typeFilter) {
    return content.content as SingleASTNode
  }

  return content
}

const flattenHelper = (item: SingleASTNode, accumulator: Array<SingleASTNode>): void => {
  if (Array.isArray(item)) {
    for (const subItem of item) {
      flattenHelper(subItem, accumulator)
    }
  } else {
    accumulator.push(item)
  }
}

const processContent = (
  parser: Parser,
  input: string,
  inline: boolean,
  state: any,
  callback: ((content: any, inline: boolean) => any) | null = null,
): any => {
  let processedContent: any

  if (!inline) {
    // biome-ignore lint/style/noParameterAssign: <explanation>
    input += "\n\n"
  }

  processedContent = parser(input, {inline, ...state})
  processedContent = flattenContent(processedContent)
  processedContent = constrainAst(processedContent)

  if (callback != null) {
    processedContent = callback(processedContent, inline)
  }

  return processedContent
}

export const reactParserFor = (
  rules: any,
): ((input: string, inline?: boolean, state?: any, callback?: (content: any, inline: boolean) => any) => any) => {
  const parser = markdown.parserFor(rules)
  const reactRenderer = markdown.reactFor(markdown.ruleOutput(rules, "react"))
  return (
    input = "",
    inline = true,
    state: any = {},
    callback: ((content: any, inline: boolean) => any) | null = null,
  ): any => {
    const processedContent = processContent(parser, input, inline, state, callback)
    return reactRenderer(processedContent, state)
  }
}

export const astParserFor = (
  rules: any,
): ((input: string, inline?: boolean, state?: any, callback?: (content: any, inline: boolean) => any) => any) => {
  const parser = markdown.parserFor(rules)
  return (
    input = "",
    inline = true,
    state: any = {},
    callback: ((content: any, inline: boolean) => any) | null = null,
  ): any => {
    return processContent(parser, input, inline, state, callback)
  }
}
