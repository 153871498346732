import clsx from "clsx"
import {AnimatePresence, motion} from "motion/react"
import React from "react"
import {SKIN_TONE_SURROGATES} from "~/Constants"
import * as EmojiActionCreators from "~/actions/EmojiActionCreators"
import styles from "~/components/channel/EmojiPicker.module.css"
import {SpaceIcon} from "~/components/popouts/SpaceIcon"
import {ScrollArea} from "~/components/uikit/ScrollArea"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import {useForceUpdate} from "~/hooks/useForceUpdate"
import {i18n} from "~/i18n"
import {ClearIcon} from "~/icons/ClearIcon"
import {SearchIcon} from "~/icons/SearchIcon"
import {ComponentDispatch} from "~/lib/ComponentDispatch"
import * as UnicodeEmojis from "~/lib/UnicodeEmojis"
import type {UnicodeEmoji} from "~/lib/UnicodeEmojis"
import ChannelStore from "~/stores/ChannelStore"
import EmojiStore, {type Emoji} from "~/stores/EmojiStore"
import SpaceListStore from "~/stores/SpaceListStore"
import SpaceStore from "~/stores/SpaceStore"
import * as EmojiUtils from "~/utils/EmojiUtils"

const EMOJI_CLAP = EmojiUtils.fromCodePoint("1f44f")

type SkinTonePickerProps = {
  isOpen: boolean
  onClose: () => void
}

const SkinTonePicker = ({isOpen, onClose}: SkinTonePickerProps) => {
  const {skinTone} = EmojiStore.useStore()

  const handleSelect = (surrogate: string) => {
    EmojiActionCreators.setSkinTone(surrogate)
    ComponentDispatch.dispatch("EMOJI_PICKER_RERENDER")
    onClose()
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: "auto"}}
          exit={{opacity: 0, height: 0}}
          className={styles.skinTonePickerOptions}
        >
          {[skinTone, ...["", ...SKIN_TONE_SURROGATES].filter((surrogate) => surrogate !== skinTone)].map(
            (surrogate, index) => (
              <motion.div
                key={surrogate}
                initial={{opacity: 0, scale: index === 0 ? 1 : 0}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0}}
                role="button"
                tabIndex={0}
                className={styles.skinTonePickerItem}
                onClick={() => handleSelect(surrogate)}
                onKeyDown={(event) => event.key === "Enter" && handleSelect(surrogate)}
              >
                <div
                  className={styles.skinTonePickerItemImage}
                  style={{backgroundImage: `url(${EmojiUtils.getURL(EMOJI_CLAP + surrogate)})`}}
                />
              </motion.div>
            ),
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

type SkinTonePickerButtonProps = {
  onClick: () => void
  selectedEmojiURL: string
}

const SkinTonePickerButton = ({onClick, selectedEmojiURL}: SkinTonePickerButtonProps) => (
  <motion.div
    role="button"
    tabIndex={0}
    className={styles.skinTonePickerButton}
    onClick={onClick}
    onKeyDown={(event) => event.key === "Enter" && onClick()}
    style={{backgroundImage: `url(${selectedEmojiURL})`}}
    initial={{opacity: 1, scale: 1}}
    animate={{opacity: 1, scale: 1}}
    exit={{opacity: 0, scale: 0}}
  />
)

const SkinToneSelector = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const {skinTone} = EmojiStore.useStore()
  const selectedEmojiUrl = EmojiUtils.getURL(EMOJI_CLAP + skinTone)
  const selectorRef = React.useRef<HTMLDivElement | null>(null)

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div ref={selectorRef}>
      <SkinTonePicker isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <SkinTonePickerButton onClick={() => setIsOpen(true)} selectedEmojiURL={selectedEmojiUrl} />
    </div>
  )
}

export const EmojiPicker = ({channelId, handleSelect}: {channelId: string; handleSelect: (emoji: Emoji) => void}) => {
  const [searchTerm, setSearchTerm] = React.useState("")
  const [hoveredEmoji, setHoveredEmoji] = React.useState<Emoji | null>(null)
  const [activeCategory, setActiveCategory] = React.useState<string | null>(null)
  const [renderedEmojis, setRenderedEmojis] = React.useState<Array<Emoji>>([])
  const categoryRefs = React.useRef<Map<string, HTMLDivElement | null>>(new Map())
  const forceUpdate = useForceUpdate()

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setActiveCategory(entry.target.getAttribute("data-category"))
          }
        }
      },
      {threshold: 0.5},
    )

    for (const categoryRef of categoryRefs.current.values()) {
      if (categoryRef) {
        observer.observe(categoryRef)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleCategoryClick = (category: string) => {
    categoryRefs.current.get(category)?.scrollIntoView({behavior: "smooth"})
  }

  const handleHover = (emoji: Emoji | null) => {
    setHoveredEmoji(emoji)
  }

  React.useEffect(() => {
    const channel = ChannelStore.getChannel(channelId!)!
    const emojis = EmojiStore.search(channel, searchTerm)
    setRenderedEmojis(emojis)
  }, [searchTerm, channelId])

  React.useEffect(() => {
    return ComponentDispatch.subscribe("EMOJI_PICKER_RERENDER", forceUpdate)
  })

  const {spaces} = SpaceListStore.useStore()

  const customEmojisBySpaceId = React.useMemo(() => {
    const spaceEmojis = renderedEmojis.filter((emoji) => emoji.spaceId != null)
    const spaceEmojisBySpaceId = new Map<string, Array<Emoji>>()

    for (const spaceEmoji of spaceEmojis) {
      if (!spaceEmojisBySpaceId.has(spaceEmoji.spaceId!)) {
        spaceEmojisBySpaceId.set(spaceEmoji.spaceId!, [])
      }
      spaceEmojisBySpaceId.get(spaceEmoji.spaceId!)?.push(spaceEmoji)
    }

    const sortedSpaceIds = spaces.map((space) => space.id)
    const sortedSpaceEmojisBySpaceId = new Map<string, Array<Emoji>>()
    for (const spaceId of sortedSpaceIds) {
      if (spaceEmojisBySpaceId.has(spaceId)) {
        sortedSpaceEmojisBySpaceId.set(spaceId, spaceEmojisBySpaceId.get(spaceId)!)
      }
    }

    return sortedSpaceEmojisBySpaceId
  }, [renderedEmojis, spaces])

  const unicodeEmojisByCategory = React.useMemo(() => {
    const unicodeEmojis = renderedEmojis.filter((emoji) => emoji.spaceId == null)
    const unicodeEmojisByCategory = new Map<string, Array<Emoji>>()

    for (const emoji of unicodeEmojis) {
      const category = UnicodeEmojis.getCategoryForEmoji(emoji as UnicodeEmoji)!
      if (!unicodeEmojisByCategory.has(category)) {
        unicodeEmojisByCategory.set(category, [])
      }
      unicodeEmojisByCategory.get(category)?.push(emoji)
    }

    const categories = UnicodeEmojis.getCategories()
    const sortedUnicodeEmojisByCategory = new Map<string, Array<Emoji>>()
    for (const category of categories) {
      if (unicodeEmojisByCategory.has(category)) {
        sortedUnicodeEmojisByCategory.set(
          category,
          unicodeEmojisByCategory.get(category)!.sort((a, b) => a.index! - b.index!),
        )
      }
    }

    return sortedUnicodeEmojisByCategory
  }, [renderedEmojis])

  return (
    <div className="relative">
      <div className={styles.emojiPicker}>
        <EmojiPickerSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} hoveredEmoji={hoveredEmoji} />
        <EmojiPickerEmojiGrid
          customEmojisBySpaceId={customEmojisBySpaceId}
          unicodeEmojisByCategory={unicodeEmojisByCategory}
          renderedEmojis={renderedEmojis}
          categoryRefs={categoryRefs}
          handleHover={handleHover}
          handleSelect={handleSelect}
          searchTerm={searchTerm}
        />
        <EmojiPickerInspector hoveredEmoji={hoveredEmoji} />
      </div>
      <EmojiPickerCategoryList
        customEmojisBySpaceId={customEmojisBySpaceId}
        unicodeEmojisByCategory={unicodeEmojisByCategory}
        activeCategory={activeCategory}
        handleCategoryClick={handleCategoryClick}
      />
    </div>
  )
}

type EmojiPickerCategoryListProps = {
  customEmojisBySpaceId: Map<string, Array<Emoji>>
  unicodeEmojisByCategory: Map<string, Array<Emoji>>
  activeCategory: string | null
  handleCategoryClick: (category: string) => void
}

const EmojiPickerCategoryList = ({
  customEmojisBySpaceId,
  unicodeEmojisByCategory,
  activeCategory,
  handleCategoryClick,
}: EmojiPickerCategoryListProps) => (
  <div className={styles.categoryList}>
    <ScrollArea className={styles.list}>
      <div className={styles.listItems}>
        {Array.from(customEmojisBySpaceId.keys()).map((spaceId) => {
          const space = SpaceStore.getSpace(spaceId)!
          return (
            <Tooltip key={space.id} text={space.name} position="left">
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleCategoryClick(space.id)}
                onKeyDown={(event) => event.key === "Enter" && handleCategoryClick(space.id)}
                className={clsx(
                  styles.categoryListIcon,
                  activeCategory === space.id ? styles.categoryListIconActive : styles.textPrimaryMuted,
                )}
              >
                <SpaceIcon id={space.id} name={space.name} icon={space.icon} className={styles.iconSize} />
              </div>
            </Tooltip>
          )
        })}
        {Array.from(unicodeEmojisByCategory.keys()).map((category) => {
          const Icon = UnicodeEmojis.getCategoryIcon(category)
          return (
            <div
              key={category}
              role="button"
              tabIndex={0}
              onClick={() => handleCategoryClick(category)}
              onKeyDown={(event) => event.key === "Enter" && handleCategoryClick(category)}
              className={clsx(
                styles.categoryListIcon,
                activeCategory === category ? styles.categoryListIconActive : styles.textPrimaryMuted,
              )}
            >
              <Icon className={styles.iconSize} />
            </div>
          )
        })}
      </div>
    </ScrollArea>
  </div>
)

type EmojiPickerSearchBarProps = {
  searchTerm: string
  setSearchTerm: (term: string) => void
  hoveredEmoji: Emoji | null
}

const EmojiPickerSearchBar = ({searchTerm, setSearchTerm, hoveredEmoji}: EmojiPickerSearchBarProps) => (
  <div className={styles.header}>
    <div className={styles.searchBar}>
      <div className={styles.searchBarInner}>
        <input
          // biome-ignore lint/a11y/noAutofocus: <explanation>
          autoFocus={true}
          value={searchTerm}
          placeholder={
            hoveredEmoji ? hoveredEmoji.allNamesString.toString() : i18n.Messages.EMOJI_PICKER_SEARCH_PLACEHOLDER
          }
          onChange={(event) => setSearchTerm(event.target.value)}
          className={styles.searchBarInput}
        />
        <div
          role="button"
          tabIndex={searchTerm ? 0 : -1}
          aria-hidden={!searchTerm}
          aria-label={i18n.Messages.CLEAR_SEARCH}
          onClick={searchTerm ? () => setSearchTerm("") : undefined}
          onKeyDown={searchTerm ? (event) => event.key === "Enter" && setSearchTerm("") : undefined}
          className={clsx(styles.iconLayout, searchTerm ? styles.cursorPointer : styles.cursorText)}
        >
          <div className={styles.iconContainer}>
            <SearchIcon className={clsx(styles.icon, !searchTerm && styles.visible)} />
            <ClearIcon className={clsx(styles.icon, searchTerm && styles.visible)} />
          </div>
        </div>
      </div>
    </div>
    <SkinToneSelector />
  </div>
)

type EmojiPickerInspectorProps = {
  hoveredEmoji: Emoji | null
}

const EmojiPickerInspector = ({hoveredEmoji}: EmojiPickerInspectorProps) => (
  <div className={styles.inspector}>
    {hoveredEmoji && (
      <>
        <img src={hoveredEmoji.url ?? ""} alt={hoveredEmoji.name} className={styles.inspectorEmoji} loading="lazy" />
        <span className={styles.inspectorText}>{hoveredEmoji.allNamesString}</span>
      </>
    )}
  </div>
)

type EmojiPickerEmojiGridProps = {
  customEmojisBySpaceId: Map<string, Array<Emoji>>
  unicodeEmojisByCategory: Map<string, Array<Emoji>>
  renderedEmojis: Array<Emoji>
  categoryRefs: React.MutableRefObject<Map<string, HTMLDivElement | null>>
  handleHover: (emoji: Emoji | null) => void
  handleSelect: (emoji: Emoji) => void
  searchTerm: string
}

const EmojiPickerEmojiGrid = ({
  customEmojisBySpaceId,
  unicodeEmojisByCategory,
  renderedEmojis,
  categoryRefs,
  handleHover,
  handleSelect,
  searchTerm,
}: EmojiPickerEmojiGridProps) => {
  const [focusedIndex, setFocusedIndex] = React.useState<number | null>(null)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (focusedIndex != null) {
      const rowCount = 9
      if (event.key === "ArrowDown") {
        const nextIndex = focusedIndex + rowCount
        if (nextIndex < renderedEmojis.length) {
          setFocusedIndex(nextIndex)
        } else {
          setFocusedIndex(renderedEmojis.length - 1)
        }
        event.preventDefault()
      } else if (event.key === "ArrowUp") {
        const prevIndex = focusedIndex - rowCount
        if (prevIndex >= 0) {
          setFocusedIndex(prevIndex)
        } else {
          setFocusedIndex(0)
        }
        event.preventDefault()
      } else if (event.key === "ArrowRight") {
        const nextIndex = focusedIndex + 1
        if (nextIndex < renderedEmojis.length) {
          setFocusedIndex(nextIndex)
        }
        event.preventDefault()
      } else if (event.key === "ArrowLeft") {
        const prevIndex = focusedIndex - 1
        if (prevIndex >= 0) {
          setFocusedIndex(prevIndex)
        }
        event.preventDefault()
      } else if (event.key === "Enter" && focusedIndex != null) {
        handleSelect(renderedEmojis[focusedIndex])
        event.preventDefault()
      }
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (focusedIndex == null && renderedEmojis.length > 0) {
      setFocusedIndex(0)
    }
  }, [renderedEmojis])

  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.emojiPickerListWrapper} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
        <div className={styles.listWrapper}>
          <ScrollArea className={styles.list}>
            <div className={styles.listItems}>
              {searchTerm ? (
                <div className={styles.emojiGrid}>
                  {renderedEmojis.map((emoji, index) => (
                    <EmojiRenderer
                      key={emoji.name}
                      emoji={emoji}
                      handleHover={handleHover}
                      handleSelect={handleSelect}
                      focused={focusedIndex === index}
                      setFocusedIndex={() => setFocusedIndex(index)}
                    />
                  ))}
                </div>
              ) : (
                <>
                  {Array.from(customEmojisBySpaceId.keys()).map((spaceId) => {
                    const emojis = customEmojisBySpaceId.get(spaceId)!
                    return (
                      <EmojiGridCategory
                        key={spaceId}
                        emojis={emojis}
                        category={`custom-${spaceId}`}
                        categoryRef={(el) => {
                          categoryRefs.current.set(spaceId, el)
                        }}
                        handleHover={handleHover}
                        handleSelect={handleSelect}
                        focusedIndex={focusedIndex}
                        setFocusedIndex={setFocusedIndex}
                        categoryStartIndex={Array.from(customEmojisBySpaceId.values())
                          .slice(0, Array.from(customEmojisBySpaceId.keys()).indexOf(spaceId))
                          .reduce((acc, curr) => acc + curr.length, 0)}
                      />
                    )
                  })}

                  {Array.from(unicodeEmojisByCategory.keys()).map((category, categoryIndex) => (
                    <EmojiGridCategory
                      key={category}
                      emojis={unicodeEmojisByCategory.get(category)!}
                      category={category}
                      categoryRef={(el) => {
                        categoryRefs.current.set(category, el)
                      }}
                      handleHover={handleHover}
                      handleSelect={handleSelect}
                      focusedIndex={focusedIndex}
                      setFocusedIndex={setFocusedIndex}
                      categoryStartIndex={Array.from(unicodeEmojisByCategory.values())
                        .slice(0, categoryIndex)
                        .reduce((acc, curr) => acc + curr.length, 0)}
                    />
                  ))}
                </>
              )}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  )
}

type EmojiGridCategoryProps = {
  category: string
  emojis: Array<Emoji>
  categoryRef: (el: HTMLDivElement | null) => void
  handleHover: (emoji: Emoji | null) => void
  handleSelect: (emoji: Emoji) => void
  focusedIndex: number | null
  setFocusedIndex: (index: number) => void
  categoryStartIndex: number
}

const EmojiGridCategory = ({
  category,
  emojis,
  categoryRef,
  handleHover,
  handleSelect,
  focusedIndex,
  setFocusedIndex,
  categoryStartIndex,
}: EmojiGridCategoryProps) => (
  <div ref={categoryRef} data-category={category} className={styles.emojiGridCategory}>
    <h2 className={styles.categoryTitle}>
      {category.startsWith("custom-")
        ? SpaceStore.getSpace(category.slice(7))?.name
        : UnicodeEmojis.getCategoryLabel(category)}
    </h2>
    <div className={styles.emojiGrid}>
      {emojis.map((emoji, index) => (
        <EmojiRenderer
          key={emoji.name}
          emoji={emoji}
          handleHover={handleHover}
          handleSelect={handleSelect}
          focused={focusedIndex === categoryStartIndex + index}
          setFocusedIndex={() => setFocusedIndex(categoryStartIndex + index)}
        />
      ))}
    </div>
  </div>
)

type EmojiRendererProps = {
  emoji: Emoji
  handleHover: (emoji: Emoji | null) => void
  handleSelect: (emoji: Emoji) => void
  focused: boolean
  setFocusedIndex: () => void
}

const EmojiRenderer = ({emoji, handleHover, handleSelect, focused, setFocusedIndex}: EmojiRendererProps) => {
  const emojiRef = React.useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={emojiRef}
      onMouseEnter={() => handleHover(emoji)}
      onClick={() => handleSelect(emoji)}
      onFocus={() => handleHover(emoji)}
      onBlur={() => handleHover(null)}
      className={clsx(styles.emojiRenderer, focused && styles.focusedEmojiRenderer)}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSelect(emoji)
          event.preventDefault()
          event.stopPropagation()
        }
      }}
      onFocusCapture={setFocusedIndex}
    >
      <img src={emoji.url ?? ""} alt={emoji.name} className={styles.emojiImage} loading="lazy" />
    </div>
  )
}
