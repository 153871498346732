import clsx from "clsx"
import React from "react"
import {type APIErrorCode, APIErrorCodes} from "~/Constants"
import * as InviteActionCreators from "~/actions/InviteActionCreators"
import {SpaceIcon} from "~/components/popouts/SpaceIcon"
import {i18n} from "~/i18n"
import {SmileySadIcon} from "~/icons/SmileySadIcon"
import type {HttpResponse} from "~/lib/HttpClient"
import AuthenticationStore from "~/stores/AuthenticationStore"
import DeveloperOptionsStore from "~/stores/DeveloperOptionsStore"
import type {Invite as InviteType} from "~/stores/InviteStore"
import PresenceStore from "~/stores/PresenceStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"

export const Invite = ({code}: {code: string}) => {
  const [invite, setInvite] = React.useState<InviteType | null>(null)
  const [notFoundError, setNotFoundError] = React.useState(false)

  React.useEffect(() => {
    InviteActionCreators.fetch(code)
      .then(setInvite)
      .catch((error: HttpResponse) => {
        if (
          error.body &&
          typeof error.body === "object" &&
          ((error.body as any).code as APIErrorCode) === APIErrorCodes.UNKNOWN_INVITE
        ) {
          setNotFoundError(true)
        }
      })
  }, [code])

  if (notFoundError) {
    return <InviteNotFoundError />
  }

  if (DeveloperOptionsStore.getForceRenderPlaceholders() || invite == null) {
    return <InviteSkeleton />
  }

  const presenceCount = PresenceStore.getPresenceCount(invite.space.id)
  const memberCount = SpaceMemberStore.getMemberCount(invite.space.id)
  const isMemberAlready = SpaceMemberStore.getMember(invite.space.id, AuthenticationStore.getId()) != null

  const handleAcceptInvite = () => InviteActionCreators.acceptAndTransitionToChannel(invite.code)
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleAcceptInvite()
    }
  }

  return (
    <div className="box-border flex w-full min-w-[160px] max-w-[432px] cursor-default select-none flex-col rounded-md bg-background-primary p-4">
      <div className="flex flex-wrap gap-4">
        <div className="flex max-w-full flex-[1000_0_auto] items-center gap-4">
          <SpaceIcon id={invite.space.id} name={invite.space.name} icon={invite.space.icon} className="h-12 w-12" />
          <div className="flex min-w-0 flex-1 flex-col items-stretch justify-center">
            <div
              className={clsx(
                "mb-1 overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-base text-text-primary leading-[20px]",
                isMemberAlready && "cursor-pointer hover:underline",
              )}
              role={isMemberAlready ? "button" : undefined}
              onClick={isMemberAlready ? handleAcceptInvite : undefined}
              onKeyDown={isMemberAlready ? handleKeyDown : undefined}
            >
              {invite.space.name}
            </div>

            <div className="text-sm leading-4">
              <div className="flex flex-wrap items-center gap-3">
                <StatusBadge
                  color="bg-green-500"
                  text={i18n.format(i18n.Messages.ONLINE_COUNT, {
                    count: presenceCount || invite.presence_count,
                  })}
                />
                <StatusBadge
                  color="bg-gray-500"
                  text={i18n.format(i18n.Messages.MEMBER_COUNT, {
                    count: memberCount || invite.member_count,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <JoinButton isMemberAlready={isMemberAlready} onClick={handleAcceptInvite} />
      </div>
    </div>
  )
}

const StatusBadge = ({color, text}: {color: string; text: string}) => (
  <div className="flex min-w-0 flex-initial flex-nowrap items-center">
    <i className={clsx("mr-1 block h-2 w-2 flex-none rounded-full", color)} />
    <span className="text-text-secondary">{text}</span>
  </div>
)

const JoinButton = ({isMemberAlready, onClick}: {isMemberAlready: boolean; onClick: () => void}) => (
  <button
    type="button"
    className="relative flex h-[40px] w-auto flex-auto cursor-pointer items-center justify-center self-center overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border-none bg-green-600 px-4 py-2 font-semibold text-sm text-white leading-5 transition-colors duration-150 ease-in-out hover:bg-green-700 active:bg-green-800"
    onClick={onClick}
  >
    {isMemberAlready ? i18n.Messages.JOINED : i18n.Messages.JOIN}
  </button>
)

const InviteSkeleton = () => (
  <div className="box-border flex w-full min-w-[160px] max-w-[432px] cursor-default select-none flex-col rounded-md bg-background-primary p-4">
    <div className="flex flex-wrap gap-4">
      <div className="flex max-w-full flex-[1000_0_auto] items-center gap-4">
        <div className="h-12 w-12 animate-pulse rounded-full bg-background-header-secondary" />
        <div className="flex min-w-0 flex-1 flex-col items-stretch justify-center">
          <div className="mb-1 h-5 w-3/4 animate-pulse rounded-md bg-background-header-secondary" />
          <div className="text-sm leading-4">
            <div className="flex flex-wrap items-center gap-3">
              <div className="flex min-w-0 flex-initial flex-nowrap items-center">
                <i className="mr-1 block h-2 w-2 flex-none animate-pulse rounded-full bg-background-header-secondary" />
                <span className="h-4 w-12 animate-pulse rounded-md bg-background-header-secondary" />
              </div>
              <div className="flex min-w-0 flex-initial flex-nowrap items-center">
                <i className="mr-1 block h-2 w-2 flex-none animate-pulse rounded-full bg-background-header-secondary" />
                <span className="h-4 w-12 animate-pulse rounded-md bg-background-header-secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex h-[40px] w-full flex-auto animate-pulse items-center justify-center self-center rounded-md bg-background-header-secondary px-4 py-2 lg:w-[60px]" />
    </div>
  </div>
)

const InviteNotFoundError = () => (
  <div className="box-border flex w-full min-w-[160px] max-w-[432px] cursor-default select-none flex-col rounded-md bg-background-primary p-4">
    <div className="flex flex-wrap gap-4">
      <div className="flex max-w-full flex-[1000_0_auto] items-center gap-4">
        <SmileySadIcon className="h-12 w-12 text-background-header-secondary" />
        <div className="flex min-w-0 flex-1 flex-col items-stretch justify-center">
          <div className="mb-1 text-ellipsis whitespace-nowrap font-semibold text-lg text-red-600 leading-[1.25]">
            {i18n.Messages.INVALID_INVITE}
          </div>
          <div className="text-sm text-text-secondary leading-4">{i18n.Messages.INVALID_INVITE_DESCRIPTION}</div>
        </div>
      </div>
    </div>
  </div>
)
