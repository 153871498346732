import * as RegexUtils from "~/utils/RegexUtils"

const INVITE_REGEX = new RegExp(`${RegexUtils.escapeRegex(location.host)}(?:/#)?/invite/([a-z0-9\-]+)`, "ig")

export const findInvites = (content: string) => {
  if (content == null) {
    return []
  }
  const links = content.match(INVITE_REGEX)
  if (links != null) {
    return [
      ...new Set(
        links.map((link) => {
          const parts = link.split("/")
          return parts[parts.length - 1]
        }),
      ),
    ].slice(0, 10)
  }
  return []
}

export const findInvite = (content: string) => {
  const invites = findInvites(content)
  return invites.length > 0 ? invites[0] : null
}
