import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  editingMessageIds: Record<string, string>
}

const initialState: State = {
  editingMessageIds: {},
}

class MessageEditStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "MESSAGE_EDIT_START":
        return this.handleMessageEditStart(action)
      case "MESSAGE_EDIT_STOP":
        return this.handleMessageEditStop(action)
      default:
        return false
    }
  }

  useIsEditing(channelId: string, messageId: string): boolean {
    const {editingMessageIds} = this.useStore()
    return editingMessageIds[channelId] === messageId
  }

  useEditingMessageId(channelId: string): string | null {
    const {editingMessageIds} = this.useStore()
    return editingMessageIds[channelId] || null
  }

  private handleMessageEditStart({channelId, messageId}: {channelId: string; messageId: string}) {
    this.setState((prevState) => {
      const editingMessageIds = {...prevState.editingMessageIds}
      editingMessageIds[channelId] = messageId
      return {editingMessageIds}
    })
  }

  private handleMessageEditStop({channelId}: {channelId: string}) {
    this.setState((prevState) => {
      const editingMessageIds = {...prevState.editingMessageIds}
      delete editingMessageIds[channelId]
      return {editingMessageIds}
    })
  }
}

export default new MessageEditStore()
