import clsx from "clsx"
import prettyBytes from "pretty-bytes"
import {FileIcon} from "~/icons/FileIcon"
import type {MessageAttachment} from "~/records/MessageRecord"
import styles from "./AttachmentFile.module.css"

type AttachmentFileProps = {
  attachment: MessageAttachment
  isPreview?: boolean
}

export const AttachmentFile = ({attachment, isPreview}: AttachmentFileProps) => {
  const fileName = attachment.title || attachment.filename
  const fileSize = prettyBytes(attachment.size)

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={clsx(styles.attachment, isPreview && styles.small)}>
          <FileIcon className={styles.icon} aria-hidden={true} />
          <div className={styles.content}>
            <div className={styles.filename}>
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer noopener"
                href={attachment.url}
                aria-label={`${fileName} (${fileSize})`}
              >
                {fileName}
              </a>
            </div>
            <div className={styles.metadata}>{fileSize}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
