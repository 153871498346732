import EventEmitter from "eventemitter3"
import {GatewayCloseCodes, type GatewayOpCode, GatewayOpCodes, type StatusType} from "~/Constants"
import {ExponentialBackoff} from "~/lib/ExponentialBackoff"
import {Logger} from "~/lib/Logger"

enum GatewayTimeouts {
  HeartbeatAck = 15000,
  Connection = 10000,
  ResumeWindow = 90000,
  ExpeditedHeartbeatAck = 5000,
  RateLimit = 30000,
}

enum GatewayConnectionState {
  Disconnected = "DISCONNECTED",
  Connecting = "CONNECTING",
  Identifying = "IDENTIFYING",
  Resuming = "RESUMING",
  Connected = "CONNECTED",
}

type GatewayPayload = {
  op: GatewayOpCode
  d?: any
  s?: number
  t?: string
}

type GatewayClientOptions = {
  token: string
  apiVersion?: number
  properties?: GatewayClientProperties
  debug?: boolean
}

type GatewayClientProperties = {
  os?: string
  browser?: string
  device?: string
  locale?: string
  browser_version?: string
  os_version?: string
  [key: string]: any
}

type CloseEvent = {
  code: number
  reason: string
  wasClean: boolean
}

class JsonEncoder {
  private logger: Logger

  constructor() {
    this.logger = new Logger("JsonEncoder")
  }

  encode(data: any): string {
    try {
      return JSON.stringify(data)
    } catch (error) {
      this.logger.error(`Failed to stringify JSON: ${error}`)
      throw error
    }
  }

  decode(data: string): any {
    try {
      return JSON.parse(data)
    } catch (error) {
      this.logger.error(`Failed to parse JSON: ${error}`)
      throw error
    }
  }

  getName(): string {
    return "json"
  }

  wantString(): boolean {
    return true
  }
}

export class GatewayClient extends EventEmitter {
  private readonly logger = new Logger("GatewayClient")
  private readonly encoder = new JsonEncoder()
  private readonly backoff = new ExponentialBackoff()

  private websocket: WebSocket | null = null
  private connectionStartTime = 0
  private expectedClose = false

  private state = GatewayConnectionState.Disconnected
  private sessionId: string | null = null
  private sequence = 0

  private heartbeatIntervalId: number | null = null
  private heartbeatTimeoutId: number | null = null
  private lastHeartbeatAck = true
  private heartbeatAcked = true
  private expeditedHeartbeatTimeoutId: number | null = null

  private connectionTimeoutId: number | null = null
  private reconnectTimeoutId: number | null = null

  constructor(
    private readonly url: string,
    private readonly options: GatewayClientOptions,
  ) {
    super()
    this.bindEventHandlers()
  }

  connect(): void {
    if (this.websocket) this.cleanup()

    const gatewayUrl = this.buildGatewayUrl()
    this.websocket = new WebSocket(gatewayUrl)
    this.connectionStartTime = Date.now()

    this.attachWebSocketListeners()
    this.updateState(GatewayConnectionState.Connecting)
    this.setupConnectionTimeout()
  }

  disconnect(code = 1000, reason = "Client disconnecting"): void {
    this.expectedClose = true
    this.cleanup()

    if (this.websocket?.readyState === WebSocket.OPEN) {
      this.websocket.close(code, reason)
    }

    this.updateState(GatewayConnectionState.Disconnected)
  }

  async expediteHeartbeat(timeout = GatewayTimeouts.ExpeditedHeartbeatAck): Promise<boolean> {
    if (!this.isConnected() || !this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      return false
    }

    this.clearExpeditedHeartbeatTimeout()

    if (!this.heartbeatAcked) {
      this.logger.warn("Expedited heartbeat failed - previous heartbeat not acknowledged")
      this.reconnect()
      return false
    }

    return new Promise((resolve, reject) => {
      this.heartbeatAcked = false
      this.sendHeartbeat()

      this.expeditedHeartbeatTimeoutId = window.setTimeout(() => {
        this.expeditedHeartbeatTimeoutId = null
        if (!this.heartbeatAcked) {
          this.logger.warn("Expedited heartbeat timeout")
          this.reconnect()
          reject(new Error("Expedited heartbeat timeout"))
        }
      }, timeout)

      const handleAck = () => {
        this.clearExpeditedHeartbeatTimeout()
        resolve(true)
      }

      this.once("heartbeatAck", handleAck)

      setTimeout(() => {
        this.removeListener("heartbeatAck", handleAck)
      }, timeout)
    })
  }

  handleNetworkStatusChange(online: boolean): void {
    this.logger.info(`Network status changed - Online: ${online}`)

    if (online && this.isConnected()) {
      this.expediteHeartbeat().catch((error) => {
        this.logger.warn("Network restored but gateway connection failed:", error)
      })
    }
  }

  updatePresence(status: StatusType): void {
    this.sendPayload({
      op: GatewayOpCodes.PRESENCE_UPDATE,
      d: {status},
    })
  }

  setToken(token: string): void {
    this.options.token = token
  }

  private bindEventHandlers(): void {
    this.handleOpen = this.handleOpen.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  private handleOpen(): void {
    this.logger.info("WebSocket connected")
    this.clearConnectionTimeout()

    const canResume =
      this.sessionId && this.sequence > 0 && Date.now() - this.connectionStartTime <= GatewayTimeouts.ResumeWindow

    if (canResume) {
      this.resumeSession()
    } else {
      this.identifySession()
    }
  }

  private handleMessage(event: MessageEvent): void {
    try {
      const payload = this.encoder.decode(event.data)
      this.processGatewayPayload(payload)
    } catch (error) {
      this.logger.warn("Failed to decode message:", error)
      this.disconnect(GatewayCloseCodes.DECODE_ERROR, "Failed to decode message")
    }
  }

  private handleClose(event: CloseEvent): void {
    this.logger.warn(`WebSocket closed: [${event.code}] ${event.reason}`)

    const wasClean = event.wasClean || this.expectedClose
    this.cleanup()

    this.emit("close", {
      code: event.code,
      reason: event.reason,
      wasClean,
    })

    switch (event.code) {
      case GatewayCloseCodes.AUTHENTICATION_FAILED:
      case GatewayCloseCodes.INVALID_API_VERSION:
        this.updateState(GatewayConnectionState.Disconnected)
        break
      case GatewayCloseCodes.RATE_LIMITED:
        this.scheduleReconnection(GatewayTimeouts.RateLimit)
        break
      default:
        if (!this.expectedClose) {
          this.scheduleReconnection()
        } else {
          this.updateState(GatewayConnectionState.Disconnected)
        }
    }
  }

  private handleError(event: Event): void {
    this.logger.warn("WebSocket error:", event)

    if (this.websocket) {
      this.websocket.close(GatewayCloseCodes.UNKNOWN_ERROR, "WebSocket error")
    }
  }

  private processGatewayPayload(payload: GatewayPayload): void {
    this.logger.info("<~", payload.t || payload.op, payload.d || "")

    if (payload.s !== undefined) {
      this.sequence = payload.s
    }

    switch (payload.op) {
      case GatewayOpCodes.DISPATCH:
        this.handleDispatch(payload)
        break
      case GatewayOpCodes.HEARTBEAT:
        this.sendHeartbeat()
        break
      case GatewayOpCodes.RECONNECT:
        this.handleServerReconnect()
        break
      case GatewayOpCodes.INVALID_SESSION:
        this.handleInvalidSession(payload.d as boolean)
        break
      case GatewayOpCodes.HELLO:
        this.handleHello(payload.d)
        break
      case GatewayOpCodes.HEARTBEAT_ACK:
        this.handleHeartbeatAcknowledgment()
        break
    }

    this.emit("message", payload)
  }

  private handleDispatch(payload: GatewayPayload): void {
    if (payload.t === "READY") {
      this.sessionId = payload.d.session_id
      this.updateState(GatewayConnectionState.Connected)
      this.backoff.reset()
    }

    this.emit("dispatch", payload.t, payload.d)
  }

  private handleHello(data: any): void {
    if (!data?.heartbeat_interval) {
      this.disconnect(GatewayCloseCodes.DECODE_ERROR, "Invalid HELLO message")
      return
    }

    this.initializeHeartbeat(data.heartbeat_interval)
  }

  private handleHeartbeatAcknowledgment(): void {
    this.heartbeatAcked = true
    this.lastHeartbeatAck = true
    this.clearHeartbeatTimeout()
    this.clearExpeditedHeartbeatTimeout()
    this.emit("heartbeatAck")
  }

  private handleServerReconnect(): void {
    this.logger.warn("Server requested reconnect")
    this.reconnect()
  }

  private handleInvalidSession(resumable: boolean): void {
    this.logger.warn("Invalid session, resumable:", resumable)

    if (resumable && this.sessionId) {
      this.resumeSession()
    } else {
      this.sessionId = null
      this.sequence = 0
      this.identifySession()
    }
  }

  private initializeHeartbeat(interval: number): void {
    this.stopHeartbeat()

    const sendHeartbeatWithJitter = () => {
      if (!this.lastHeartbeatAck) {
        this.logger.warn("Heartbeat timeout - no acknowledgment")
        this.reconnect()
        return
      }

      this.sendHeartbeat()
      this.lastHeartbeatAck = false

      this.heartbeatTimeoutId = window.setTimeout(() => {
        if (!this.lastHeartbeatAck) {
          this.logger.warn("Heartbeat timeout - no response")
          this.reconnect()
        }
      }, GatewayTimeouts.HeartbeatAck)

      const jitteredInterval = Math.random() * interval

      this.heartbeatIntervalId = window.setTimeout(sendHeartbeatWithJitter, jitteredInterval)
    }

    const initialJitteredInterval = Math.random() * interval
    this.heartbeatIntervalId = window.setTimeout(sendHeartbeatWithJitter, initialJitteredInterval)

    this.sendHeartbeat()
  }

  private stopHeartbeat(): void {
    if (this.heartbeatIntervalId) {
      clearTimeout(this.heartbeatIntervalId)
      this.heartbeatIntervalId = null
    }

    this.clearHeartbeatTimeout()
  }

  private sendHeartbeat(): void {
    this.sendPayload({
      op: GatewayOpCodes.HEARTBEAT,
      d: this.sequence,
    })
  }

  private identifySession(): void {
    this.updateState(GatewayConnectionState.Identifying)

    this.sendPayload({
      op: GatewayOpCodes.IDENTIFY,
      d: {
        token: this.options.token,
        properties: this.options.properties,
      },
    })
  }

  private resumeSession(): void {
    if (!this.sessionId || !this.options.token) {
      this.identifySession()
      return
    }

    this.updateState(GatewayConnectionState.Resuming)

    this.sendPayload({
      op: GatewayOpCodes.RESUME,
      d: {
        token: this.options.token,
        session_id: this.sessionId,
        seq: this.sequence,
      },
    })
  }

  private reconnect(): void {
    this.cleanup()
    this.scheduleReconnection()
  }

  private scheduleReconnection(delay?: number): void {
    if (this.reconnectTimeoutId) return

    const reconnectionDelay = delay ?? this.backoff.next()

    if (reconnectionDelay === -1) {
      this.logger.warn("Max reconnection attempts reached")
      this.updateState(GatewayConnectionState.Disconnected)
      this.emit("disconnected", new Error("Max reconnection attempts reached"))
      return
    }

    this.logger.warn(`Reconnecting in ${reconnectionDelay}ms`)

    this.reconnectTimeoutId = window.setTimeout(() => {
      this.reconnectTimeoutId = null
      this.connect()
    }, reconnectionDelay)
  }

  private buildGatewayUrl(): string {
    const gatewayUrl = new URL(this.url)
    gatewayUrl.searchParams.set("v", (this.options.apiVersion ?? 1).toString())
    gatewayUrl.searchParams.set("encoding", this.encoder.getName())

    return gatewayUrl.toString()
  }

  private attachWebSocketListeners(): void {
    if (!this.websocket) return

    this.websocket.addEventListener("open", this.handleOpen)
    this.websocket.addEventListener("message", this.handleMessage)
    this.websocket.addEventListener("close", this.handleClose)
    this.websocket.addEventListener("error", this.handleError)
  }

  private sendPayload(payload: GatewayPayload): void {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) return

    try {
      const encoded = this.encoder.encode(payload)
      this.websocket.send(encoded)
      this.logger.info("~>", payload.t || payload.op, payload.d || "")
    } catch (error) {
      this.logger.warn("Failed to send message:", error)
      this.reconnect()
    }
  }

  private setupConnectionTimeout(): void {
    this.clearConnectionTimeout()
    this.connectionTimeoutId = window.setTimeout(() => {
      this.logger.warn("Connection timeout")
      this.cleanup()
      this.scheduleReconnection()
    }, GatewayTimeouts.Connection)
  }

  private clearConnectionTimeout(): void {
    if (this.connectionTimeoutId) {
      clearTimeout(this.connectionTimeoutId)
      this.connectionTimeoutId = null
    }
  }

  private clearHeartbeatTimeout(): void {
    if (this.heartbeatTimeoutId) {
      clearTimeout(this.heartbeatTimeoutId)
      this.heartbeatTimeoutId = null
    }
  }

  private clearExpeditedHeartbeatTimeout(): void {
    if (this.expeditedHeartbeatTimeoutId) {
      clearTimeout(this.expeditedHeartbeatTimeoutId)
      this.expeditedHeartbeatTimeoutId = null
    }
  }

  reset(): void {
    this.cleanup()

    this.state = GatewayConnectionState.Disconnected
    this.sessionId = null
    this.sequence = 0
    this.backoff.reset()
    this.heartbeatAcked = true
    this.lastHeartbeatAck = true
    this.expectedClose = false

    this.logger.info("GatewayClient has been reset to its initial state.")
  }

  private cleanup(): void {
    this.stopHeartbeat()
    this.clearConnectionTimeout()
    this.clearExpeditedHeartbeatTimeout()

    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId)
      this.reconnectTimeoutId = null
    }

    this.heartbeatAcked = true
    this.lastHeartbeatAck = true

    if (this.websocket) {
      this.websocket.removeEventListener("open", this.handleOpen)
      this.websocket.removeEventListener("message", this.handleMessage)
      this.websocket.removeEventListener("close", this.handleClose)
      this.websocket.removeEventListener("error", this.handleError)

      if (this.websocket.readyState === WebSocket.OPEN) {
        try {
          this.websocket.close()
        } catch (error) {
          this.logger.warn("Error closing WebSocket:", error)
        }
      }

      this.websocket = null
    }
  }

  private updateState(newState: GatewayConnectionState): void {
    const previousState = this.state
    this.state = newState

    this.logger.info(`State changed: ${previousState} -> ${newState}`)
    this.emit("stateChange", newState, previousState)
  }

  getState(): GatewayConnectionState {
    return this.state
  }

  getSessionId(): string | null {
    return this.sessionId
  }

  getSequence(): number {
    return this.sequence
  }

  isConnected(): boolean {
    return this.state === GatewayConnectionState.Connected
  }
}
