import * as ReactionActionCreators from "~/actions/ReactionActionCreators"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type UserPartial, UserRecord} from "~/records/UserRecord"
import UserStore from "~/stores/UserStore"
import {type ReactionEmoji, getReactionKey} from "~/utils/ReactionUtils"

type ReactionUsers = Record<string, UserRecord>

type State = {
  reactions: Record<string, {users: ReactionUsers; fetched: boolean}>
}

const initialState: State = {
  reactions: {},
}

class MessageReactionsStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen()
      case "MESSAGE_REACTION_ADD":
      case "MESSAGE_REACTION_REMOVE":
        return this.handleReaction(action)
      case "MESSAGE_REACTION_REMOVE_ALL":
        return this.handleRemoveAllReactions(action)
      case "MESSAGE_REACTION_REMOVE_EMOJI":
        return this.handleRemoveEmojiReactions(action)
      case "MESSAGE_REACTION_ADD_USERS":
        return this.handleAddUserReactions(action)
      default:
        return false
    }
  }

  getReactions(channelId: string, messageId: string, emoji: ReactionEmoji): Array<UserRecord> {
    const reaction = this.ensureReaction(messageId, emoji)
    if (!reaction.fetched) {
      ReactionActionCreators.getReactions(channelId, messageId, emoji)
      reaction.fetched = true
    }
    return Object.values(reaction.users)
  }

  private ensureReaction(messageId: string, emoji: ReactionEmoji) {
    const key = getReactionKey(messageId, emoji)
    let reaction = this.state.reactions[key]

    if (!reaction) {
      this.setState((state) => {
        const newReaction = {users: {}, fetched: false}
        const newReactions = {...state.reactions, [key]: newReaction}
        return {
          ...state,
          reactions: newReactions,
        }
      })
      reaction = this.state.reactions[key]
    }

    return reaction!
  }

  private handleConnectionOpen() {
    this.setState({reactions: {}})
  }

  private handleReaction(action: Action & {messageId: string; userId: string; emoji: ReactionEmoji}) {
    this.setState((state) => {
      const key = getReactionKey(action.messageId, action.emoji)
      const reaction = state.reactions[key] || {users: {}, fetched: false}

      if (action.type === "MESSAGE_REACTION_ADD") {
        const user = UserStore.getUser(action.userId)
        if (user) {
          reaction.users[action.userId] = user
        }
      } else {
        delete reaction.users[action.userId]
      }

      const newReactions = {...state.reactions, [key]: reaction}

      return {
        ...state,
        reactions: newReactions,
      }
    })
  }

  private handleRemoveAllReactions(action: {messageId: string}) {
    this.setState((state) => {
      const newReactions = {...state.reactions}
      for (const key in newReactions) {
        if (key.startsWith(action.messageId)) {
          delete newReactions[key]
        }
      }
      return {...state, reactions: newReactions}
    })
  }

  private handleRemoveEmojiReactions(action: {messageId: string; emoji: ReactionEmoji}) {
    this.setState((state) => {
      const key = getReactionKey(action.messageId, action.emoji)
      const newReactions = {...state.reactions, [key]: {users: {}, fetched: false}}
      return {...state, reactions: newReactions}
    })
  }

  private handleAddUserReactions(action: {messageId: string; users: Array<UserPartial>; emoji: ReactionEmoji}) {
    this.setState((state) => {
      const key = getReactionKey(action.messageId, action.emoji)
      const reaction = state.reactions[key] || {users: {}, fetched: false}

      for (const user of action.users) {
        reaction.users[user.id] = new UserRecord(user)
      }

      const newReactions = {...state.reactions, [key]: reaction}

      return {
        ...state,
        reactions: newReactions,
      }
    })
  }
}

export default new MessageReactionsStore()
