import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {ProfileRecord} from "~/records/ProfileRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"

type Profiles = Record<string, ProfileRecord>

type State = {
  profiles: Record<string, Profiles>
}

const initialState: State = {
  profiles: {},
}

class UserProfileStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen()
      case "USER_PROFILE_CREATE":
        return this.setProfile(action)
      case "USER_PROFILES_CLEAR":
        return this.clearUserProfiles()
      default:
        return false
    }
  }

  getProfile(userId: string, spaceId?: string): ProfileRecord | null {
    return this.state.profiles[userId]?.[spaceId || "ME"] || null
  }

  private setProfile({profile}: {profile: ProfileRecord}): void {
    this.setState((prevState) => {
      const userProfiles = {...prevState.profiles}
      if (!userProfiles[profile.userId]) {
        userProfiles[profile.userId] = {}
      }
      userProfiles[profile.userId][profile.spaceId || "ME"] = profile

      setTimeout(() => {
        this.setState((state) => {
          const currentProfiles = {...state.profiles}
          if (currentProfiles[profile.userId]) {
            delete currentProfiles[profile.userId][profile.spaceId || "ME"]
            if (Object.keys(currentProfiles[profile.userId]).length === 0) {
              delete currentProfiles[profile.userId]
            }
          }
          return {profiles: currentProfiles}
        })
      }, 60_000)

      return {profiles: userProfiles}
    })
  }

  private handleConnectionOpen() {
    this.setState(initialState)
  }

  private clearUserProfiles(): void {
    this.setState((prevState) => {
      const profiles = {...prevState.profiles}
      const currentUserId = AuthenticationStore.getId()
      profiles[currentUserId] = {}
      return {profiles}
    })
  }
}

export default new UserProfileStore()
