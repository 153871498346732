import * as ReadStateActionCreators from "~/actions/ReadStateActionCreators"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {Logger} from "~/lib/Logger"
import type {Channel} from "~/records/ChannelRecord"
import {type Message, messageMentionsCurrentUser} from "~/records/MessageRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import ChannelStore from "~/stores/ChannelStore"
import SpaceAvailabilityStore from "~/stores/SpaceAvailabilityStore"
import * as ReadStateUtils from "~/utils/ReadStateUtils"
import * as SnowflakeUtils from "~/utils/SnowflakeUtils"

const logger = new Logger("ReadStateStore")

export type ReadState = {
  channel_id: string
  message_id: string
  mention_count: number
  last_pin_at?: number | null
  manual?: boolean
  optimistic?: boolean
}

type State = {
  readStates: Record<string, ReadState>
}

const initialState: State = {
  readStates: {},
}

const ONE_HOUR_IN_MS = 60 * 60 * 1000

const isReadStateOld = (readState: ReadState) =>
  SnowflakeUtils.extractTimestamp(readState.message_id) < Date.now() - ONE_HOUR_IN_MS

class ReadStateStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "MESSAGE_ACK":
        return this.handleMessageAck(action)
      case "MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      case "CHANNEL_PINS_ACK":
        return this.handleChannelPinsAck(action)
      case "CHANNEL_DELETE":
        return this.handleChannelDelete(action)
      default:
        return false
    }
  }

  getReadState(channelId: string): ReadState | null {
    return this.state.readStates[channelId] || null
  }

  useReadState(channelId: string): ReadState | null {
    const {readStates} = this.useStore()
    return readStates[channelId] || null
  }

  useChannelMentionCount(channelId: string): number {
    const readState = this.useReadState(channelId)
    return readState?.mention_count ?? 0
  }

  useChannelUnreadMessages(channelId: string): boolean {
    const readState = this.useReadState(channelId)
    const channel = ChannelStore.getChannel(channelId)
    if (!channel) return false
    return ReadStateUtils.hasUnreadMessages(channel, readState)
  }

  useSpaceReadStates(spaceId: string): Array<ReadState> {
    const {readStates} = this.useStore()
    const spaceChannels = ChannelStore.getSpaceChannels(spaceId)
    return spaceChannels
      .map((channel) => readStates[channel.id])
      .filter((readState): readState is ReadState => readState !== undefined)
  }

  useSpaceUnreadMessages(spaceId: string): boolean {
    const readStates = this.useSpaceReadStates(spaceId)
    return readStates.some((readState) =>
      ReadStateUtils.hasUnreadMessages(ChannelStore.getChannel(readState.channel_id)!, readState),
    )
  }

  useSpaceMentionCount(spaceId: string): number {
    const readStates = this.useSpaceReadStates(spaceId)
    return readStates.reduce((sum, readState) => sum + (readState.mention_count || 0), 0)
  }

  private handleConnectionOpen({readStates}: {readStates: Array<ReadState>}) {
    const readStatesObj: Record<string, ReadState> = {}
    for (const readState of readStates) {
      readStatesObj[readState.channel_id] = readState
    }
    this.setState({readStates: readStatesObj})
    this.cleanupReadStates()
  }

  private handleMessageAck({
    channelId,
    messageId,
    mentionCount,
    manual,
    optimistic,
  }: {
    channelId: string
    messageId: string
    mentionCount: number
    manual?: boolean
    optimistic?: boolean
  }) {
    this.setState((prevState) => {
      const existingReadState = prevState.readStates[channelId] || {
        channel_id: channelId,
        message_id: "",
        mention_count: 0,
      }
      const updatedReadState: ReadState = {
        ...existingReadState,
        message_id: messageId,
        mention_count: mentionCount,
        manual,
        optimistic,
      }
      return {readStates: {...prevState.readStates, [channelId]: updatedReadState}}
    })
  }

  private handleMessageCreate({message}: {message: Message}) {
    this.setState((prevState) => {
      const readStates = {...prevState.readStates}
      const currentUserId = AuthenticationStore.getId()
      const channelId = message.channel_id

      if (message.nonce != null && message.author.id === currentUserId) {
        readStates[channelId] = {
          channel_id: channelId,
          message_id: message.id,
          mention_count: 0,
        }
      } else if (messageMentionsCurrentUser(message)) {
        const readState = readStates[channelId]
        if (readState) {
          readStates[channelId] = {
            ...readState,
            mention_count: readState.mention_count + 1,
          }
        }
      }
      return {readStates}
    })
  }

  private handleChannelPinsAck({channelId, lastPinAt}: {channelId: string; lastPinAt: number}) {
    this.setState((prevState) => {
      const readState = prevState.readStates[channelId]
      if (readState) {
        prevState.readStates[channelId] = {
          ...readState,
          last_pin_at: lastPinAt,
        }
      }
      return {readStates: {...prevState.readStates, [channelId]: readState}}
    })
  }

  private handleChannelDelete({channel}: {channel: Channel}) {
    this.setState((prevState) => {
      const readStates = {...prevState.readStates}
      delete readStates[channel.id]
      return {readStates}
    })
  }

  private cleanupReadStates() {
    if (SpaceAvailabilityStore.totalUnavailableSpaces > 0) {
      return
    }

    this.setState((prevState) => {
      const readStates = {...prevState.readStates}
      for (const channelId in readStates) {
        const readState = readStates[channelId]
        const channel = ChannelStore.getChannel(channelId)
        if (!channel && isReadStateOld(readState)) {
          logger.info("Deleting old read state", {channelId})
          ReadStateActionCreators.deleteReadState(channelId)
          delete readStates[channelId]
        }
      }
      return {readStates}
    })
  }
}

export default new ReadStateStore()
