import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {SpaceReadyData} from "~/records/SpaceRecord"

type State = {
  unavailableSpaces: Set<string>
}

const initialState: State = {
  unavailableSpaces: new Set(),
}

class SpaceAvailabilityStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
      case "SPACE_UPDATE":
        return this.handleSpace(action.space.id)
      case "SPACE_DELETE":
        return this.handleSpace(action.spaceId, action.unavailable)
      default:
        return false
    }
  }

  get totalUnavailableSpaces() {
    return this.state.unavailableSpaces.size
  }

  isSpaceUnavailable(spaceId: string): boolean {
    return this.state.unavailableSpaces.has(spaceId)
  }

  useUnavailableSpaces(): Set<string> {
    const {unavailableSpaces} = this.useStore()
    return unavailableSpaces
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}) {
    this.setState({unavailableSpaces: new Set(spaces.filter((space) => space.unavailable).map((space) => space.id))})
  }

  private handleSpace(spaceId: string, unavailable?: boolean) {
    this.setState((prevState) => {
      const unavailableSpaces = new Set(prevState.unavailableSpaces)
      if (unavailable) {
        unavailableSpaces.add(spaceId)
      } else {
        unavailableSpaces.delete(spaceId)
      }
      return {unavailableSpaces}
    })
  }
}

export default new SpaceAvailabilityStore()
