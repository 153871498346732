import {useForm} from "react-hook-form"
import * as MfaActionCreators from "~/actions/MfaActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {Form} from "~/components/form/Form"
import {Input} from "~/components/form/Input"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import type {HttpResponse} from "~/lib/HttpClient"
import * as FormUtils from "~/utils/FormUtils"

type FormInputs = {
  code: string
  password: string
}

export const MfaTotpDisableModal = () => {
  const form = useForm<FormInputs>()

  const onSubmit = async (data: FormInputs) => {
    try {
      await MfaActionCreators.disableMfaTotp(data.code.split(" ").join(""), data.password)
      ModalActionCreators.pop()
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "code")
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Modal.Root label={i18n.Messages.REMOVE_AUTHENTICATOR_APP} size="small">
        <Modal.Header title={i18n.Messages.REMOVE_AUTHENTICATOR_APP} />
        <Modal.Content className={styles.content}>
          <Input
            {...form.register("code")}
            autoFocus={true}
            autoComplete="one-time-code"
            error={form.formState.errors.code?.message}
            label={i18n.Messages.TOTP_CODE}
            required={true}
            footer={<p className="text-sm text-text-primary-muted">{i18n.Messages.TOTP_CODE_HELPER_TEXT}</p>}
          />

          <Input
            {...form.register("password")}
            autoComplete="current-password"
            error={form.formState.errors.password?.message}
            label={i18n.Messages.PASSWORD}
            maxLength={128}
            minLength={8}
            placeholder={"•".repeat(32)}
            required={true}
            type="password"
          />
        </Modal.Content>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button onClick={ModalActionCreators.pop} variant="ghost">
              {i18n.Messages.CANCEL}
            </Button>
            <Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
              {i18n.Messages.DONE}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Root>
    </Form>
  )
}
