import * as ModalActionCreators from "~/actions/ModalActionCreators"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"

export const TooManyReactionsModal = () => (
  <Modal.Root label={i18n.Messages.TOO_MANY_REACTIONS_MODAL_TITLE} size="small">
    <Modal.Header title={i18n.Messages.TOO_MANY_REACTIONS_MODAL_TITLE} />
    <Modal.Content className={styles.content}>{i18n.Messages.TOO_MANY_REACTIONS_MODAL_DESCRIPTION}</Modal.Content>
    <Modal.Footer>
      <div className={styles.footer}>
        <Button onClick={ModalActionCreators.pop} variant="brand">
          {i18n.Messages.TOO_MANY_REACTIONS_MODAL_PRIMARY_ACTION}
        </Button>
      </div>
    </Modal.Footer>
  </Modal.Root>
)
