import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"
import {LruCache} from "~/lib/LruCache"
import {type Message, messageMentionsCurrentUser} from "~/records/MessageRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import ChannelStore from "~/stores/ChannelStore"
import UserStore from "~/stores/UserStore"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"
import * as NotificationUtils from "~/utils/NotificationUtils"

type State = {
  notifiedMessageIds: LruCache<string, boolean>
  browserNotificationsEnabled: boolean
}

const initialState: State = {
  notifiedMessageIds: new LruCache(500),
  browserNotificationsEnabled: NotificationUtils.isGranted(),
}

class NotificationStore extends PersistedStore<State> {
  constructor() {
    super(initialState, "NotificationStore", 1, ["browserNotificationsEnabled"])
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      case "NOTIFICATION_PERMISSION_GRANTED":
        return this.handleNotificationPermissionGranted()
      case "NOTIFICATION_PERMISSION_DENIED":
        return this.handleNotificationPermissionDenied()
      default:
        return false
    }
  }

  getBrowserNotificationsEnabled() {
    return this.state.browserNotificationsEnabled
  }

  private handleMessageCreate({message}: {message: Message}) {
    if (message.author.id === AuthenticationStore.getId()) {
      return
    }

    if (!messageMentionsCurrentUser(message)) {
      return
    }

    if (this.state.notifiedMessageIds.has(message.id)) {
      return
    }

    const user = UserStore.getUser(message.author.id)
    const channel = ChannelStore.getChannel(message.channel_id)
    if (!(user && channel)) {
      return
    }

    NotificationUtils.showNotification({
      title: `@${NicknameUtils.getNickname(user, channel.spaceId)}`,
      body: message.content,
      icon: AvatarUtils.getUserAvatarURL(user),
      url: `/channels/${channel.spaceId}/${channel.id}`,
    })

    this.state.notifiedMessageIds.set(message.id, true)
  }

  private handleNotificationPermissionGranted() {
    this.setState((state) => ({...state, browserNotificationsEnabled: true}))
  }

  private handleNotificationPermissionDenied() {
    this.setState((state) => ({...state, browserNotificationsEnabled: false}))
  }
}

export default new NotificationStore()
