import React from "react"
import {useForm} from "react-hook-form"
import * as MfaActionCreators from "~/actions/MfaActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {Form} from "~/components/form/Form"
import {Input} from "~/components/form/Input"
import {BackupCodesModal} from "~/components/modals/BackupCodesModal"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {QRCodeCanvas} from "~/components/uikit/QRCodeCanvas"
import {i18n} from "~/i18n"
import type {HttpResponse} from "~/lib/HttpClient"
import UserStore from "~/stores/UserStore"
import * as FormUtils from "~/utils/FormUtils"
import * as MfaUtils from "~/utils/MfaUtils"

type FormInputs = {
  code: string
}

export const MfaTotpEnableModal = () => {
  const user = UserStore.getCurrentUser()!
  const form = useForm<FormInputs>()
  const [secret] = React.useState(() => MfaUtils.generateTotpSecret())

  const onSubmit = async (data: FormInputs) => {
    try {
      const backupCodes = await MfaActionCreators.enableMfaTotp(
        MfaUtils.encodeTotpSecret(secret),
        data.code.split(" ").join(""),
      )
      ModalActionCreators.pop()
      ModalActionCreators.push((props) => <BackupCodesModal {...props} />, "backup-codes", {backupCodes})
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "code")
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Modal.Root label={i18n.Messages.SETUP_AUTHENTICATOR_APP_MODAL_TITLE} size="small">
        <Modal.Header title={i18n.Messages.SETUP_AUTHENTICATOR_APP_MODAL_TITLE} />
        <Modal.Content className={styles.content}>
          <div className="flex items-center justify-center gap-5">
            <QRCodeCanvas data={MfaUtils.encodeTotpSecretAsURL(user.email!, secret)} />
            <div className="flex flex-col gap-2">
              <div>{i18n.Messages.SETUP_AUTHENTICATOR_APP_MODAL_DESCRIPTION}</div>
              <pre className="select-text text-sm text-text-primary-muted">{secret}</pre>
            </div>
          </div>

          <Input
            {...form.register("code")}
            autoFocus={true}
            autoComplete="one-time-code"
            error={form.formState.errors.code?.message}
            label={i18n.Messages.TOTP_CODE}
            required={true}
            footer={<p className="text-sm text-text-primary-muted">{i18n.Messages.TOTP_CODE_HELPER_TEXT}</p>}
          />
        </Modal.Content>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button onClick={ModalActionCreators.pop} variant="ghost">
              {i18n.Messages.CANCEL}
            </Button>
            <Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
              {i18n.Messages.DONE}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Root>
    </Form>
  )
}
