import type {FC} from "react"
import {MessageAttachmentFlags} from "~/Constants"
import type {MessageAttachment} from "~/records/MessageRecord"
import {createCalculator} from "~/utils/DimensionUtils"
import EmbedAudio from "../media/EmbedAudio"
import {EmbedGif} from "../media/EmbedGifv"
import {EmbedImage} from "../media/EmbedImage"
import EmbedVideo from "../media/EmbedVideo"
import {AttachmentFile} from "./AttachmentFile"

const MEDIA_CONFIG = {
  MAX_WIDTH: 400,
  MAX_HEIGHT: 300,
} as const

const mediaCalculator = createCalculator({
  maxWidth: MEDIA_CONFIG.MAX_WIDTH,
  maxHeight: MEDIA_CONFIG.MAX_HEIGHT,
  responsive: true,
})

type AttachmentProps = {
  attachment: MessageAttachment
  isPreview?: boolean
}

type AttachmentMediaProps = {
  attachment: MessageAttachment
}

const isImageType = (contentType?: string): boolean => contentType?.startsWith("image/") ?? false
const isVideoType = (contentType?: string): boolean => contentType?.startsWith("video/") ?? false
const isAudioType = (contentType?: string): boolean => contentType?.startsWith("audio/") ?? false

const isAnimated = (flags: number): boolean => (flags & MessageAttachmentFlags.ANIMATED) !== 0

const hasValidDimensions = (attachment: MessageAttachment): boolean =>
  typeof attachment.width === "number" && typeof attachment.height === "number"

const AnimatedAttachment: FC<AttachmentMediaProps> = ({attachment}) => (
  <EmbedGif
    embedURL={attachment.url}
    proxyURL={attachment.proxy_url}
    naturalWidth={attachment.width!}
    naturalHeight={attachment.height!}
    placeholder={attachment.placeholder}
  />
)

const VideoAttachment: FC<AttachmentMediaProps> = ({attachment}) => {
  const {dimensions} = mediaCalculator.calculate(
    {
      width: attachment.width!,
      height: attachment.height!,
    },
    {forceScale: true},
  )

  return (
    <div className="mt-1 first:mt-0">
      <EmbedVideo
        src={attachment.proxy_url}
        width={dimensions.width}
        height={dimensions.height}
        placeholder={attachment.placeholder}
      />
    </div>
  )
}

const AudioAttachment: FC<AttachmentMediaProps> = ({attachment}) => (
  <div className="mt-1 first:mt-0">
    <EmbedAudio src={attachment.proxy_url} title={attachment.title || attachment.filename} />
  </div>
)

const AttachmentMedia: FC<AttachmentMediaProps> = ({attachment}) => {
  if (isAnimated(attachment.flags)) {
    return <AnimatedAttachment attachment={attachment} />
  }

  const {dimensions} = mediaCalculator.calculate(
    {
      width: attachment.width!,
      height: attachment.height!,
    },
    {forceScale: true},
  )

  return (
    <div className="mt-1 first:mt-0">
      <EmbedImage
        src={attachment.proxy_url}
        originalSrc={attachment.url}
        naturalWidth={attachment.width!}
        naturalHeight={attachment.height!}
        width={dimensions.width}
        height={dimensions.height}
        placeholder={attachment.placeholder}
        constrain={true}
      />
    </div>
  )
}

export const Attachment: FC<AttachmentProps> = ({attachment, isPreview}) => {
  if (isAudioType(attachment.content_type)) {
    return <AudioAttachment attachment={attachment} />
  }

  if (!hasValidDimensions(attachment)) {
    return <AttachmentFile attachment={attachment} isPreview={isPreview} />
  }

  if (isImageType(attachment.content_type)) {
    return <AttachmentMedia attachment={attachment} />
  }

  if (isVideoType(attachment.content_type)) {
    return <VideoAttachment attachment={attachment} />
  }

  return <AttachmentFile attachment={attachment} isPreview={isPreview} />
}
