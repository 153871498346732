import {noop} from "lodash"
import {ArrowLeftIcon, ArrowRight, AtSign, Copy, Globe, Info} from "lucide-react"
import {AnimatePresence, motion} from "motion/react"
import {useState} from "react"
import {useForm} from "react-hook-form"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as TextCopyActionCreators from "~/actions/TextCopyActionCreators"
import * as UserActionCreators from "~/actions/UserActionCreators"
import {Form} from "~/components/form/Form"
import {Input} from "~/components/form/Input"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import type {HttpResponse} from "~/lib/HttpClient"
import UserStore from "~/stores/UserStore"
import * as FormUtils from "~/utils/FormUtils"
import * as Modal from "./Modal"

type FormInputs = {
  username: string
  password: string
  domain?: string
}

type SetupMode = "initial" | "username" | "domain"
type DomainSetupStep = "input" | "verification"

const slideVariants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 300 : -300,
    opacity: 0,
  }),
}

const DnsInputField = ({label, value, onCopy}: {label: string; value: string; onCopy: (value: string) => void}) => (
  <div className="flex flex-col gap-2">
    <span className="text-sm text-text-primary-muted">{label}</span>
    <div className="flex items-center gap-2">
      <div className="w-0 flex-1 truncate rounded-md border border-background-header-secondary bg-background-tertiary px-3 py-2 font-mono text-text-tertiary">
        {value}
      </div>
      <button
        type="button"
        onClick={() => onCopy(value)}
        className="flex h-10 w-10 items-center justify-center rounded-md border border-background-header-secondary bg-background-primary shadow-sm transition-colors hover:bg-background-modifier-hover focus:outline-none"
        aria-label={`Copy ${label}`}
      >
        <Copy className="h-4 w-4" />
      </button>
    </div>
  </div>
)

const DomainVerificationSection = ({
  domain,
  challenge,
  onCopy,
}: {
  domain: string
  challenge: string
  onCopy: (value: string) => void
}) => {
  const dnsFields = [
    {
      label: "Name",
      value: `_fluxer.${domain}`,
    },
    {
      label: "Type",
      value: "TXT",
    },
    {
      label: "Content",
      value: `dh=${challenge}`,
    },
  ]

  return (
    <div className="rounded-lg border border-background-header-secondary bg-background-tertiary p-6 shadow-sm">
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Info className="h-5 w-5 text-text-primary" />
          <h4 className="font-medium text-text-primary">DNS Verification Required</h4>
        </div>

        <div className="space-y-4 rounded-lg border border-background-header-secondary bg-background-secondary p-4">
          {dnsFields.map((field) => (
            <DnsInputField key={field.label} label={field.label} value={field.value} onCopy={onCopy} />
          ))}
        </div>

        <p className="text-sm text-text-primary-muted">
          DNS changes can take up to 24 hours to propagate, but don't worry—it's usually much faster than that! Once
          you've added the record, just click "Verify" below.
        </p>
      </div>
    </div>
  )
}

export const UsernameChangeModal = () => {
  const user = UserStore.getCurrentUser()!
  const [setupMode, setSetupMode] = useState<SetupMode>("initial")
  const [domainStep, setDomainStep] = useState<DomainSetupStep>("input")
  const [challenge, setChallenge] = useState<string>("")
  const [isVerifying, setIsVerifying] = useState(false)
  const [direction, setDirection] = useState(0)

  const form = useForm<FormInputs>({
    defaultValues: {
      username: user.username,
      password: "",
      domain: "",
    },
  })

  const paginate = (newDirection: number, newMode: SetupMode) => {
    setDirection(newDirection)
    setSetupMode(newMode)
  }

  const handleModeChange = (mode: SetupMode, direction = 1) => {
    paginate(direction, mode)
    setDomainStep("input")
    setChallenge("")
    form.reset(
      mode === "username"
        ? {username: user.username, password: "", domain: ""}
        : {username: "", password: "", domain: ""},
    )
  }

  const validateDomain = (domain: string) => {
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    if (!domainRegex.test(domain)) {
      return "Please enter a valid domain name"
    }
    return true
  }

  const requestChallenge = async () => {
    const domain = form.getValues("domain")
    const validationResult = validateDomain(domain!)
    if (validationResult !== true) {
      form.setError("domain", {message: validationResult as string})
      return
    }

    try {
      setIsVerifying(true)
      const response = await UserActionCreators.requestDomainChallenge(domain!)
      const {challenge} = response
      setChallenge(challenge)
      setDomainStep("verification")
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "domain")
    } finally {
      setIsVerifying(false)
    }
  }

  const verifyDomain = async () => {
    const domain = form.getValues("domain")
    const password = form.getValues("password")

    try {
      setIsVerifying(true)
      await UserActionCreators.verifyDomain(domain!, password)
      ModalActionCreators.pop()
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "password")
    } finally {
      setIsVerifying(false)
    }
  }

  const onSubmit = async (data: FormInputs) => {
    try {
      await UserActionCreators.update({
        username: data.username,
        password: data.password,
      })
      ModalActionCreators.pop()
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "username")
    }
  }

  const renderContent = () => {
    switch (setupMode) {
      case "initial":
        return (
          <motion.div
            key="initial"
            custom={direction}
            variants={slideVariants}
            initial="center"
            animate="center"
            exit="exit"
            transition={{
              x: {type: "spring", stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            className="w-full overflow-auto"
          >
            <div className="space-y-4 p-6">
              <button
                type="button"
                onClick={() => handleModeChange("username")}
                className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
                aria-label="Change Username"
              >
                <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
                  <AtSign className="h-6 w-6 text-text-tertiary" />
                </div>
                <div className="flex-1 text-left">
                  <h3 className="font-medium text-lg text-text-primary">flxr.app</h3>
                  <p className="text-sm text-text-primary-muted">Change your @username.flxr.app</p>
                </div>
                <ArrowRight className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary" />
              </button>

              <button
                type="button"
                onClick={() => handleModeChange("domain")}
                className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
                aria-label="Use Custom Domain"
              >
                <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
                  <Globe className="h-6 w-6 text-text-tertiary" />
                </div>
                <div className="flex-1 text-left">
                  <h3 className="font-medium text-lg text-text-primary">Custom Domain</h3>
                  <p className="text-sm text-text-primary-muted">Use your own domain name</p>
                </div>
                <ArrowRight className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary" />
              </button>
            </div>
          </motion.div>
        )
      case "username":
        return (
          <motion.div
            key="username"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: {type: "spring", stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            className="w-full overflow-auto"
          >
            <div className="space-y-6 p-6">
              <Input
                {...form.register("username")}
                autoComplete="username"
                autoFocus
                error={form.formState.errors.username?.message}
                label={i18n.Messages.USERNAME}
                footer={<p className="mt-1 text-sm text-text-primary-muted">{i18n.Messages.USERNAME_HELPER_TEXT}</p>}
                maxLength={32}
                minLength={2}
                name="username"
                placeholder={i18n.Messages.USERNAME_PLACEHOLDER}
                required
                type="text"
                onChange={(event) => {
                  const value = event.target.value
                  const normalizedValue = value.toLowerCase().replace(/\s/g, "")
                  form.setValue("username", normalizedValue)
                }}
              />

              <Input
                {...form.register("password")}
                autoComplete="current-password"
                error={form.formState.errors.password?.message}
                label={i18n.Messages.PASSWORD}
                maxLength={128}
                minLength={8}
                placeholder="••••••••••••••••••••••••••••••"
                required
                type="password"
              />
            </div>
          </motion.div>
        )
      case "domain":
        return (
          <motion.div
            key="domain"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: {type: "spring", stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            className="w-full overflow-auto"
          >
            <div className="space-y-6 p-6">
              <Input
                {...form.register("domain")}
                label="Domain Name"
                placeholder="yourdomain.com"
                required
                disabled={domainStep === "verification"}
                error={form.formState.errors.domain?.message}
                onChange={(event) => {
                  const value = event.target.value.toLowerCase().trim()
                  form.setValue("domain", value)
                }}
              />

              {domainStep === "verification" && (
                <DomainVerificationSection
                  domain={form.watch("domain")!}
                  challenge={challenge}
                  onCopy={TextCopyActionCreators.copy}
                />
              )}

              {domainStep === "verification" && (
                <Input
                  {...form.register("password")}
                  autoComplete="current-password"
                  error={form.formState.errors.password?.message}
                  label={i18n.Messages.PASSWORD}
                  maxLength={128}
                  minLength={8}
                  placeholder="••••••••••••••••••••••••••••••"
                  required
                  type="password"
                />
              )}
            </div>
          </motion.div>
        )
    }
  }

  return (
    <Modal.Root label={i18n.Messages.CHANGE_USERNAME_MODAL_TITLE} size="small">
      <Form form={form} onSubmit={setupMode === "username" ? onSubmit : noop}>
        <div className="flex h-full w-full flex-col bg-background-secondary">
          {/* Header */}
          <div className="relative flex items-center gap-4 border-background-header-secondary border-b px-4 py-3">
            {setupMode !== "initial" && (
              <Button
                variant="ghost"
                small
                fitContent
                className="absolute flex items-center"
                onClick={() => handleModeChange("initial", -1)}
                aria-label="Go back"
              >
                <ArrowLeftIcon className="h-4 w-4" />
                <span className="ml-1">Back</span>
              </Button>
            )}
            <h2 className="mx-auto font-semibold text-lg text-text-primary">
              {setupMode === "initial" ? "Choose Identity Type" : i18n.Messages.CHANGE_USERNAME_MODAL_TITLE}
            </h2>
          </div>

          {/* Content */}
          <div className="min-h-0 flex-1">
            <div className="relative flex-1 overflow-hidden">
              <AnimatePresence initial={false} custom={direction} mode="wait">
                {renderContent()}
              </AnimatePresence>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-end gap-3 border-background-header-secondary border-t p-4">
            <Button onClick={ModalActionCreators.pop} variant="ghost" aria-label="Cancel">
              {i18n.Messages.CANCEL}
            </Button>
            {setupMode !== "initial" &&
              (setupMode === "username" ? (
                <Button
                  type="submit"
                  submitting={form.formState.isSubmitting}
                  variant="brand"
                  aria-label="Submit Username Change"
                >
                  {i18n.Messages.DONE}
                </Button>
              ) : (
                <Button
                  onClick={domainStep === "input" ? requestChallenge : verifyDomain}
                  submitting={isVerifying}
                  variant="brand"
                  disabled={!form.watch("domain") || (domainStep === "verification" && !form.watch("password"))}
                  aria-label={domainStep === "input" ? "Continue to Verification" : "Verify Domain"}
                >
                  {domainStep === "input" ? "Continue" : "Verify"}
                </Button>
              ))}
          </div>
        </div>
      </Form>
    </Modal.Root>
  )
}
