import {UAParser} from "my-ua-parser"
import type {StatusType} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import Dispatcher from "~/flux/Dispatcher"
import {Store} from "~/flux/Store"
import {GatewayClient} from "~/lib/GatewayClient"
import AuthenticationStore from "~/stores/AuthenticationStore"
import LocalPresenceStore from "~/stores/LocalPresenceStore"

type GatewayConnectionState = {
  gatewayClient: GatewayClient | null
  isConnected: boolean
  sessionId: string | null
}

const initialState: GatewayConnectionState = {
  gatewayClient: null,
  isConnected: false,
  sessionId: null,
}

class GatewayConnectionStore extends Store<GatewayConnectionState> {
  constructor() {
    super(initialState)
    this.initializeGatewayClient()
    this.setupPresenceSync()
  }

  handleAction(action: Action): boolean {
    switch (action.type) {
      case "SESSION_START":
        return this.initiateSession()
      case "LOGOUT":
        return this.performLogout()
      case "CONNECTION_OPEN":
        return this.onConnectionOpened(action)
      case "CONNECTION_CLOSED":
        return this.onConnectionClosed()
      default:
        return false
    }
  }

  useConnectionStatus(): boolean {
    return this.useStore().isConnected
  }

  getSessionId(): string | null {
    return this.state.sessionId
  }

  private initializeGatewayClient(): void {
    const userAgent = new UAParser(navigator.userAgent).getResult()
    const gatewayClient = new GatewayClient(window.ENV.GATEWAY_ENDPOINT, {
      apiVersion: window.ENV.API_VERSION,
      token: AuthenticationStore.getToken(),
      properties: {
        os: userAgent.os.name,
        browser: userAgent.browser.name,
        device: "",
        locale: navigator.language,
        browserVersion: userAgent.browser.version,
        osVersion: userAgent.os.version,
      },
    })

    gatewayClient.on("dispatch", this.handleGatewayDispatch.bind(this))
    gatewayClient.on("disconnect", (event: CloseEvent) => {
      Dispatcher.dispatch({
        type: "CONNECTION_CLOSED",
        code: event.code,
        reason: event.reason,
      })
    })

    this.setState((prevState) => ({...prevState, gatewayClient}))
  }

  private setupPresenceSync(): void {
    let previousStatus: StatusType | null = null

    this.syncWith([LocalPresenceStore], () => {
      const {status} = LocalPresenceStore.getState()
      if (status !== previousStatus) {
        previousStatus = status
        this.state.gatewayClient?.updatePresence(status)
      }
    })
  }

  private initiateSession(): boolean {
    const client = this.state.gatewayClient
    if (client && !client.isConnected()) {
      client.connect()
      return true
    }
    return false
  }

  private performLogout(): boolean {
    const client = this.state.gatewayClient
    client?.disconnect()
    this.setState({
      gatewayClient: client,
      isConnected: false,
      sessionId: null,
    })
    return true
  }

  private onConnectionOpened(action: {type: string; sessionId: string}): boolean {
    this.setState({
      gatewayClient: this.state.gatewayClient,
      isConnected: true,
      sessionId: action.sessionId,
    })
    return true
  }

  private onConnectionClosed(): boolean {
    this.setState({
      gatewayClient: this.state.gatewayClient,
      isConnected: false,
      sessionId: this.state.sessionId,
    })
    return true
  }

  private handleGatewayDispatch(eventType: string, data: any): void {
    switch (eventType) {
      case "READY":
        this.handleReadyEvent(data)
        break
      case "AUTH_SESSION_CHANGE":
        this.handleAuthSessionChange(data)
        break
      case "USER_UPDATE":
        Dispatcher.dispatch({type: eventType, user: data})
        break
      case "USER_SETTINGS_UPDATE":
        Dispatcher.dispatch({type: eventType, userSettings: data})
        break
      case "USER_NOTE_UPDATE":
        Dispatcher.dispatch({type: eventType, userId: data.id, note: data.note})
        break
      case "RECENT_MENTION_DELETE":
        Dispatcher.dispatch({type: eventType, messageId: data.message_id})
        break
      case "SAVED_MESSAGE_CREATE":
        Dispatcher.dispatch({type: eventType, message: data})
        break
      case "SAVED_MESSAGE_DELETE":
        Dispatcher.dispatch({type: eventType, messageId: data.message_id})
        break
      case "PRESENCE_UPDATE":
        Dispatcher.dispatch({type: eventType, presence: data})
        break
      case "SPACE_CREATE":
      case "SPACE_UPDATE":
        Dispatcher.dispatch({type: eventType, space: data})
        break
      case "SPACE_DELETE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.id,
          unavailable: data.unavailable,
        })
        break
      case "SPACE_MEMBER_ADD":
      case "SPACE_MEMBER_UPDATE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          member: data,
        })
        break
      case "SPACE_MEMBER_REMOVE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          userId: data.user.id,
        })
        break
      case "SPACE_ROLE_CREATE":
      case "SPACE_ROLE_UPDATE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          role: data.role,
        })
        break
      case "SPACE_ROLE_DELETE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          roleId: data.role_id,
        })
        break
      case "SPACE_EMOJIS_UPDATE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          emojis: data.emojis,
        })
        break
      case "SPACE_BAN_ADD":
      case "SPACE_BAN_REMOVE":
        Dispatcher.dispatch({
          type: eventType,
          spaceId: data.space_id,
          userId: data.user.id,
        })
        break
      case "CHANNEL_CREATE":
      case "CHANNEL_UPDATE":
      case "CHANNEL_DELETE":
        Dispatcher.dispatch({type: eventType, channel: data})
        break
      case "CHANNEL_PINS_UPDATE":
      case "CHANNEL_PINS_ACK":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          lastPinAt: data.last_pin_at,
        })
        break
      case "MESSAGE_CREATE":
      case "MESSAGE_UPDATE":
        Dispatcher.dispatch({type: eventType, message: data})
        break
      case "MESSAGE_DELETE":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          messageId: data.id,
        })
        break
      case "MESSAGE_DELETE_BULK":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          messageIds: data.ids,
        })
        break
      case "MESSAGE_REACTION_ADD":
      case "MESSAGE_REACTION_REMOVE":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          messageId: data.message_id,
          userId: data.user_id,
          emoji: data.emoji,
        })
        break
      case "MESSAGE_REACTION_REMOVE_ALL":
      case "MESSAGE_REACTION_REMOVE_EMOJI":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          messageId: data.message_id,
          emoji: data.emoji,
        })
        break
      case "MESSAGE_ACK":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          messageId: data.message_id,
          mentionCount: data.mention_count,
          manual: data.manual,
        })
        break
      case "TYPING_START":
        Dispatcher.dispatch({
          type: eventType,
          channelId: data.channel_id,
          userId: data.user_id,
        })
        break
      default:
        break
    }
  }

  private handleReadyEvent(data: any): void {
    Dispatcher.dispatch({
      type: "CONNECTION_OPEN",
      sessionId: data.session_id,
      authSessionIdHash: data.auth_session_id_hash,
      user: data.user,
      userSettings: data.user_settings,
      notes: data.notes,
      readStates: data.read_states,
      spaces: data.spaces,
    })
  }

  private handleAuthSessionChange(data: any): void {
    this.state.gatewayClient?.setToken(data.new_token)
    Dispatcher.dispatch({
      type: "AUTH_SESSION_CHANGE",
      authSessionIdHash: data.new_auth_session_id_hash,
      token: data.new_token,
    })
  }
}

export default new GatewayConnectionStore()
