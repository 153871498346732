import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {SpaceReadyData} from "~/records/SpaceRecord"
import {type User, type UserPrivate, UserRecord} from "~/records/UserRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"

type State = {
  users: Record<string, UserRecord>
}

const initialState: State = {
  users: {},
}

class UserStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "USER_UPDATE":
        return this.handleUserCreate(action.user)
      case "SPACE_MEMBER_ADD":
      case "SPACE_MEMBER_UPDATE":
        return this.handleUserCreate(action.member.user)
      default:
        return false
    }
  }

  getUser(userId: string): UserRecord | undefined {
    return this.state.users[userId]
  }

  getCurrentUser(): UserRecord | undefined {
    return this.state.users[AuthenticationStore.getId()]
  }

  useUser(userId: string): UserRecord | undefined {
    const {users} = this.useStore()
    return users[userId]
  }

  useCurrentUser(): UserRecord | null {
    const {users} = this.useStore()
    return users[AuthenticationStore.getId()] || null
  }

  private handleConnectionOpen({user, spaces}: {user: UserPrivate; spaces: Array<SpaceReadyData>}) {
    this.setState((prevState) => ({
      users: {
        ...prevState.users,
        [user.id]: new UserRecord(user),
      },
    }))

    for (const space of spaces) {
      this.handleSpaceCreate(space)
    }
  }

  private handleSpaceCreate(space: SpaceReadyData) {
    if (space.unavailable) {
      return
    }
    for (const member of space.members) {
      this.handleUserCreate(member.user)
    }
  }

  private handleUserCreate(user: User): UserRecord {
    this.setState((prevState) => ({
      users: {
        ...prevState.users,
        [user.id]: new UserRecord({
          ...prevState.users[user.id]?.toJSON(),
          ...user,
        }),
      },
    }))

    return this.state.users[user.id]!
  }
}

export default new UserStore()
