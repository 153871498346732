import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"
import {clsx} from "clsx"
import React from "react"

export const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({className, children, ...props}, ref) => (
  <ScrollAreaPrimitive.Root className={clsx("relative h-full min-h-0 overflow-hidden", className)} ref={ref} {...props}>
    <ScrollAreaPrimitive.Viewport className="h-full min-h-0 w-full min-w-0 rounded-[inherit]">
      {children}
    </ScrollAreaPrimitive.Viewport>
    <ScrollBar />
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
))

ScrollArea.displayName = "ScrollArea"

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({className, orientation = "vertical", ...props}, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    className={clsx(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" && "h-full min-h-0 w-2.5 min-w-0 border-l border-l-transparent p-px",
      orientation === "horizontal" && "h-2.5 border-t border-t-transparent p-px",
      className,
    )}
    orientation={orientation}
    ref={ref}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-background-modifier-selected" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
))

ScrollBar.displayName = "ScrollBar"
