import {clsx} from "clsx"
import React from "react"
import styles from "~/styles/Scroller.module.css"

export const Scroller = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({children, className, ...props}, ref) => (
    <div className={clsx(styles.scroller, styles.scrollerWithBottomGutter, className)} ref={ref} {...props}>
      {children}
    </div>
  ),
)

Scroller.displayName = "Scroller"
