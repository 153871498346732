import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {Space, SpaceReadyData, SpaceRecord} from "~/records/SpaceRecord"
import SpaceStore from "~/stores/SpaceStore"
import UserSettingsStore from "~/stores/UserSettingsStore"

type State = {
  spaces: Array<SpaceRecord>
}

const initialState: State = {
  spaces: [],
}

class SpaceListStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
      case "SPACE_UPDATE":
        return this.handleSpace(action.space)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "USER_SETTINGS_UPDATE":
        return this.sortSpaces()
      default:
        return false
    }
  }

  getSpaces() {
    return this.state.spaces
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}) {
    this.setState(initialState)
    for (const space of spaces) {
      this.handleSpace(space)
    }
  }

  private handleSpace(space: Space | SpaceReadyData) {
    if (space.unavailable) {
      return
    }
    const spaceRecord = SpaceStore.getSpace(space.id)
    if (!spaceRecord) {
      return
    }
    const index = this.state.spaces.findIndex((c) => c.id === space.id)
    if (index === -1) {
      spaceRecord.unavailable = false
      this.setState((prevState) => ({spaces: [...prevState.spaces, spaceRecord]}))
      this.sortSpaces()
      return
    }
    this.setState((prevState) => {
      const spaces = [...prevState.spaces]
      spaces[index] = spaceRecord
      return {spaces}
    })
    this.sortSpaces()
  }

  private handleSpaceDelete({spaceId, unavailable}: {spaceId: string; unavailable?: boolean}) {
    const index = this.state.spaces.findIndex((c) => c.id === spaceId)
    if (index === -1) {
      return
    }
    if (unavailable) {
      this.setState((prevState) => {
        const spaces = [...prevState.spaces]
        spaces[index].unavailable = true
        return {spaces}
      })
      return
    }
    this.setState((prevState) => {
      const spaces = [...prevState.spaces]
      spaces.splice(index, 1)
      return {spaces}
    })
  }

  private sortSpaces() {
    const settings = UserSettingsStore.getState()
    this.setState((prevState) => {
      const spaces = [...prevState.spaces]
      spaces.sort((a, b) => {
        const aIndex = settings.space_positions.indexOf(a.id)
        const bIndex = settings.space_positions.indexOf(b.id)
        if (aIndex === -1 && bIndex === -1) {
          return a.name.localeCompare(b.name)
        }
        if (aIndex === -1) {
          return -1
        }
        if (bIndex === -1) {
          return 1
        }
        return aIndex - bIndex
      })
      return {spaces}
    })
  }
}

export default new SpaceListStore()
