import {Permissions} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"
import * as UnicodeEmojis from "~/lib/UnicodeEmojis"
import type {UnicodeEmoji} from "~/lib/UnicodeEmojis"
import type {ChannelRecord} from "~/records/ChannelRecord"
import {type SpaceEmoji, SpaceEmojiRecord} from "~/records/SpaceEmojiRecord"
import type {SpaceMember} from "~/records/SpaceMemberRecord"
import type {Space, SpaceReadyData} from "~/records/SpaceRecord"
import SpaceListStore from "~/stores/SpaceListStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import UserStore from "~/stores/UserStore"
import * as PermissionUtils from "~/utils/PermissionUtils"
import * as RegexUtils from "~/utils/RegexUtils"

type State = {
  skinTone: string
}

const initialState: State = {
  skinTone: "",
}

export type Emoji = Partial<SpaceEmojiRecord> &
  Partial<UnicodeEmoji> & {
    name: string
    allNamesString: string
    uniqueName: string
  }

class EmojiDisambiguations {
  private static _lastInstance: EmojiDisambiguations | null = null
  private spaceId: string | null
  private disambiguatedEmoji: Array<Emoji> | null
  private customEmojis: Map<string, Emoji> | null
  private emojisByName: Map<string, Emoji> | null
  private emojisById: Map<string, Emoji> | null

  private constructor(spaceId?: string | null) {
    this.spaceId = spaceId ?? null
    this.disambiguatedEmoji = null
    this.customEmojis = null
    this.emojisByName = null
    this.emojisById = null
  }

  static getInstance(spaceId?: string | null): EmojiDisambiguations {
    if (!EmojiDisambiguations._lastInstance || EmojiDisambiguations._lastInstance.spaceId !== spaceId) {
      EmojiDisambiguations._lastInstance = new EmojiDisambiguations(spaceId)
    }
    return EmojiDisambiguations._lastInstance
  }

  static reset(): void {
    EmojiDisambiguations._lastInstance = null
  }

  static clear(spaceId?: string | null): void {
    if (EmojiDisambiguations._lastInstance?.spaceId === spaceId) {
      EmojiDisambiguations._lastInstance = null
    }
  }

  private ensureDisambiguated(): void {
    if (!this.disambiguatedEmoji) {
      this.buildDisambiguatedCustomEmoji()
    }
  }

  getDisambiguatedEmoji(): Array<Emoji> {
    this.ensureDisambiguated()
    return this.disambiguatedEmoji!
  }

  getCustomEmoji(): Map<string, Emoji> {
    this.ensureDisambiguated()
    return this.customEmojis!
  }

  getByName(disambiguatedEmojiName: string): Emoji | undefined {
    this.ensureDisambiguated()
    return this.emojisByName?.get(disambiguatedEmojiName)
  }

  getById(emojiId: string): Emoji | undefined {
    this.ensureDisambiguated()
    return this.emojisById?.get(emojiId)
  }

  nameMatchesChain(testName: (name: string) => boolean): Array<Emoji> {
    return this.getDisambiguatedEmoji().filter(({names, name}) => (names ? names.some(testName) : testName(name)))
  }

  private buildDisambiguatedCustomEmoji(): void {
    const emojiCountByName: Map<string, number> = new Map()

    this.disambiguatedEmoji = []
    this.customEmojis = new Map()
    this.emojisByName = new Map()
    this.emojisById = new Map()

    const disambiguateEmoji = (emoji: Emoji) => {
      const uniqueName = emoji.name
      const existingCount = emojiCountByName.get(uniqueName) || 0
      emojiCountByName.set(uniqueName, existingCount + 1)
      if (existingCount > 0) {
        const name = `${uniqueName}~${existingCount}`
        // biome-ignore lint/style/noParameterAssign: <explanation>
        emoji = {
          ...emoji,
          name,
          uniqueName,
          allNamesString: `:${name}:`,
        }
      }

      this.emojisByName?.set(emoji.name, emoji)
      if (emoji.id) {
        this.emojisById?.set(emoji.id, emoji)
        this.customEmojis?.set(emoji.name, emoji)
      }
      this.disambiguatedEmoji?.push(emoji)
    }

    UnicodeEmojis.forEachEmoji(disambiguateEmoji)

    const addSpaceEmoji = (spaceId: string) => {
      const spaceEmoji = spaces.get(spaceId)
      if (!spaceEmoji) {
        return
      }

      spaceEmoji.usableEmojis.forEach(disambiguateEmoji)
    }

    if (this.spaceId) {
      addSpaceEmoji(this.spaceId)
    }

    for (const space of SpaceListStore.getSpaces()) {
      if (space.id !== this.spaceId) {
        addSpaceEmoji(space.id)
      }
    }
  }
}

let customEmojisById: Map<string, SpaceEmojiRecord> = new Map()
let spaces: Map<string, {emojis: Array<SpaceEmojiRecord>; usableEmojis: Array<SpaceEmojiRecord>}> = new Map()

const deleteEverything = () => {
  spaces = new Map()
  customEmojisById = new Map()
  EmojiDisambiguations.reset()
}

const deleteSpaceEmoji = (spaceId: string) => {
  spaces.delete(spaceId)
}

const rebuildUsableEmojis = () => {
  customEmojisById = new Map()

  for (const space of spaces.values()) {
    for (const emoji of space.usableEmojis) {
      customEmojisById.set(emoji.id, emoji)
    }
  }

  EmojiDisambiguations.reset()
}

const updateSpaceEmoji = (spaceId: string, spaceEmojis?: Array<SpaceEmoji>) => {
  deleteSpaceEmoji(spaceId)
  EmojiDisambiguations.clear(spaceId)

  if (!spaceEmojis) {
    return
  }

  const currentUser = UserStore.getCurrentUser()
  if (!currentUser) {
    return
  }

  const localUser = SpaceMemberStore.getMember(spaceId, currentUser.id)
  if (!localUser) {
    return
  }

  const space = {
    emojis: spaceEmojis.map((emoji) => new SpaceEmojiRecord(spaceId, emoji)),
    usableEmojis: [] as Array<SpaceEmojiRecord>,
  }

  for (const emoji of space.emojis.sort((a, b) => b.name.localeCompare(a.name))) {
    space.usableEmojis.push(emoji)
  }

  spaces.set(spaceId, space)
}

class EmojiStore extends PersistedStore<State> {
  constructor() {
    super(initialState, "EmojiStore", 1, ["skinTone"])
  }

  handleAction(action: Action): boolean {
    switch (action.type) {
      case "EMOJI_SKIN_TONE":
        return this.handleSkinTone(action)
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
      case "SPACE_UPDATE":
        return this.handleSpaceUpdate(action)
      case "SPACE_EMOJIS_UPDATE":
        return this.handleSpaceEmojiUpdated(action)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "SPACE_MEMBER_UPDATE":
        return this.handleSpaceMemberUpdate(action)
      default:
        return false
    }
  }

  private handleSkinTone({skinTone}: {skinTone: string}): boolean {
    this.setState({skinTone})
    return true
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}): boolean {
    deleteEverything()
    for (const space of spaces) {
      updateSpaceEmoji(space.id, space.emojis)
    }
    rebuildUsableEmojis()
    return true
  }

  private handleSpaceUpdate({space}: {space: Space | SpaceReadyData}): boolean {
    if ("emojis" in space) {
      updateSpaceEmoji(space.id, space.emojis)
    } else {
      updateSpaceEmoji(space.id)
    }
    rebuildUsableEmojis()
    return true
  }

  private handleSpaceEmojiUpdated({spaceId, emojis}: {spaceId: string; emojis: Array<SpaceEmoji>}): boolean {
    updateSpaceEmoji(spaceId, emojis)
    rebuildUsableEmojis()
    return true
  }

  private handleSpaceDelete({spaceId}: {spaceId: string}): boolean {
    deleteSpaceEmoji(spaceId)
    rebuildUsableEmojis()
    return true
  }

  private handleSpaceMemberUpdate({spaceId, member}: {spaceId: string; member: SpaceMember}): boolean {
    if (member.user.id !== UserStore.getCurrentUser()?.id) {
      return false
    }
    updateSpaceEmoji(spaceId, spaces.get(spaceId)?.usableEmojis)
    rebuildUsableEmojis()
    return true
  }

  getSkinTone(): string {
    return this.state.skinTone
  }

  get categories(): Array<string> {
    return ["custom", ...UnicodeEmojis.getCategories()]
  }

  getSpaceEmoji(spaceId: string): Array<SpaceEmojiRecord> {
    return spaces.get(spaceId)?.usableEmojis || []
  }

  filterExternal(channel: ChannelRecord, nameTest: (name: string) => boolean, count: number): Array<Emoji> {
    let chain = EmojiDisambiguations.getInstance(channel.spaceId).nameMatchesChain(nameTest)

    const currentUser = UserStore.getCurrentUser()
    if (
      !PermissionUtils.can(Permissions.USE_EXTERNAL_EMOJIS, {
        userId: currentUser?.id,
        spaceId: channel.spaceId,
        channelId: channel.id,
      })
    ) {
      chain = chain.filter((emoji) => !emoji.spaceId || emoji.spaceId === channel.spaceId)
    }

    if (count > 0) {
      chain = chain.slice(0, count)
    }

    return chain
  }

  getDisambiguatedEmojiContext(spaceId?: string | null): EmojiDisambiguations {
    return EmojiDisambiguations.getInstance(spaceId)
  }

  getEmojiMarkdown(emoji: Emoji): string {
    if (emoji.id) {
      return `<${emoji.animated ? "a" : ""}:${emoji.uniqueName}:${emoji.id}>`
    }
    return `:${emoji.uniqueName}:`
  }

  search(channel: ChannelRecord, query: string, count = 0): Array<Emoji> {
    const lowerCasedQuery = query.toLowerCase()
    const escapedSearchQuery = RegexUtils.escapeRegex(lowerCasedQuery)
    const containsRegex = new RegExp(escapedSearchQuery.toString(), "i")
    const contains = containsRegex.test.bind(containsRegex)

    let searchResults = this.filterExternal(channel, contains, 0)
    if (searchResults.length > 0) {
      const startsWithRegex = new RegExp(`^${escapedSearchQuery}`, "i")
      const isAtBoundaryRegex = new RegExp(`(^|_|[A-Z])${escapedSearchQuery}s?([A-Z]|_|$)`)
      const isAtBoundary = isAtBoundaryRegex.test.bind(isAtBoundaryRegex)
      const startsWith = startsWithRegex.test.bind(startsWithRegex)

      const score = (name: string): number => {
        const nameLowerCase = name.toLowerCase()
        return (
          1 +
          (nameLowerCase === lowerCasedQuery ? 4 : 0) +
          (isAtBoundary(nameLowerCase) || isAtBoundary(name) ? 2 : 0) +
          (startsWith(name) ? 1 : 0)
        )
      }

      searchResults = searchResults.sort((a, b) => {
        const scoreA = score(a.names ? a.names[0] : a.name)
        const scoreB = score(b.names ? b.names[0] : b.name)
        return scoreB - scoreA || (a.names ? a.names[0] : a.name).localeCompare(b.names ? b.names[0] : b.name)
      })
    }

    if (count) {
      searchResults = searchResults.slice(0, count)
    }

    return searchResults
  }
}

export default new EmojiStore()
