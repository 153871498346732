import type {SpaceMember, SpaceMemberRecord} from "~/records/SpaceMemberRecord"
import type {SpaceRecord} from "~/records/SpaceRecord"
import type {UserPartial, UserProfile} from "~/records/UserRecord"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import SpaceStore from "~/stores/SpaceStore"

export type UserBadge = {
  id: string
  name: string
}

export type Profile = {
  user: UserPartial
  user_profile: UserProfile
  timezone_offset: number | null
  badges: Array<UserBadge>
  space_member?: SpaceMember
  domain: string
}

export class ProfileRecord {
  userId: string
  spaceId: string | null
  userProfile: UserProfile
  badges: Array<UserBadge>
  timezoneOffset: number | null
  domain: string

  constructor(profile: Profile, spaceId?: string) {
    this.userId = profile.user.id
    this.spaceId = spaceId ?? null
    this.userProfile = profile.user_profile
    this.badges = profile.badges
    this.timezoneOffset = profile.timezone_offset
    this.domain = profile.domain
  }

  get space(): SpaceRecord | null {
    return this.spaceId ? SpaceStore.getSpace(this.spaceId)! : null
  }

  get spaceMember(): SpaceMemberRecord | null {
    return this.spaceId ? SpaceMemberStore.getMember(this.spaceId, this.userId) : null
  }
}
