import {DateTime} from "luxon"

declare global {
  interface Window {
    __TZ_OVERRIDE__: string
  }
}

const getTimeZone = () => {
  return typeof window.__TZ_OVERRIDE__ !== "undefined" ? window.__TZ_OVERRIDE__ : DateTime.local().zoneName
}

export const getRelativeDateString = (timestamp: number) => {
  const dt = DateTime.fromMillis(timestamp, {zone: getTimeZone()})
  const now = DateTime.now().setZone(getTimeZone())
  if (dt.hasSame(now, "day")) {
    return `Today at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`
  }
  if (dt.hasSame(now.minus({days: 1}), "day")) {
    return `Yesterday at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`
  }
  return dt.toLocaleString({
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
}

export const getFormattedDateTime = (timestamp: number) =>
  DateTime.fromMillis(timestamp, {zone: getTimeZone()}).toLocaleString(DateTime.DATETIME_SHORT)

export const getFormattedShortDate = (timestamp: number) =>
  DateTime.fromMillis(timestamp, {zone: getTimeZone()}).toLocaleString(DateTime.DATE_MED)

export const getFormattedDateWithFullMonth = (timestamp: number) =>
  DateTime.fromMillis(timestamp, {zone: getTimeZone()}).toLocaleString({
    month: "long",
    day: "numeric",
    year: "numeric",
  })

export const getFormattedTime = (timestamp: number) =>
  DateTime.fromMillis(timestamp, {zone: getTimeZone()}).toLocaleString(DateTime.TIME_SIMPLE)

export const getFormattedDateTimeWithSeconds = (timestamp: number) => {
  const dt = DateTime.fromMillis(timestamp, {zone: getTimeZone()})
  return `${dt.toLocaleString({weekday: "long", month: "long", day: "numeric", year: "numeric"})} ${dt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
}

export const getShortRelativeDateString = (timestamp: number) =>
  DateTime.fromMillis(timestamp, {zone: getTimeZone()}).toRelative({locale: "en", style: "short"})
