import clsx from "clsx"
import type React from "react"
import type {
  EmbedAuthor as EmbedAuthorData,
  EmbedField as EmbedFieldType,
  EmbedFooter as EmbedFooterData,
} from "~/records/MessageRecord"
import markupStyles from "~/styles/Markup.module.css"
import * as DateUtils from "~/utils/DateUtils"
import * as MarkupUtils from "~/utils/MarkupUtils"
import styles from "./EmbedFields.module.css"

type LinkComponentProps = {
  url: string
  children: React.ReactNode
  className?: string
}

const LinkComponent = ({url, children, className}: LinkComponentProps) => (
  <a className={clsx(styles.embedLink, className)} href={url} rel="noopener noreferrer" target="_blank">
    {children}
  </a>
)

type EmbedProviderProps = {
  provider?: EmbedAuthorData
}

export const EmbedProvider = ({provider}: EmbedProviderProps) => {
  if (!provider) return null

  return (
    <div className={clsx(styles.embedProvider, styles.embedMargin)}>
      {provider.url ? <LinkComponent url={provider.url}>{provider.name}</LinkComponent> : <span>{provider.name}</span>}
    </div>
  )
}

type EmbedAuthorProps = {
  author?: EmbedAuthorData
}

export const EmbedAuthor = ({author}: EmbedAuthorProps) => {
  if (!author) return null

  return (
    <div className={clsx(styles.embedAuthor, styles.embedMargin)}>
      {author.proxy_icon_url && (
        <img alt="" className={styles.embedAuthorIcon} height={24} src={author.proxy_icon_url} width={24} />
      )}
      {author.url ? (
        <LinkComponent className={clsx(styles.embedAuthorName, styles.embedAuthorNameLink)} url={author.url}>
          {author.name}
        </LinkComponent>
      ) : (
        <span className={styles.embedAuthorName}>{author.name}</span>
      )}
    </div>
  )
}

type EmbedTitleProps = {
  title?: string
  url?: string
}

export const EmbedTitle = ({title, url}: EmbedTitleProps) => {
  if (!title) return null

  return (
    <div className={clsx(styles.embedTitle, styles.embedMargin)}>
      {url ? (
        <LinkComponent url={url}>{MarkupUtils.parseEmbedTitle(title)}</LinkComponent>
      ) : (
        <span>{MarkupUtils.parseEmbedTitle(title)}</span>
      )}
    </div>
  )
}

type EmbedDescriptionProps = {
  messageId?: string
  channelId?: string
  description?: string
}

export const EmbedDescription = ({messageId, channelId, description}: EmbedDescriptionProps) => {
  if (!description) return null

  return (
    <div className={clsx(markupStyles.markup, markupStyles.message, styles.embedDescription, styles.embedMargin)}>
      {MarkupUtils.parse(description, undefined, {messageId, channelId})}
    </div>
  )
}

type EmbedFieldsProps = {
  fields: Array<EmbedFieldType>
}

const groupFields = (fields: Array<EmbedFieldType>): Array<Array<EmbedFieldType>> => {
  const groupedFields: Array<Array<EmbedFieldType>> = []
  let currentGroup: Array<EmbedFieldType> = []

  for (const field of fields) {
    if (field.inline) {
      currentGroup.push(field)
    } else {
      if (currentGroup.length > 0) {
        groupedFields.push(currentGroup)
        currentGroup = []
      }
      groupedFields.push([field])
    }
  }

  if (currentGroup.length > 0) {
    groupedFields.push(currentGroup)
  }

  return groupedFields
}

export const EmbedFields = ({fields}: EmbedFieldsProps) => {
  if (!fields?.length) {
    return null
  }

  const groupedFields = groupFields(fields)

  return (
    <div className={styles.embedFields}>
      {groupedFields.map((group, groupIndex) => {
        const groupLength = group.length
        return group.map(({name, value}, index) => {
          const gridColumnStart = index * (12 / groupLength) + 1
          const gridColumnEnd = gridColumnStart + 12 / groupLength
          return (
            <div
              className={styles.embedField}
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={`${groupIndex}-${index}`}
              style={{gridColumn: `${gridColumnStart} / ${gridColumnEnd}`}}
            >
              <div className={styles.embedFieldName}>{name}</div>
              <div className={clsx(markupStyles.markup, styles.embedFieldValue)}>{MarkupUtils.parse(value)}</div>
            </div>
          )
        })
      })}
    </div>
  )
}

type EmbedFooterProps = {
  timestamp?: number
  footer?: EmbedFooterData
}

export const EmbedFooter = ({timestamp, footer}: EmbedFooterProps) => {
  const formattedTimestamp = timestamp ? DateUtils.getRelativeDateString(timestamp) : undefined
  if (!(footer || formattedTimestamp)) return null

  return (
    <div className={clsx(styles.embedFooter, styles.embedMargin, footer?.proxy_icon_url && styles.hasThumbnail)}>
      {footer?.proxy_icon_url && (
        <img alt="" className={styles.embedFooterIcon} height={20} src={footer.proxy_icon_url} width={20} />
      )}
      <div className={styles.embedFooterText}>
        {footer?.text}
        {formattedTimestamp && (
          <>
            <div aria-hidden={true} className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat align-middle" />
            <span>{formattedTimestamp}</span>
          </>
        )}
      </div>
    </div>
  )
}
