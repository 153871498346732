import {useForm} from "react-hook-form"
import * as MfaActionCreators from "~/actions/MfaActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {Form} from "~/components/form/Form"
import {Input} from "~/components/form/Input"
import {BackupCodesModal} from "~/components/modals/BackupCodesModal"
import styles from "~/components/modals/ConfirmModal.module.css"
import * as Modal from "~/components/modals/Modal"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import type {HttpResponse} from "~/lib/HttpClient"
import * as FormUtils from "~/utils/FormUtils"

type FormInputs = {
  password: string
}

export const BackupCodesViewModal = () => {
  const form = useForm<FormInputs>()

  const onSubmit = async (data: FormInputs) => {
    try {
      const backupCodes = await MfaActionCreators.getBackupCodes(data.password)
      ModalActionCreators.pop()
      ModalActionCreators.push((props) => <BackupCodesModal {...props} />, "backup-codes", {backupCodes})
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "password")
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Modal.Root label={i18n.Messages.VIEW_BACKUP_CDOES} size="small">
        <Modal.Header title={i18n.Messages.VIEW_BACKUP_CDOES} />
        <Modal.Content className={styles.content}>
          <Input
            {...form.register("password")}
            autoFocus={true}
            autoComplete="current-password"
            error={form.formState.errors.password?.message}
            label={i18n.Messages.PASSWORD}
            maxLength={128}
            minLength={8}
            placeholder={"•".repeat(32)}
            required={true}
            type="password"
          />
        </Modal.Content>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button onClick={ModalActionCreators.pop} variant="ghost">
              {i18n.Messages.CANCEL}
            </Button>
            <Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
              {i18n.Messages.DONE}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Root>
    </Form>
  )
}
