import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import AuthenticationStore from "~/stores/AuthenticationStore"
import MessageStore from "~/stores/MessageStore"

type MessageReplyState = {
  messageId: string
  mentioning: boolean
}

type State = {
  replyingMessageIds: Record<string, MessageReplyState>
  highlightMessageId: string | null
}

const initialState: State = {
  replyingMessageIds: {},
  highlightMessageId: null,
}

class MessageReplyStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "MESSAGE_REPLY_START":
        return this.handleMessageReplyStart(action)
      case "MESSAGE_REPLY_MENTIONING":
        return this.handleMessageReplyMentioning(action)
      case "MESSAGE_REPLY_STOP":
        return this.handleMessageReplyStop(action)
      case "MESSAGE_HIGHLIGHT":
        return this.handleMessageHighlight(action)
      case "MESSAGE_HIGHLIGHT_CLEAR":
        return this.handleMessageHighlightClear()
      default:
        return false
    }
  }

  useIsReplying(channelId: string, messageId: string): boolean {
    const {replyingMessageIds} = this.useStore()
    const replyingMessage = replyingMessageIds[channelId]
    return replyingMessage ? replyingMessage.messageId === messageId : false
  }

  useReplyingMessage(channelId: string): MessageReplyState | null {
    const {replyingMessageIds} = this.useStore()
    return replyingMessageIds[channelId] || null
  }

  useIsHighlight(messageId: string): boolean {
    const {highlightMessageId} = this.useStore()
    return highlightMessageId === messageId
  }

  private handleMessageReplyStart({
    channelId,
    messageId,
    mentioning,
  }: {
    channelId: string
    messageId: string
    mentioning: boolean
  }) {
    const cachedMessage = MessageStore.getMessage(channelId, messageId)
    if (!cachedMessage) {
      return
    }
    if (cachedMessage.author.id === AuthenticationStore.getId() || cachedMessage.webhookId) {
      mentioning = false
    }
    this.setState((prevState) => {
      const replyingMessageIds = {...prevState.replyingMessageIds}
      replyingMessageIds[channelId] = {messageId, mentioning}
      return {
        ...prevState,
        replyingMessageIds,
      }
    })
  }

  private handleMessageReplyMentioning({
    channelId,
    mentioning,
  }: {
    channelId: string
    mentioning: boolean
  }) {
    this.setState((prevState) => {
      const replyingMessageIds = {...prevState.replyingMessageIds}
      const currentReply = replyingMessageIds[channelId]
      if (currentReply) {
        replyingMessageIds[channelId] = {...currentReply, mentioning}
      }
      return {
        ...prevState,
        replyingMessageIds,
      }
    })
  }

  private handleMessageReplyStop({channelId}: {channelId: string}) {
    this.setState((prevState) => {
      const replyingMessageIds = {...prevState.replyingMessageIds}
      delete replyingMessageIds[channelId]
      return {
        ...prevState,
        replyingMessageIds,
      }
    })
  }

  private handleMessageHighlight({messageId}: {messageId: string}) {
    this.setState((prevState) => ({
      ...prevState,
      highlightMessageId: messageId,
    }))
  }

  private handleMessageHighlightClear() {
    this.setState((prevState) => ({
      ...prevState,
      highlightMessageId: null,
    }))
  }
}

export default new MessageReplyStore()
