import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import AuthenticationStore from "~/stores/AuthenticationStore"
import InviteStore, {type Invite} from "~/stores/InviteStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import * as RouterUtils from "~/utils/RouterUtils"

const invitePromises = new Map()

export const fetch = async (code: string): Promise<Invite> => {
  if (invitePromises.has(code)) {
    return invitePromises.get(code)
  }

  const invite = InviteStore.getInvite(code)
  if (invite) {
    return Promise.resolve(invite)
  }

  const invitePromise = (async () => {
    const {body} = await HttpClient.get<Invite>({url: Endpoints.INVITE(code)})
    Dispatcher.dispatch({type: "INVITE_CREATE", invite: body})
    return body
  })()

  invitePromises.set(code, invitePromise)

  invitePromise.finally(() => {
    invitePromises.delete(code)
  })

  return invitePromise
}

export const accept = async (code: string): Promise<Invite> => {
  const {body} = await HttpClient.post<Invite>({url: Endpoints.INVITE(code)})
  Dispatcher.dispatch({type: "INVITE_CREATE", invite: body})
  return body
}

export const acceptAndTransitionToChannel = async (code: string) => {
  const invite = await fetch(code)
  if (SpaceMemberStore.getMember(invite.space.id, AuthenticationStore.getId()) != null) {
    RouterUtils.transitionTo(`/channels/${invite.space.id}/${invite.channel.id}`)
    return
  }
  await accept(code)
  RouterUtils.transitionTo(`/channels/${invite.space.id}/${invite.channel.id}`)
}

export const create = async (channelId: string): Promise<Invite> => {
  const {body} = await HttpClient.post<Invite>({url: Endpoints.CHANNEL_INVITES(channelId)})
  Dispatcher.dispatch({type: "INVITE_CREATE", invite: body})
  return body
}
