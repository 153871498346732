import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type Message, MessageRecord} from "~/records/MessageRecord"

type CachedMessageState =
  | {
      status: "loaded"
      message: MessageRecord
    }
  | {
      status: "deleted" | "not_loaded"
    }

type State = {
  cachedMessages: Map<string, MessageRecord>
  cachedMessageIds: Set<string>
}

const initialState: State = {
  cachedMessages: new Map(),
  cachedMessageIds: new Set(),
}

class ReferencedMessageStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen()
      case "MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      case "MESSAGE_DELETE":
        return this.handleMessageDelete(action)
      case "MESSAGE_DELETE_BULK":
        return this.handleMessageDeleteBulk(action)
      case "REPLY_DELETE_MESSAGE_IDS":
        return this.handleReplyDeleteMessageIds(action)
      case "MESSAGES_FETCH_SUCCESS":
      case "CHANNEL_PINS_FETCH_SUCCESS":
      case "SAVED_MESSAGES_FETCH_SUCCESS":
      case "RECENT_MENTIONS_FETCH_SUCCESS":
        return this.handleMessagesFetchSuccess(action)
      default:
        return false
    }
  }

  useCachedMessage(messageId: string): CachedMessageState {
    const {cachedMessages, cachedMessageIds} = this.useStore()
    const cachedMessage = cachedMessages.get(messageId)
    if (cachedMessage == null) {
      if (cachedMessageIds.has(messageId)) {
        return {status: "deleted"}
      }
      return {status: "not_loaded"}
    }
    return {status: "loaded", message: cachedMessage}
  }

  private handleConnectionOpen() {
    this.setState(initialState)
  }

  private handleMessageCreate({message}: {message: Message}) {
    if (message.referenced_message === undefined) {
      return
    }
    if (message.message_reference == null) {
      console.warn("Message reference is null")
      return
    }
    const messageReferenceId = message.message_reference.message_id
    if (message.referenced_message === null) {
      this.setState((prevState) => {
        if (!prevState.cachedMessageIds.has(messageReferenceId)) {
          const newCachedMessageIds = new Set(prevState.cachedMessageIds)
          newCachedMessageIds.add(messageReferenceId)
          return {
            ...prevState,
            cachedMessageIds: newCachedMessageIds,
          }
        }
        return prevState
      })
      return
    }
    const referencedMessage = new MessageRecord(message.referenced_message)
    this.setState((prevState) => {
      const newCachedMessages = new Map(prevState.cachedMessages)
      newCachedMessages.set(messageReferenceId, referencedMessage)
      const newCachedMessageIds = new Set(prevState.cachedMessageIds)
      newCachedMessageIds.add(messageReferenceId)
      return {
        cachedMessages: newCachedMessages,
        cachedMessageIds: newCachedMessageIds,
      }
    })
  }

  private handleMessageDelete({messageId}: {messageId: string}) {
    this.setState((prevState) => {
      const newCachedMessages = new Map(prevState.cachedMessages)
      newCachedMessages.delete(messageId)
      const newCachedMessageIds = new Set(prevState.cachedMessageIds)
      newCachedMessageIds.add(messageId)
      return {
        cachedMessages: newCachedMessages,
        cachedMessageIds: newCachedMessageIds,
      }
    })
  }

  private handleMessageDeleteBulk({messageIds}: {messageIds: Array<string>}) {
    this.setState((prevState) => {
      const newCachedMessages = new Map(prevState.cachedMessages)
      const newCachedMessageIds = new Set(prevState.cachedMessageIds)

      for (const messageId of messageIds) {
        newCachedMessages.delete(messageId)
        newCachedMessageIds.delete(messageId)
      }

      return {
        cachedMessages: newCachedMessages,
        cachedMessageIds: newCachedMessageIds,
      }
    })
  }

  private handleMessagesFetchSuccess({messages}: {messages: Array<Message>}) {
    for (const message of messages) {
      this.handleMessageCreate({message})
    }
  }

  private handleReplyDeleteMessageIds({messageIds}: {messageIds: Array<string>}) {
    this.handleMessageDeleteBulk({messageIds})
  }
}

export default new ReferencedMessageStore()
