import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import {type Profile, ProfileRecord} from "~/records/ProfileRecord"
import UserProfileStore from "~/stores/UserProfileStore"

export const fetch = async (userId: string, spaceId?: string) => {
  const existingProfile = UserProfileStore.getProfile(userId, spaceId)
  if (existingProfile) {
    return existingProfile
  }
  const {body: profile} = await HttpClient.get<Profile>({
    url: Endpoints.USER_PROFILE(userId),
    query: spaceId ? {space_id: spaceId} : undefined,
  })
  const profileRecord = new ProfileRecord(profile, spaceId)
  Dispatcher.dispatch({
    type: "USER_PROFILE_CREATE",
    profile: profileRecord,
  })
  return profileRecord
}
