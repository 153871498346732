import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"

export type AccessibilitySettings = {
  saturationFactor: number
  alwaysUnderlineLinks: boolean
}

const initialState: AccessibilitySettings = {
  saturationFactor: 1,
  alwaysUnderlineLinks: false,
}

class AccessibilityStore extends PersistedStore<AccessibilitySettings> {
  constructor() {
    super(initialState, "AccessibilityStore", 1)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "ACCESSIBILITY_SETTINGS_UPDATE":
        return this.handleAccessibilitySettingsUpdate(action)
      default:
        return false
    }
  }

  getSaturationFactor() {
    return this.state.saturationFactor
  }

  getAlwaysUnderlineLinks() {
    return this.state.alwaysUnderlineLinks
  }

  private handleAccessibilitySettingsUpdate({data}: {data: Partial<AccessibilitySettings>}) {
    this.setState({...this.state, ...data})
  }
}

export default new AccessibilityStore()
