import clsx from "clsx"
import _ from "lodash"
import {AnimatePresence} from "motion/react"
import {motion} from "motion/react"
import uaParser from "my-ua-parser"
import React from "react"
import {useForm} from "react-hook-form"
import {type ThemeType, ThemeTypes} from "~/Constants"
import * as AuthSessionActionCreators from "~/actions/AuthSessionActionCreators"
import * as AuthenticationActionCreators from "~/actions/AuthenticationActionCreators"
import * as BetaCodeActionCreators from "~/actions/BetaCodeActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as TextCopyActionCreators from "~/actions/TextCopyActionCreators"
import * as ToastActionCreators from "~/actions/ToastActionCreators"
import * as UserActionCreators from "~/actions/UserActionCreators"
import * as UserSettingsActionCreators from "~/actions/UserSettingsActionCreators"
import * as UserSettingsModalActionCreators from "~/actions/UserSettingsModalActionCreators"
import {Form} from "~/components/form/Form"
import {BaseTextarea, Input, Radio, Select, Textarea} from "~/components/form/Input"
import {Switch} from "~/components/form/Switch"
import {BackupCodesViewModal} from "~/components/modals/BackupCodesViewModal"
import {ConfirmModal} from "~/components/modals/ConfirmModal"
import {DeviceRevokeModal} from "~/components/modals/DeviceRevokeModal"
import {EmailChangeModal} from "~/components/modals/EmailChangeModal"
import {MfaTotpDisableModal} from "~/components/modals/MfaTotpDisableModal"
import {MfaTotpEnableModal} from "~/components/modals/MfaTotpEnableModal"
import * as Modal from "~/components/modals/Modal"
import {PasswordChangeModal} from "~/components/modals/PasswordChangeModal"
import {UsernameChangeModal} from "~/components/modals/UsernameChangeModal"
import {Avatar} from "~/components/uikit/Avatar"
import {Button} from "~/components/uikit/Button/Button"
import {ScrollArea} from "~/components/uikit/ScrollArea"
import {Slider} from "~/components/uikit/Slider"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import Dispatcher from "~/flux/Dispatcher"
import {useHover} from "~/hooks/useHover"
import {i18n} from "~/i18n"
import {CopyIcon} from "~/icons/CopyIcon"
import {DesktopDeviceIcon} from "~/icons/DesktopDeviceIcon"
import {EditIcon} from "~/icons/EditIcon"
import {LogoutIcon} from "~/icons/LogoutIcon"
import {MobileDeviceIcon} from "~/icons/MobileDeviceIcon"
import {SmileySadIcon} from "~/icons/SmileySadIcon"
import {WarningCircleIcon} from "~/icons/WarningCircleIcon"
import type {HttpResponse} from "~/lib/HttpClient"
import type {AuthSessionRecord} from "~/records/AuthSessionRecord"
import type {BetaCodeRecord} from "~/records/BetaCodeRecord"
import type {UserRecord} from "~/records/UserRecord"
import AccessibilityStore from "~/stores/AccessibilityStore"
import AuthSessionStore from "~/stores/AuthSessionStore"
import BetaCodeStore from "~/stores/BetaCodeStore"
import DeveloperOptionsStore, {type DeveloperOptionsState} from "~/stores/DeveloperOptionsStore"
import GatewayConnectionStore from "~/stores/GatewayConnectionStore"
import NotificationStore from "~/stores/NotificationStore"
import PresenceStore from "~/stores/PresenceStore"
import UserSettingsModalStore, {type UserSettingsTabType} from "~/stores/UserSettingsModalStore"
import UserSettingsStore, {type UserSettings} from "~/stores/UserSettingsStore"
import UserStore from "~/stores/UserStore"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as DateUtils from "~/utils/DateUtils"
import * as FormUtils from "~/utils/FormUtils"
import * as NotificationUtils from "~/utils/NotificationUtils"
import * as TimezoneUtils from "~/utils/TimezoneUtils"

export type UserSettingsTabCategories = "user_settings" | "app_settings" | "miscellaneous"

const UserSettingsTabCategoriesToString: Record<UserSettingsTabCategories, string> = {
  user_settings: i18n.Messages.USER_SETTINGS,
  app_settings: i18n.Messages.APP_SETTINGS,
  miscellaneous: i18n.Messages.MISCELLANEOUS,
}

type SettingsTab = {
  type: UserSettingsTabType
  category: UserSettingsTabCategories
  label: string
  component: React.ReactNode
}

const parsedUserAgent = new uaParser(navigator.userAgent).getResult()

const ClientInfo = () => {
  const browserName = parsedUserAgent.browser.name ?? "Unknown"
  const browserVersion = parsedUserAgent.browser.version
  const osName = parsedUserAgent.os.name ?? "Unknown"
  const osVersion = parsedUserAgent.os.version
  const versionHash = window.ENV.VERSION_HASH || "Unknown"

  const onClick = () => {
    TextCopyActionCreators.copy(
      `${window.ENV.NODE_ENV} (${versionHash}), ${browserName} ${browserVersion}, ${osName} ${osVersion}`,
    )
  }

  return (
    <Tooltip text={i18n.Messages.CLICK_TO_COPY}>
      <div
        role="button"
        onClick={onClick}
        onKeyDown={(event) => event.key === "Enter" && onClick()}
        tabIndex={0}
        className="mx-1 flex cursor-pointer flex-col items-start gap-1 text-sm text-text-primary-muted"
      >
        <span className="first-letter:uppercase">
          {window.ENV.NODE_ENV} ({versionHash})
        </span>
        <span>
          {browserName} {browserVersion}
        </span>
        <span>
          {osName} {osVersion}
        </span>
      </div>
    </Tooltip>
  )
}

const LogoutModal = () => (
  <ConfirmModal
    title={i18n.Messages.LOGOUT_MODAL_TITLE}
    description={i18n.Messages.LOGOUT_MODAL_DESCRIPTION}
    primaryText={i18n.Messages.LOGOUT_MODAL_PRIMARY_ACTION}
    secondaryText={i18n.Messages.LOGOUT_MODAL_SECONDARY_ACTION}
    onPrimary={() => AuthenticationActionCreators.logout()}
  />
)

export const UserSettingsModal = () => {
  const {selectedTab} = UserSettingsModalStore.useStore()
  const groupedSettingsTabs = SETTINGS_TABS.reduce(
    (acc, tab) => {
      if (!acc[tab.category]) {
        acc[tab.category] = []
      }
      acc[tab.category].push(tab)
      return acc
    },
    {} as Record<UserSettingsTabCategories, Array<SettingsTab>>,
  )

  return (
    <Modal.Root label={i18n.Messages.USER_SETTINGS} size="large">
      <div className="grid h-full flex-auto grid-cols-[1fr,3fr]">
        <div className="grid h-full grid-rows-[1fr,auto] bg-background-primary p-2">
          <ScrollArea className="flex flex-col">
            {Object.entries(groupedSettingsTabs).map(([category, tabs]) => (
              <div key={category}>
                <div className="px-[10px] py-[6px] font-semibold text-sm text-text-tertiary">
                  {UserSettingsTabCategoriesToString[category as UserSettingsTabCategories]}
                </div>
                <div className="mb-2">
                  {tabs.map((tab) => (
                    <div
                      key={tab.label}
                      onClick={() => UserSettingsModalActionCreators.selectTab(tab.type)}
                      onKeyDown={(event) =>
                        event.key === "Enter" && UserSettingsModalActionCreators.selectTab(tab.type)
                      }
                      tabIndex={0}
                      aria-selected={tab.type === selectedTab}
                      aria-label={tab.label}
                      aria-controls={tab.type}
                      role="tab"
                      className="active:translate-z-0 mb-[2px] transform-gpu cursor-pointer rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </ScrollArea>

          <div className="flex flex-col gap-4">
            <ClientInfo />
            <div
              role="button"
              onClick={() => ModalActionCreators.push(() => <LogoutModal />)}
              onKeyDown={(event) => event.key === "Enter" && ModalActionCreators.push(() => <LogoutModal />)}
              tabIndex={0}
              className="active:translate-z-0 mb-[2px] flex transform-gpu cursor-pointer items-center justify-between rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
            >
              {i18n.Messages.SIGN_OUT}
              <LogoutIcon className="mt-px h-4 w-4" />
            </div>
          </div>
        </div>

        <ScrollArea className="relative flex h-full min-h-0 min-w-0 flex-1 flex-grow flex-col border border-background-header-secondary">
          <div className="p-5">{SETTINGS_TABS.find((tab) => tab.type === selectedTab)?.component}</div>
        </ScrollArea>
      </div>
    </Modal.Root>
  )
}

const maskEmail = (email: string): string => {
  const [username, domain] = email.split("@")
  const maskedUsername = username.replace(/./g, "*")
  return `${maskedUsername}@${domain}`
}

const MyAccountTab = () => {
  const user = UserStore.useCurrentUser()
  const status = PresenceStore.useUserStatus(user?.id ?? "")
  const [showMaskedEmail, setShowMaskedEmail] = React.useState(false)

  if (!user) {
    return null
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.MY_ACCOUNT}</h2>
        </div>

        <div className="flex flex-col gap-3">
          <div className="relative overflow-hidden rounded-md bg-background-primary">
            <header>
              <div className="h-[100px] bg-brand-primary" />

              <div className="absolute top-[68px] left-[10px] z-0 rounded-full border-[6px] border-background-primary bg-background-primary">
                <Avatar forceAnimate={true} size={80} status={status} user={user} />
              </div>

              <div className="z-elevated-1 flex items-center justify-between overflow-hidden pt-[16px] pr-[16px] pl-[110px]">
                <div className="select-text">
                  <div className="flex items-center gap-0.5">
                    <span className="inline whitespace-normal break-words break-all align-middle font-semibold text-text-primary text-xl leading-[24px]">
                      {user.displayName}
                    </span>
                  </div>
                </div>

                <Button
                  variant="brand"
                  small={true}
                  leftIcon={<EditIcon className="h-4 w-4" />}
                  onClick={() => UserSettingsModalActionCreators.selectTab("edit_profile")}
                >
                  {i18n.Messages.EDIT_PROFILE}
                </Button>
              </div>
            </header>

            <div className="mx-[16px] mt-[20px] mb-[16px] flex flex-col gap-4 rounded-md bg-background-secondary p-[16px]">
              <div className="flex items-center justify-between">
                <div className="mr-4 flex flex-auto flex-col overflow-hidden">
                  <div className="font-medium text-sm text-text-primary-muted">{i18n.Messages.DISPLAY_NAME}</div>
                  <div className="truncate">{user.displayName}</div>
                </div>
                <Button
                  variant="secondary"
                  small={true}
                  onClick={() => UserSettingsModalActionCreators.selectTab("edit_profile")}
                >
                  {i18n.Messages.EDIT}
                </Button>
              </div>

              <div className="flex items-center justify-between">
                <div className="mr-4 flex flex-auto flex-col overflow-hidden">
                  <div className="font-medium text-sm text-text-primary-muted">{i18n.Messages.USERNAME}</div>
                  <div className="truncate">@{user.handle}</div>
                </div>
                <Button
                  variant="secondary"
                  small={true}
                  onClick={() => ModalActionCreators.push(() => <UsernameChangeModal />)}
                >
                  {i18n.Messages.EDIT}
                </Button>
              </div>

              <div className="flex items-center justify-between">
                <div className="mr-4 flex flex-auto flex-col overflow-hidden">
                  <div className="font-medium text-sm text-text-primary-muted">{i18n.Messages.EMAIL}</div>
                  <div className="flex gap-2">
                    <div className="truncate">{showMaskedEmail ? user.email : maskEmail(user.email!)}</div>
                    <button
                      type="button"
                      className="cursor-pointer text-sm text-text-link hover:underline"
                      onClick={() => setShowMaskedEmail(!showMaskedEmail)}
                    >
                      {showMaskedEmail ? i18n.Messages.HIDE : i18n.Messages.REVEAL}
                    </button>
                  </div>
                </div>
                <Button
                  variant="secondary"
                  small={true}
                  onClick={() => ModalActionCreators.push(() => <EmailChangeModal />)}
                >
                  {i18n.Messages.EDIT}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-start gap-3">
          <div className="flex flex-col gap-1">
            <h2 className="font-semibold text-lg">{i18n.Messages.PASSWORD_AUTHENTICATION}</h2>
            <div className="text-sm text-text-primary-muted">{i18n.Messages.PASSWORD_AUTHENTICATION_DESCRIPTION}</div>
          </div>

          <Button variant="brand" small={true} onClick={() => ModalActionCreators.push(() => <PasswordChangeModal />)}>
            {i18n.Messages.CHANGE_PASSWORD}
          </Button>
        </div>

        <div className="flex flex-col items-start gap-3">
          <div className="flex flex-col gap-1 overflow-hidden">
            <div className="font-semibold text-sm text-text-primary">{i18n.Messages.AUTHENTICATOR_APP}</div>
            <div className="text-sm text-text-primary-muted">{i18n.Messages.AUTHENTICATOR_APP_DESCRIPTION}</div>
          </div>

          {user.mfaEnabled ? (
            <div className="flex gap-3">
              <Button
                variant="brand"
                small={true}
                onClick={() => ModalActionCreators.push(() => <BackupCodesViewModal />)}
              >
                {i18n.Messages.VIEW_BACKUP_CDOES}
              </Button>
              <Button
                variant="danger-outline"
                small={true}
                onClick={() => ModalActionCreators.push(() => <MfaTotpDisableModal />)}
              >
                {i18n.Messages.REMOVE_AUTHENTICATOR_APP}
              </Button>
            </div>
          ) : (
            <div className="flex gap-3">
              <Button
                variant="brand"
                small={true}
                onClick={() => ModalActionCreators.push(() => <MfaTotpEnableModal />)}
              >
                {i18n.Messages.SETUP_AUTHENTICATOR_APP}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-start gap-3">
          <div className="flex flex-col gap-1">
            <h2 className="font-semibold text-lg">{i18n.Messages.ACCOUNT_REMOVAL}</h2>
            <div className="text-sm text-text-primary-muted">{i18n.Messages.ACCOUNT_REMOVAL_DESCRIPTION}</div>
          </div>

          <div className="flex gap-3">
            <Button variant="danger" small={true}>
              {i18n.Messages.DISABLE_ACCOUNT}
            </Button>
            <Button variant="danger-outline" small={true}>
              {i18n.Messages.DELETE_ACCOUNT}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

type FormInputs = {
  avatar?: string | null
  nickname: string | null
  pronouns: string | null
  biography: string | null
  location: string | null
  timezone: string | null
}

const EditProfileTab = () => {
  const user = UserStore.useCurrentUser()
  const [hasClearedAvatar, setHasClearedAvatar] = React.useState(false)
  const [previewAvatarUrl, setPreviewAvatarUrl] = React.useState<string | null>(null)
  const form = useForm<FormInputs>({
    defaultValues: React.useMemo(
      () => ({
        nickname: user?.nickname,
        pronouns: user?.pronouns,
        biography: user?.biography,
        location: user?.location,
        timezone: user?.timezone,
      }),
      [user],
    ),
  })

  const onSubmit = async (data: FormInputs) => {
    try {
      const newUser = await UserActionCreators.update({
        avatar: data.avatar,
        nickname: data.nickname,
        pronouns: data.pronouns,
        biography: data.biography,
        location: data.location,
        timezone: data.timezone,
      })

      form.reset({
        nickname: newUser.nickname,
        pronouns: newUser.pronouns,
        biography: newUser.biography,
        location: newUser.location,
        timezone: newUser.timezone,
      })

      ToastActionCreators.createToast({
        type: "success",
        children: i18n.Messages.PROFILE_UPDATED,
      })
    } catch (error) {
      FormUtils.handleError(form, error as HttpResponse, "nickname")
    }
  }

  const inputRef = React.useRef<HTMLInputElement>(null)
  const handleFileChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const file = event.target.files?.[0]
        if (!file) {
          return
        }
        const base64 = await AvatarUtils.fileToBase64(file)
        event.target.value = ""
        form.setValue("avatar", base64)
        setPreviewAvatarUrl(base64)
        setHasClearedAvatar(false)
      } catch {
        ToastActionCreators.createToast({
          type: "error",
          children: i18n.Messages.INVALID_IMAGE,
        })
      }
    },
    [form],
  )

  const handleClearAvatar = React.useCallback(() => {
    form.setValue("avatar", null)
    setPreviewAvatarUrl(null)
    setHasClearedAvatar(true)
  }, [form])

  if (!user) {
    return null
  }

  const avatarPresentable =
    previewAvatarUrl ??
    AvatarUtils.getUserAvatarURL({id: user.id, avatar: hasClearedAvatar || !user.avatar ? null : user.avatar}, true)

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.EDIT_PROFILE}</h2>
        </div>
      </div>

      <Form
        form={form}
        onSubmit={onSubmit}
        className="flex h-full flex-col items-center rounded-md border border-background-header-secondary bg-background-tertiary p-4"
      >
        <div className="flex flex-col items-center gap-2">
          <div
            className="active:translate-z-0 relative inline-block h-20 w-20 transform-gpu cursor-pointer rounded-full bg-center bg-cover active:translate-y-px"
            style={{
              backgroundImage: avatarPresentable ? `url(${avatarPresentable})` : undefined,
            }}
          >
            <input
              accept="image/jpeg,image/png,image/gif,image/webp"
              onChange={handleFileChange}
              ref={inputRef}
              style={{display: "none"}}
              type="file"
            />
            <div
              aria-label={i18n.Messages.CHANGE_AVATAR}
              className="absolute inset-0 flex items-center justify-center"
              onClick={() => inputRef.current?.click()}
              onKeyDown={(event) => event.key === "Enter" && inputRef.current?.click()}
              role="button"
              tabIndex={0}
            />
          </div>

          <span
            className="font-medium text-sm text-text-link hover:underline"
            onClick={handleClearAvatar}
            onKeyDown={(event) => event.key === "Enter" && handleClearAvatar()}
            role="button"
            tabIndex={0}
          >
            {i18n.Messages.CLEAR_AVATAR}
          </span>

          <div className="flex flex-col items-center gap-1 text-center text-sm text-text-primary-muted">
            <p>{i18n.Messages.SUPPORTED_FORMATS}</p>
            <p>{i18n.Messages.RECOMMENDED_SIZE}</p>
          </div>

          {form.formState.errors.avatar?.message && (
            <p className="text-sm text-text-danger">{form.formState.errors.avatar.message}</p>
          )}
        </div>

        <div className="flex flex-col gap-3">
          <Input
            {...form.register("nickname")}
            type="text"
            label={i18n.Messages.DISPLAY_NAME}
            placeholder={user.username}
            maxLength={32}
            footer={<span className="text-sm text-text-primary-muted">{i18n.Messages.DISPLAY_NAME_HELPER_TEXT}</span>}
            error={form.formState.errors.nickname?.message}
          />

          <Input
            {...form.register("pronouns")}
            type="text"
            label={i18n.Messages.PRONOUNS}
            placeholder={i18n.Messages.PRONOUNS_PLACEHOLDER}
            maxLength={40}
            error={form.formState.errors.pronouns?.message}
          />

          <Textarea
            {...form.register("biography")}
            label={i18n.Messages.ABOUT_ME}
            placeholder={i18n.Messages.ABOUT_ME_PLACEHOLDER}
            maxLength={190}
            error={form.formState.errors.biography?.message}
            footer={<span className="text-sm text-text-primary-muted">{i18n.Messages.ABOUT_ME_HELPER_TEXT}</span>}
          />

          <Input
            {...form.register("location")}
            type="text"
            label={i18n.Messages.LOCATION}
            placeholder={i18n.Messages.LOCATION_PLACEHOLDER}
            maxLength={40}
            error={form.formState.errors.location?.message}
          />

          <Select
            {...form.register("timezone")}
            label={i18n.Messages.LOCAL_TIME}
            options={TimezoneUtils.getTimezoneOptions()}
            footer={
              <div className="mt-1 flex items-center gap-1.5">
                <input
                  className="h-4 w-4 rounded-md accent-brand-primary"
                  defaultChecked={user.timezone != null}
                  id="display-local-time"
                  onChange={(event) =>
                    form.setValue("timezone", event.target.checked ? TimezoneUtils.getBrowserTimezone() : null)
                  }
                  type="checkbox"
                />
                <label className="text-text-primary" htmlFor="display-local-time">
                  {i18n.Messages.DISPLAY_LOCAL_TIME}
                </label>
              </div>
            }
          />
        </div>

        <div className="mt-4 flex">
          <Button variant="ghost" onClick={form.reset}>
            {i18n.Messages.RESET}
          </Button>
          <Button type="submit" variant="primary" submitting={form.formState.isSubmitting}>
            {i18n.Messages.SAVE_CHANGES}
          </Button>
        </div>
      </Form>
    </div>
  )
}

type SlateProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  title: string
  description: string
  buttonText?: string
  onClick?: () => void
}

const Slate = ({icon: Icon, title, description, buttonText, onClick}: SlateProps) => (
  <div className="flex h-full items-center justify-center">
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col items-center gap-2">
        <Icon className="h-14 w-14 text-text-primary-muted" />
        <div className="flex flex-col items-center gap-2 text-center">
          <h3 className="font-semibold text-text-primary text-xl">{title}</h3>
          <p className="text-base text-text-primary-muted">{description}</p>
        </div>
      </div>
      {buttonText && (
        <Button variant="brand" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  </div>
)

const BetaCode = ({betaCode}: {betaCode: BetaCodeRecord}) => {
  const [hoverRef, isHovering] = useHover()
  const onCopy = () => TextCopyActionCreators.copy(betaCode.code)
  return (
    <div
      ref={hoverRef}
      className="flex items-center justify-between gap-2 rounded-md border border-background-header-secondary bg-background-primary p-4"
    >
      <div className="flex flex-col items-start gap-1">
        <div className="flex items-center gap-1">
          <pre className="select-text font-medium text-lg text-text-primary">{betaCode.code}</pre>
          <AnimatePresence>
            {isHovering && (
              <Tooltip text={i18n.Messages.CLICK_TO_COPY}>
                <motion.div
                  role="button"
                  onClick={onCopy}
                  onKeyDown={(event) => event.key === "Enter" && onCopy()}
                  tabIndex={0}
                  className="cursor-pointer"
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  initial={{opacity: 0}}
                >
                  <CopyIcon className="mt-[.1em] ml-0.5 h-5 w-5 text-text-primary-muted" />
                </motion.div>
              </Tooltip>
            )}
          </AnimatePresence>
        </div>

        {betaCode.redeemer && (
          <div className="flex items-center text-sm text-text-primary-muted">
            {i18n.format(i18n.Messages.REDEEMED_BY, {handle: betaCode.redeemer.handle})}
            <div
              aria-hidden={true}
              className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
            />
            {DateUtils.getShortRelativeDateString(betaCode.redeemedAt!)}
          </div>
        )}
      </div>

      <Button
        variant="danger-outline"
        small={true}
        disabled={betaCode.redeemer != null}
        onClick={() => BetaCodeActionCreators.remove(betaCode.code)}
      >
        {i18n.Messages.REVOKE}
      </Button>
    </div>
  )
}

const BetaCodesTab = () => {
  const {betaCodes, fetchStatus} = BetaCodeStore.useStore()

  React.useEffect(() => {
    BetaCodeActionCreators.fetch()
  }, [])

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.BETA_CODES}</h2>
        </div>
      </div>

      {fetchStatus === "error" && (
        <Slate
          icon={WarningCircleIcon}
          title={i18n.Messages.NETWORK_ERROR}
          description={i18n.Messages.NETWORK_ERROR_DESCRIPTION}
          buttonText={i18n.Messages.RETRY}
          onClick={() => BetaCodeActionCreators.fetch()}
        />
      )}

      {fetchStatus === "success" && betaCodes.length === 0 && (
        <Slate
          icon={SmileySadIcon}
          title={i18n.Messages.NO_BETA_CODES_TITLE}
          description={i18n.Messages.NO_BETA_CODES_DESCRIPTION}
          buttonText={i18n.Messages.GENERATE_BETA_CODE}
          onClick={() => BetaCodeActionCreators.create()}
        />
      )}

      {fetchStatus === "success" && betaCodes.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            {betaCodes.map((betaCode) => (
              <BetaCode key={betaCode.code} betaCode={betaCode} />
            ))}
          </div>

          <div className="flex justify-end">
            <Button variant="brand" onClick={() => BetaCodeActionCreators.create()}>
              {i18n.Messages.GENERATE_BETA_CODE}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const PrivacySafetyTab = () => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <h2 className="font-semibold text-xl">{i18n.Messages.PRIVACY_SAFETY}</h2>
      </div>
    </div>
  </div>
)

const MOBILE_DEVICE_REGEX = /iOS|Android|Windows Phone|BlackBerry|Mobile/i

const AuthSession = ({authSession, isCurrent}: {authSession: AuthSessionRecord; isCurrent?: boolean}) => {
  const isMobile = MOBILE_DEVICE_REGEX.test(authSession.clientOs)
  return (
    <div className="flex items-center justify-between gap-4 rounded-md border border-background-header-secondary bg-background-primary p-4">
      <div className="flex items-center gap-4">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-text-primary-muted text-background-primary">
          {isMobile ? <MobileDeviceIcon className="h-6 w-6" /> : <DesktopDeviceIcon className="h-6 w-6" />}
        </div>
        <div className="flex flex-col">
          <span className="font-semibold text-base text-text-primary">
            {authSession.clientOs}
            <div
              aria-hidden={true}
              className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
            />
            {authSession.clientPlatform}
          </span>
          <div className="text-sm text-text-primary-muted">
            {authSession.clientLocation}
            {!isCurrent && (
              <>
                <div
                  aria-hidden={true}
                  className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
                />
                {DateUtils.getShortRelativeDateString(authSession.approxLastUsedAt)}
              </>
            )}
          </div>
        </div>
      </div>
      {!isCurrent && (
        <Button
          variant="danger-outline"
          small={true}
          onClick={() => ModalActionCreators.push(() => <DeviceRevokeModal sessionIdHashes={[authSession.id]} />)}
        >
          {i18n.Messages.REVOKE}
        </Button>
      )}
    </div>
  )
}

const DevicesTab = () => {
  const {authSessionIdHash, authSessions, fetchStatus} = AuthSessionStore.useStore()
  const otherDevices = authSessions.filter((authSession) => authSession.id !== authSessionIdHash)

  React.useEffect(() => {
    AuthSessionActionCreators.fetch()
  }, [])

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.DEVICES}</h2>
          <p className="text-base text-text-primary-muted">{i18n.Messages.DEVICES_DESCRIPTION}</p>
        </div>
      </div>

      {fetchStatus === "error" && (
        <Slate
          icon={WarningCircleIcon}
          title={i18n.Messages.NETWORK_ERROR}
          description={i18n.Messages.NETWORK_ERROR_DESCRIPTION}
          buttonText={i18n.Messages.RETRY}
          onClick={() => AuthSessionActionCreators.fetch()}
        />
      )}

      {fetchStatus === "success" && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg">{i18n.Messages.CURRENT_DEVICE}</h3>
            <div className="flex flex-col gap-1">
              <AuthSession
                authSession={authSessions.find((authSession) => authSession.id === authSessionIdHash)!}
                isCurrent={true}
              />
            </div>
          </div>

          {otherDevices.length > 0 && (
            <div className="flex flex-col gap-2">
              <h3 className="font-semibold text-lg">{i18n.Messages.OTHER_DEVICES}</h3>
              <div className="flex flex-col gap-1">
                {authSessions
                  .filter((authSession) => authSession.id !== authSessionIdHash)
                  .map((authSession) => (
                    <AuthSession key={authSession.id} authSession={authSession} />
                  ))}
              </div>
            </div>
          )}

          {otherDevices.length > 0 && (
            <div className="flex flex-col gap-2">
              <Button
                variant="danger-outline"
                onClick={() =>
                  ModalActionCreators.push(() => (
                    <DeviceRevokeModal sessionIdHashes={otherDevices.map((authSession) => authSession.id)} />
                  ))
                }
              >
                {i18n.Messages.LOG_OUT_ALL_OTHER_DEVICES}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const ConnectionsTab = () => (
  <div className="flex flex-col gap-4">
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <h2 className="font-semibold text-xl">{i18n.Messages.CONNECTIONS}</h2>
      </div>
    </div>
  </div>
)

const AppearanceTab = () => {
  const {theme, custom_css: customCss} = UserSettingsStore.useStore()

  const saveCustomCss = React.useCallback(
    _.debounce((value: string) => UserSettingsActionCreators.update({custom_css: value}), 500),
    [],
  )

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.APPEARANCE}</h2>
        </div>
      </div>

      <Radio
        name="theme"
        label={i18n.Messages.APP_THEME}
        defaultValue={theme}
        onChange={(event) => UserSettingsActionCreators.update({theme: Number(event.target.value) as ThemeType})}
        options={[
          {label: i18n.Messages.THEME_DEFAULT_DARK, value: ThemeTypes.DARK},
          {label: i18n.Messages.THEME_DEFAULT_LIGHT, value: ThemeTypes.LIGHT},
          {label: i18n.Messages.THEME_SOLARIZED, value: ThemeTypes.SOLARIZED},
          {label: i18n.Messages.THEME_MIDNIGHT, value: ThemeTypes.MIDNIGHT},
          {label: i18n.Messages.THEME_TWILIGHT, value: ThemeTypes.TWILIGHT},
          {label: i18n.Messages.THEME_SPARKLE, value: ThemeTypes.SPARKLE},
        ]}
      />

      <div className="flex flex-col gap-2">
        <h3 className="font-semibold text-sm text-text-tertiary">{i18n.Messages.CUSTOM_CSS}</h3>
        <BaseTextarea
          defaultValue={customCss ?? ""}
          onChange={(event) => saveCustomCss(event.target.value)}
          minRows={10}
        />
      </div>
    </div>
  )
}

const AccessibilityTab: React.FC = () => {
  const {saturationFactor, alwaysUnderlineLinks} = AccessibilityStore.useStore()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.ACCESSIBILITY}</h2>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <div className="mb-1 flex flex-col gap-1">
          <label htmlFor="saturation" className="block text-text-primary">
            {i18n.Messages.SATURATION}
          </label>
          <p className="text-text-primary-muted">{i18n.Messages.SATURATION_DESCRIPTION}</p>
        </div>
        <Slider
          value={saturationFactor * 10}
          min={0}
          max={10}
          step={1}
          onChange={(value) =>
            Dispatcher.dispatch({type: "ACCESSIBILITY_SETTINGS_UPDATE", data: {saturationFactor: value / 10}})
          }
        />

        <div className="mt-4">
          <Switch
            label={i18n.Messages.ALWAYS_UNDERLINE_LINKS}
            description={i18n.Messages.ALWAYS_UNDERLINE_LINKS_DESCRIPTION}
            value={alwaysUnderlineLinks}
            onChange={(value) =>
              Dispatcher.dispatch({
                type: "ACCESSIBILITY_SETTINGS_UPDATE",
                data: {alwaysUnderlineLinks: value},
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

type ChatSettingsSection = {
  title: string
  settings: Array<{
    label: string
    description: string
    key: keyof UserSettings
  }>
}

const chatSettingsSections: Array<ChatSettingsSection> = [
  {
    title: i18n.Messages.DISPLAY_IMAGES_VIDEOS_AND_LOLCATS,
    settings: [
      {
        label: i18n.Messages.INLINE_EMBED_MEDIA_TITLE,
        description: i18n.Messages.INLINE_EMBED_MEDIA_DESCRIPTION,
        key: "inline_embed_media",
      },
      {
        label: i18n.Messages.INLINE_ATTACHMENT_MEDIA_TITLE,
        description: i18n.Messages.INLINE_ATTACHMENT_MEDIA_DESCRIPTION,
        key: "inline_attachment_media",
      },
    ],
  },
  {
    title: i18n.Messages.EMBEDS_AND_LINK_PREVIEWS,
    settings: [
      {
        label: i18n.Messages.RENDER_EMBEDS_TITLE,
        description: i18n.Messages.RENDER_EMBEDS_DESCRIPTION,
        key: "render_embeds",
      },
    ],
  },
  {
    title: i18n.Messages.EMOJIS,
    settings: [
      {
        label: i18n.Messages.ANIMATE_EMOJI_TITLE,
        description: i18n.Messages.ANIMATE_EMOJI_DESCRIPTION,
        key: "animate_emoji",
      },
      {
        label: i18n.Messages.RENDER_REACTIONS_TITLE,
        description: i18n.Messages.RENDER_REACTIONS_DESCRIPTION,
        key: "render_reactions",
      },
    ],
  },
  {
    title: i18n.Messages.GIFS,
    settings: [
      {
        label: i18n.Messages.GIF_AUTO_PLAY_TITLE,
        description: i18n.Messages.GIF_AUTO_PLAY_DESCRIPTION,
        key: "gif_auto_play",
      },
    ],
  },
]

const ChatSettingsTab = () => {
  const userSettings = UserSettingsStore.useStore()
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.CHAT_SETTINGS_TITLE}</h2>
        </div>
      </div>

      {chatSettingsSections.map((section, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <div key={index} className={clsx("flex flex-col gap-2", index === 0 ? "mt-4" : "mt-5")}>
          <h3 className="font-semibold text-sm text-text-tertiary">{section.title}</h3>

          <div className="flex flex-col gap-4">
            {section.settings.map((setting, subIndex) => (
              <Switch
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={subIndex}
                label={setting.label}
                description={setting.description}
                value={userSettings[setting.key] as boolean}
                onChange={(value) => UserSettingsActionCreators.update({[setting.key]: value})}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const NotificationsTab = () => {
  const {browserNotificationsEnabled} = NotificationStore.useStore()
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">{i18n.Messages.NOTIFICATIONS_TITLE}</h2>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <Switch
          label={i18n.Messages.BROWSER_NOTIFICATIONS_TITLE}
          description={i18n.Messages.BROWSER_NOTIFICATIONS_DESCRIPTION}
          value={browserNotificationsEnabled}
          onChange={(value) => {
            if (value) {
              NotificationUtils.requestPermission()
            } else {
              Dispatcher.dispatch({type: "NOTIFICATION_PERMISSION_DENIED"})
            }
          }}
        />
      </div>
    </div>
  )
}

type DeveloperOptionToggle = {
  key: keyof DeveloperOptionsState
  label: string
  description?: string
  isEnabled?: (user: UserRecord) => boolean
  onToggle?: (checked: boolean) => void
}

const DEVELOPER_OPTIONS_TOGGLES: Array<DeveloperOptionToggle> = [
  {
    key: "bypassSplashScreen",
    label: "Bypass Splash Screen",
  },
  {
    key: "debugLoggingEnabled",
    label: "Enable Debug Logging",
  },
  {
    key: "forceFailUploads",
    label: "Force Fail Uploads",
  },
  {
    key: "forceRenderPlaceholders",
    label: "Force Placeholder Rendering",
  },
  {
    key: "forceUpdateReady",
    label: "Force Update Ready",
  },
  {
    key: "showMyselfTyping",
    label: "Show Myself Typing",
  },
  {
    key: "slowAttachmentUpload",
    label: "Slow Attachment Upload",
  },
  {
    key: "slowMessageLoad",
    label: "Slow Message Load",
  },
  {
    key: "slowMessageSend",
    label: "Slow Message Send",
  },
  {
    key: "slowProfileLoad",
    label: "Slow Profile Load",
  },
]

const DeveloperOptionsTab = () => {
  const [shouldCrash, setShouldCrash] = React.useState(false)
  const {gatewayClient} = GatewayConnectionStore.useStore()
  const developerOptions = DeveloperOptionsStore.useStore()
  const user = UserStore.useCurrentUser()

  if (!(user && gatewayClient)) {
    return null
  }

  if (shouldCrash) {
    return {} as never
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">Developer Options</h2>
        </div>

        <div className="flex flex-col gap-3">
          {DEVELOPER_OPTIONS_TOGGLES.map(({key, label, description, isEnabled, onToggle}) => (
            <Switch
              key={key}
              label={label}
              value={developerOptions[key]}
              description={description}
              disabled={isEnabled != null ? !isEnabled(user) : false}
              onChange={(value) => {
                if (onToggle) {
                  onToggle(value)
                } else {
                  Dispatcher.dispatch({type: "DEVELOPER_OPTIONS_UPDATE", key, value})
                }
              }}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <h2 className="font-semibold text-lg">Debugging</h2>
        <div className="flex gap-3">
          <Button onClick={() => gatewayClient.reset()}>Reset Socket</Button>
          <Button onClick={() => setShouldCrash(true)}>React Crash</Button>
        </div>
      </div>
    </div>
  )
}

const ComponentGalleryTab = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="font-semibold text-xl">Component Gallery</h2>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <h3 className="font-semibold text-lg">Buttons</h3>

            <div className="flex flex-wrap items-start gap-3">
              <Button variant="brand">Brand</Button>
              <Button variant="brand" disabled={true}>
                Brand (Disabled)
              </Button>
              <Button variant="primary">Primary</Button>
              <Button variant="primary" disabled={true}>
                Primary (Disabled)
              </Button>
              <Button variant="secondary">Secondary</Button>
              <Button variant="secondary" disabled={true}>
                Secondary (Disabled)
              </Button>
              <Button variant="danger">Danger</Button>
              <Button variant="danger" disabled={true}>
                Danger (Disabled)
              </Button>
              <Button variant="danger-outline">Danger Outline</Button>
              <Button variant="danger-outline" disabled={true}>
                Danger Outline (Disabled)
              </Button>
              <Button variant="ghost">Ghost</Button>
              <Button variant="ghost" disabled={true}>
                Ghost (Disabled)
              </Button>
              <Button variant="inverted">Inverted</Button>
              <Button variant="inverted" disabled={true}>
                Inverted (Disabled)
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SETTINGS_TABS: Array<SettingsTab> = [
  {
    type: "my_account",
    category: "user_settings",
    label: i18n.Messages.MY_ACCOUNT,
    component: <MyAccountTab />,
  },
  {
    type: "edit_profile",
    category: "user_settings",
    label: i18n.Messages.EDIT_PROFILE,
    component: <EditProfileTab />,
  },
  {
    type: "beta_codes",
    category: "user_settings",
    label: i18n.Messages.BETA_CODES,
    component: <BetaCodesTab />,
  },
  {
    type: "privacy_safety",
    category: "user_settings",
    label: i18n.Messages.PRIVACY_SAFETY,
    component: <PrivacySafetyTab />,
  },
  {
    type: "devices",
    category: "user_settings",
    label: i18n.Messages.DEVICES,
    component: <DevicesTab />,
  },
  {
    type: "connections",
    category: "user_settings",
    label: i18n.Messages.CONNECTIONS,
    component: <ConnectionsTab />,
  },
  {
    type: "appearance",
    category: "app_settings",
    label: i18n.Messages.APPEARANCE,
    component: <AppearanceTab />,
  },
  {
    type: "accessibility",
    category: "app_settings",
    label: i18n.Messages.ACCESSIBILITY,
    component: <AccessibilityTab />,
  },
  {
    type: "chat_settings",
    category: "app_settings",
    label: i18n.Messages.CHAT_SETTINGS,
    component: <ChatSettingsTab />,
  },
  {
    type: "notifications",
    category: "app_settings",
    label: i18n.Messages.NOTIFICATIONS,
    component: <NotificationsTab />,
  },
  {
    type: "developer_options",
    category: "miscellaneous",
    label: i18n.Messages.DEVELOPER_OPTIONS,
    component: <DeveloperOptionsTab />,
  },
  {
    type: "component_gallery",
    category: "miscellaneous",
    label: i18n.Messages.COMPONENT_GALLERY,
    component: <ComponentGalleryTab />,
  },
]
