import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type AuthSession, AuthSessionRecord} from "~/records/AuthSessionRecord"

type State = {
  authSessionIdHash: string | null
  authSessions: Array<AuthSessionRecord>
  fetchStatus: "idle" | "pending" | "success" | "error"
  isDeleteError: boolean
}

const initialState: State = {
  authSessionIdHash: null,
  authSessions: [],
  fetchStatus: "idle",
  isDeleteError: false,
}

class AuthSessionStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "AUTH_SESSION_CHANGE":
        return this.handleAuthSessionChange(action)

      case "AUTH_SESSIONS_FETCH_PENDING":
        return this.handleAuthSessionsFetchPending()
      case "AUTH_SESSIONS_FETCH_SUCCESS":
        return this.handleAuthSessionsFetchSuccess(action)
      case "AUTH_SESSIONS_FETCH_ERROR":
        return this.handleAuthSessionsFetchError()

      case "AUTH_SESSIONS_LOGOUT_PENDING":
        return this.handleAuthSessionsLogoutPending()
      case "AUTH_SESSIONS_LOGOUT_SUCCESS":
        return this.handleAuthSessionsLogoutSuccess(action)
      case "AUTH_SESSIONS_LOGOUT_ERROR":
        return this.handleAuthSessionsLogoutError()

      default:
        return false
    }
  }

  private handleConnectionOpen({authSessionIdHash}: {authSessionIdHash: string}) {
    this.setState((prevState) => ({...prevState, authSessionIdHash}))
  }

  private handleAuthSessionChange({authSessionIdHash}: {authSessionIdHash: string}) {
    this.setState((prevState) => ({...prevState, authSessionIdHash}))
  }

  private handleAuthSessionsFetchPending() {
    this.setState((prevState) => ({...prevState, fetchStatus: "pending"}))
  }

  private handleAuthSessionsFetchSuccess({authSessions}: {authSessions: Array<AuthSession>}) {
    this.setState((prevState) => ({
      ...prevState,
      authSessions: authSessions.map((session) => new AuthSessionRecord(session)),
      fetchStatus: "success",
    }))
  }

  private handleAuthSessionsFetchError() {
    this.setState((prevState) => ({...prevState, fetchStatus: "error"}))
  }

  private handleAuthSessionsLogoutPending() {
    this.setState((prevState) => ({...prevState, isDeleteError: false}))
  }

  private handleAuthSessionsLogoutSuccess({sessionIdHashes}: {sessionIdHashes: Array<string>}) {
    this.setState((prevState) => ({
      ...prevState,
      authSessions: prevState.authSessions.filter((session) => !sessionIdHashes.includes(session.id)),
    }))
  }

  private handleAuthSessionsLogoutError() {
    this.setState((prevState) => ({...prevState, isDeleteError: true}))
  }
}

export default new AuthSessionStore()
