import {SKIN_TONE_SURROGATES} from "~/Constants"
import emojiData from "~/assets/emojis.json"
import {i18n} from "~/i18n"
import {ActivityIcon} from "~/icons/ActivityIcon"
import {FlagIcon} from "~/icons/FlagIcon"
import {FoodIcon} from "~/icons/FoodIcon"
import {LoveIcon} from "~/icons/LoveIcon"
import {MagnetIcon} from "~/icons/MagnetIcon"
import {NatureIcon} from "~/icons/NatureIcon"
import {SmileyIcon} from "~/icons/SmileyIcon"
import {TravelIcon} from "~/icons/TravelIcon"
import EmojiStore from "~/stores/EmojiStore"
import * as EmojiUtils from "~/utils/EmojiUtils"
import * as RegexUtils from "~/utils/RegexUtils"

type EmojiData = {
  names: Array<string>
  surrogates: string
  skins?: Array<Skin>
}

type Skin = {
  names: Array<string>
  surrogates: string
}

type EmojiSkinInfo = {
  url: string
  name: string
  surrogatePair: string
}

export class UnicodeEmoji {
  uniqueName: string
  names: Array<string>
  allNamesString: string
  skins: Array<Skin>
  _surrogates: string
  hasSkins?: boolean
  managed?: boolean
  index: number | null
  skinsByName: Map<string, EmojiSkinInfo>
  urlForSkinTone: Map<string, string>
  defaultURL: string

  constructor(emojiData: EmojiData) {
    this.uniqueName = emojiData.names[0]
    this.names = emojiData.names
    this.allNamesString = this.names.length > 1 ? `:${this.names.join(": :")}:` : `:${this.uniqueName}:`
    this.skins = emojiData.skins || []
    this._surrogates = emojiData.surrogates
    this.index = null
    this.urlForSkinTone = new Map()
    this.skinsByName = new Map()
    this.defaultURL = EmojiUtils.getURL(this._surrogates)

    if (this.skins.length > 0) {
      this.hasSkins = true
      this.skins.forEach((skin, i) => {
        const url = EmojiUtils.getURL(skin.surrogates)
        this.urlForSkinTone.set(skin.surrogates, url)
        const nameWithSkinTag = `${this.uniqueName}::skin-tone-${i + 1}`
        this.skinsByName.set(nameWithSkinTag, {
          name: nameWithSkinTag,
          surrogatePair: skin.surrogates,
          url,
        })
      })
    }
  }

  get url() {
    const skinTone = EmojiStore.getSkinTone()
    return this.hasSkins && skinTone ? this.urlForSkinTone.get(this.surrogatePair) || this.defaultURL : this.defaultURL
  }

  get name() {
    const skinTone = EmojiStore.getSkinTone()
    return this.hasSkins && skinTone ? `${this.uniqueName}::${surrogateToName.get(skinTone)}` : this.uniqueName
  }

  get surrogatePair() {
    return this.skinsByName.get(this.name)?.surrogatePair ?? this._surrogates
  }

  forEachSkin(callback: (skin: EmojiSkinInfo) => void) {
    this.skinsByName.forEach(callback)
  }
}

const emojisByCategory = new Map<string, Array<UnicodeEmoji>>()
const nameToEmoji = new Map<string, UnicodeEmoji>()
const nameToSurrogate = new Map<string, string>()
const surrogateToName = new Map<string, string>()
const emojis: Array<UnicodeEmoji> = []

const emojiDataTyped = emojiData as {
  people: Array<EmojiData>
  nature: Array<EmojiData>
  food: Array<EmojiData>
  activity: Array<EmojiData>
  travel: Array<EmojiData>
  objects: Array<EmojiData>
  symbols: Array<EmojiData>
  flags: Array<EmojiData>
}

let numSkinToneSprites = 0
let numNonSkinToneSprites = 0

for (const [category, categoryData] of Object.entries(emojiDataTyped)) {
  const categoryEmojis: Array<UnicodeEmoji> = []

  for (const emojiDatum of categoryData) {
    const emoji = new UnicodeEmoji(emojiDatum)
    emoji.index = emoji.hasSkins ? numSkinToneSprites++ : numNonSkinToneSprites++
    emoji.managed = true

    surrogateToName.set(emoji._surrogates, emoji.uniqueName)

    for (const name of emoji.names) {
      nameToEmoji.set(name, emoji)
      nameToSurrogate.set(name, emoji._surrogates)
    }

    emoji.forEachSkin((skin) => {
      nameToEmoji.set(skin.name, emoji)
      nameToSurrogate.set(skin.name, skin.surrogatePair)
      surrogateToName.set(skin.surrogatePair, skin.name)
    })

    emojis.push(emoji)
    categoryEmojis.push(emoji)
  }

  emojisByCategory.set(category, categoryEmojis)
}

SKIN_TONE_SURROGATES.forEach((surrogatePair, index) => {
  const skinTone = `skin-tone-${index + 1}`
  nameToSurrogate.set(skinTone, surrogatePair)
  surrogateToName.set(surrogatePair, skinTone)
})

export const EMOJI_NAME_RE = /^:([^\s:]+?(?:::skin-tone-\d)?):/
export const EMOJI_NAME_AND_SKIN_TONE_RE = /^:([^\s:]+?(?:::skin-tone-\d)?):/

export const EMOJI_SURROGATE_RE = new RegExp(
  `(${Array.from(surrogateToName.keys())
    .sort((a, b) => b.length - a.length)
    .map(RegexUtils.escapeRegex)
    .join("|")})`,
  "g",
)

export const translateInlineEmojiToSurrogates = (content: string) =>
  content.replace(EMOJI_NAME_AND_SKIN_TONE_RE, (original, emoji) => convertNameToSurrogate(emoji, original))

export const translateSurrogatesToInlineEmoji = (content: string) =>
  content.replace(EMOJI_SURROGATE_RE, (_, surrogate) => convertSurrogateToName(surrogate))

export const convertNameToSurrogate = (emojiName: string, defaultSurrogate = "") =>
  nameToSurrogate.get(emojiName) ?? defaultSurrogate

export const convertSurrogateToName = (surrogate: string, includeColons = true, defaultName = "") => {
  const name = surrogateToName.get(surrogate) ?? defaultName
  return includeColons ? `:${name}:` : name
}

export const getCategoryIcon = (category: string) => {
  switch (category) {
    case "people":
      return SmileyIcon
    case "nature":
      return NatureIcon
    case "food":
      return FoodIcon
    case "activity":
      return ActivityIcon
    case "travel":
      return TravelIcon
    case "objects":
      return MagnetIcon
    case "symbols":
      return LoveIcon
    case "flags":
      return FlagIcon
    default:
      return SmileyIcon
  }
}

export const getCategoryLabel = (category: string) => {
  switch (category) {
    case "people":
      return i18n.Messages.EMOJI_CATEGORY_PEOPLE
    case "nature":
      return i18n.Messages.EMOJI_CATEGORY_NATURE
    case "food":
      return i18n.Messages.EMOJI_CATEGORY_FOOD
    case "activity":
      return i18n.Messages.EMOJI_CATEGORY_ACTIVITY
    case "travel":
      return i18n.Messages.EMOJI_CATEGORY_TRAVEL
    case "objects":
      return i18n.Messages.EMOJI_CATEGORY_OBJECTS
    case "symbols":
      return i18n.Messages.EMOJI_CATEGORY_SYMBOLS
    case "flags":
      return i18n.Messages.EMOJI_CATEGORY_FLAGS
    default:
      throw new Error(`Unknown category: ${category}`)
  }
}

export const getCategoryForEmoji = (emoji: UnicodeEmoji) => {
  for (const [category, emojisList] of emojisByCategory) {
    if (emojisList.includes(emoji)) {
      return category
    }
  }
  return null
}

export const forEachEmoji = (callback: (emoji: UnicodeEmoji) => void) => emojis.forEach(callback)

export const getAllEmojis = () => emojis

export const getCategories = () => Array.from(emojisByCategory.keys())

export const findInlineEmojisFromSurrogates = (content: string) => {
  const tokens = []
  let currentIndex = 0
  const codePoints = Array.from(content)

  while (currentIndex < codePoints.length) {
    let matchFound = false

    for (let length = Math.min(4, codePoints.length - currentIndex); length >= 1; length--) {
      const substr = codePoints.slice(currentIndex, currentIndex + length).join("")
      if (surrogateToName.has(substr)) {
        tokens.push({
          type: "emoji",
          text: substr,
          surrogate: substr,
          emojiName: convertSurrogateToName(substr),
        })
        currentIndex += length
        matchFound = true
        break
      }
    }

    if (!matchFound) {
      const char = codePoints[currentIndex]
      tokens.push({type: "text", text: char})
      currentIndex += 1
    }
  }

  return tokens
}
