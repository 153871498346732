import {type UserPartial, UserRecord} from "~/records/UserRecord"

export type BetaCode = {
  code: string
  created_at: number
  redeemed_at: number | null
  redeemer: UserPartial | null
}

export class BetaCodeRecord {
  code: string
  createdAt: number
  redeemedAt: number | null
  redeemer: UserRecord | null

  constructor(data: BetaCode) {
    this.code = data.code
    this.createdAt = data.created_at
    this.redeemedAt = data.redeemed_at
    this.redeemer = data.redeemer ? new UserRecord(data.redeemer) : null
  }
}
