import {UserRolePermissions, type UserType} from "~/Constants"
import * as SnowflakeUtils from "~/utils/SnowflakeUtils"

export type BackupCode = {
  code: string
  consumed: boolean
}

export type UserPartial = {
  id: string
  username: string
  handle: string
  nickname: string | null
  pronouns: string | null
  avatar: string | null
  type: UserType
}

export type UserProfile = {
  biography: string | null
  location: string | null
}

export type UserPrivate = UserPartial &
  UserProfile & {
    email: string
    biography: string | null
    timezone: string | null
    location: string | null
    mfa_enabled: boolean
    permissions: string
  }

export type User = UserPartial & Partial<UserPrivate>

export class UserRecord {
  id: string
  username: string
  handle: string
  nickname: string | null
  pronouns: string | null
  avatar: string | null
  type: UserType
  email?: string
  biography?: string | null
  timezone?: string | null
  location?: string | null
  mfaEnabled?: boolean
  permissions?: bigint

  constructor(user: User) {
    this.id = user.id
    this.username = user.username
    this.handle = user.handle
    this.nickname = user.nickname
    this.pronouns = user.pronouns
    this.avatar = user.avatar
    this.type = user.type
    this.email = user.email
    this.biography = user.biography
    this.timezone = user.timezone
    this.location = user.location
    this.mfaEnabled = user.mfa_enabled
    this.permissions = user.permissions != null ? BigInt(user.permissions) : undefined
  }

  toJSON(): User {
    return {
      id: this.id,
      username: this.username,
      handle: this.handle,
      nickname: this.nickname,
      pronouns: this.pronouns,
      avatar: this.avatar,
      type: this.type,
      email: this.email,
      biography: this.biography,
      timezone: this.timezone,
      location: this.location,
      mfa_enabled: this.mfaEnabled,
      permissions: this.permissions?.toString(),
    }
  }

  get createdAt(): number {
    return SnowflakeUtils.extractTimestamp(this.id)
  }

  get displayName(): string {
    return this.nickname ?? this.username
  }

  get isInstanceAdmin(): boolean {
    return this.permissions != null && (this.permissions & UserRolePermissions.ADMINISTRATOR) !== 0n
  }
}
