import React from "react"
import * as PopoutActionCreators from "~/actions/PopoutActionCreators"
import type {PopoutProps, PopoutState} from "~/components/uikit/Popout"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import {useMergeRefs} from "~/hooks/useMergeRefs"
import {ComponentDispatch} from "~/lib/ComponentDispatch"
import PopoutStore from "~/stores/PopoutStore"

let currentId = 1

export const openPopout = (
  target: HTMLElement,
  props: Partial<PopoutProps> = {},
  key: string | number | null = null,
  clickPos = 0,
) => {
  PopoutActionCreators.open({
    key: key || currentId++,
    dependsOn: props.dependsOn,
    position: props.position,
    render: props.render,
    target,
    zIndexBoost: props.zIndexBoost,
    shouldAutoUpdate: props.shouldAutoUpdate,
    offsetMainAxis: props.offsetMainAxis,
    offsetCrossAxis: props.offsetCrossAxis,
    animationType: props.animationType,
    clickPos,
    containerClass: props.containerClass,
    preventInvert: props.preventInvert,
    onOpen: props.onOpen,
    onClose: props.onClose,
    onCloseRequest: props.onCloseRequest,
    returnFocusRef: props.returnFocusRef,
  })
}

export const Popout = React.forwardRef<HTMLElement, PopoutProps>((props, ref) => {
  const [state, setState] = React.useState<PopoutState>({
    id: props.uniqueId || currentId++,
    open: PopoutStore.isOpen(props.uniqueId || currentId),
  })
  const [hoveringTarget, setHoveringTarget] = React.useState(false)
  const [hoveringPopout, setHoveringPopout] = React.useState(false)

  const targetRef = React.useRef<HTMLElement | null>(null)
  const popoutRef = React.useRef<HTMLDivElement | null>(null)
  const mergedRef = useMergeRefs([ref, targetRef])
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)

  React.useEffect(() => {
    const handleStoreChange = () => {
      setState((prevState) => ({
        ...prevState,
        open: PopoutStore.isOpen(state.id),
      }))
    }

    PopoutStore.addChangeListener(handleStoreChange)
    return () => {
      PopoutStore.removeChangeListener(handleStoreChange)
    }
  }, [state.id])

  React.useEffect(() => {
    if (props.position && state.open) {
      open()
    } else if (!state.open) {
      close()
    }
  }, [props.position, state.open])

  React.useEffect(() => {
    if (props.subscribeTo) {
      ComponentDispatch.subscribe(props.subscribeTo, toggle)
    }

    return () => {
      close()
      if (props.subscribeTo) {
        ComponentDispatch.unsubscribe(props.subscribeTo, toggle)
      }
    }
  }, [props.subscribeTo])

  const close = (event?: Event) => {
    clearTimer()
    if (props.onCloseRequest && !props.onCloseRequest(event)) {
      return
    }
    if (state.open) {
      PopoutActionCreators.close(state.id)
    }
    ComponentDispatch.unsubscribe("POPOUT_CLOSE", close)
    props.onClose?.()

    if (props.returnFocusRef?.current) {
      props.returnFocusRef.current.focus()
    }
  }

  const open = (clickPos?: number) => {
    if (targetRef.current) {
      openPopout(targetRef.current, props, state.id, clickPos)
      ComponentDispatch.subscribe("POPOUT_CLOSE", close)
      props.onOpen?.()
    }
  }

  const toggle = (clickPos: number) => {
    if (state.open && props.toggleClose) {
      close()
    } else if (!state.open) {
      open(clickPos)
    }
  }

  const showDelayedPopout = () => {
    if (!timerRef.current) {
      timerRef.current = setTimeout(() => open(), props.hoverDelay || 0)
    }
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }

  const handleMouseEnterTarget = () => {
    if (props.hoverDelay != null) {
      setHoveringTarget(true)
      showDelayedPopout()
    }
  }

  const handleMouseLeaveTarget = () => {
    if (props.hoverDelay != null) {
      setHoveringTarget(false)
      setTimeout(() => {
        if (!hoveringPopout) {
          close()
        }
      }, 1000)
    }
  }

  const handleMouseEnterPopout = () => {
    if (props.hoverDelay != null) {
      setHoveringPopout(true)
    }
  }

  const handleMouseLeavePopout = () => {
    if (props.hoverDelay != null) {
      setHoveringPopout(false)
      setTimeout(() => {
        if (!hoveringTarget) {
          close()
        }
      }, 1000)
    }
  }

  if (props.children) {
    let child = React.Children.only(props.children) as React.ReactElement
    const {onClick, onMouseOver, onMouseOut, className} = child.props
    child = React.cloneElement(child, {
      className,
      "aria-describedby": state.id,
      "aria-expanded": state.open ? "true" : "false",
      "aria-controls": state.open ? state.id : undefined,
      "aria-haspopup": "true",
      "aria-label": props.tooltip,
      "aria-labelledby": typeof props.tooltip === "string" ? undefined : props.tooltip ? state.id : undefined,
      "aria-hidden": props.tooltip ? (!state.open).toString() : undefined,
      "aria-pressed": state.open ? "true" : "false",
      onClick: (event: React.MouseEvent) => {
        const clickPos = event.pageX - event.currentTarget.getBoundingClientRect().left
        event.preventDefault()
        event.stopPropagation()
        toggle(clickPos)
        onClick?.(event)
      },
      onMouseEnter: (event: React.MouseEvent) => {
        handleMouseEnterTarget()
        onMouseOver?.(event)
      },
      onMouseLeave: (event: React.MouseEvent) => {
        handleMouseLeaveTarget()
        onMouseOut?.(event)
      },
      ref: mergedRef,
    })
    if (props.tooltip) {
      return (
        <Tooltip text={props.tooltip} position={props.tooltipPosition} align={props.tooltipAlign}>
          <span>{child}</span>
        </Tooltip>
      )
    }
    return child
  }

  return (
    state.open && (
      <div
        ref={popoutRef}
        onMouseEnter={handleMouseEnterPopout}
        onMouseLeave={handleMouseLeavePopout}
        style={{position: "absolute"}}
      >
        {props.render?.(state.id)}
      </div>
    )
  )
})
