import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import type {FavoriteGif, UserSettings} from "~/stores/UserSettingsStore"
import UserSettingsStore from "~/stores/UserSettingsStore"

export const update = async (settings: Partial<UserSettings>) => {
  const previousSettings = UserSettingsStore.getState()
  Dispatcher.dispatch({
    type: "USER_SETTINGS_UPDATE",
    userSettings: {...previousSettings, ...settings},
  })
  try {
    await HttpClient.patch({url: Endpoints.USER_SETTINGS, body: settings})
  } catch (error) {
    Dispatcher.dispatch({
      type: "USER_SETTINGS_UPDATE",
      userSettings: previousSettings,
    })
    throw error
  }
}

export const addFavoriteGif = async (gif: FavoriteGif) => {
  const previousSettings = UserSettingsStore.getState()
  const favoriteGifsSet = new Set(previousSettings.favorite_gifs.map((g) => g.url))
  if (!favoriteGifsSet.has(gif.url)) {
    previousSettings.favorite_gifs.unshift(gif)
    await update({favorite_gifs: previousSettings.favorite_gifs})
  }
}

export const removeFavoriteGif = async (url: string) => {
  const previousSettings = UserSettingsStore.getState()
  const favoriteGifsSet = new Set(previousSettings.favorite_gifs.map((g) => g.url))
  if (favoriteGifsSet.has(url)) {
    previousSettings.favorite_gifs = previousSettings.favorite_gifs.filter((g) => g.url !== url)
    await update({favorite_gifs: previousSettings.favorite_gifs})
  }
}
