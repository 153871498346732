import {Endpoints} from "~/Endpoints"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import * as HttpClient from "~/lib/HttpClient"

export type UploadAttachment = {
  id: number
  channelId: string
  file: File
  filename: string
  description?: string
  spoiler: boolean
  previewURL: string | null
  status: "pending" | "uploading" | "completed" | "failed"
  uploadURL?: string
  uploadFilename?: string
  width: number
  height: number
}

type State = {
  uploadAttachments: Record<string, Array<UploadAttachment>>
}

const initialState: State = {
  uploadAttachments: {},
}

class UploadAttachmentStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "UPLOAD_ATTACHMENT_CREATE":
        return this.handleUploadAttachmentCreate(action)
      case "UPLOAD_ATTACHMENT_UPDATE":
        return this.handleUploadAttachmentUpdate(action)
      case "UPLOAD_ATTACHMENT_DELETE":
        return this.handleUploadAttachmentDelete(action)
      case "UPLOAD_ATTACHMENT_REPLACE":
        return this.handleUploadAttachmentReplace(action)
      case "UPLOAD_ATTACHMENT_START_UPLOAD":
        return this.handleUploadAttachmentStartUpload(action)
      case "UPLOAD_ATTACHMENT_UPLOAD_COMPLETE":
        return this.handleUploadAttachmentUploadComplete(action)
      case "UPLOAD_ATTACHMENT_UPLOAD_FAILED":
        return this.handleUploadAttachmentUploadFailed(action)
      default:
        return false
    }
  }

  getUploadAttachments(channelId: string): Array<UploadAttachment> {
    return this.state.uploadAttachments[channelId] || []
  }

  useUploadAttachments(channelId: string): Array<UploadAttachment> {
    const {uploadAttachments: attachments} = this.useStore()
    return attachments[channelId] || []
  }

  private handleUploadAttachmentCreate({
    channelId,
    attachments,
  }: {
    channelId: string
    attachments: Array<UploadAttachment>
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const existingAttachments = uploadAttachments[channelId] || []
      uploadAttachments[channelId] = [...existingAttachments, ...attachments]
      return {uploadAttachments}
    })
  }

  private handleUploadAttachmentUpdate({
    channelId,
    attachmentId,
    patch,
  }: {
    channelId: string
    attachmentId: number
    patch: Partial<UploadAttachment>
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const attachments = [...(uploadAttachments[channelId] || [])]
      const attachmentIndex = attachments.findIndex((attachment) => attachment.id === attachmentId)
      if (attachmentIndex === -1) {
        return prevState
      }
      const attachment = attachments[attachmentIndex]
      attachments[attachmentIndex] = {...attachment, ...patch}
      uploadAttachments[channelId] = attachments
      return {uploadAttachments}
    })
  }

  private handleUploadAttachmentDelete({
    channelId,
    attachmentId,
  }: {
    channelId: string
    attachmentId: number
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const attachments = [...(uploadAttachments[channelId] || [])]
      const attachmentIndex = attachments.findIndex((attachment) => attachment.id === attachmentId)
      if (attachmentIndex === -1) {
        return prevState
      }
      const attachment = attachments[attachmentIndex]
      if (attachment.uploadFilename) {
        HttpClient.del({url: Endpoints.ATTACHMENT(attachment.uploadFilename)})
      }
      attachments.splice(attachmentIndex, 1)
      uploadAttachments[channelId] = attachments
      return {uploadAttachments}
    })
  }

  private handleUploadAttachmentReplace({
    channelId,
    attachments,
  }: {
    channelId: string
    attachments: Array<UploadAttachment>
  }) {
    this.setState((prevState) => ({
      uploadAttachments: {
        ...prevState.uploadAttachments,
        [channelId]: attachments,
      },
    }))
  }

  private handleUploadAttachmentStartUpload({
    channelId,
    attachmentId,
  }: {
    channelId: string
    attachmentId: number
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const attachments = [...(uploadAttachments[channelId] || [])]
      const attachmentIndex = attachments.findIndex((attachment) => attachment.id === attachmentId)
      if (attachmentIndex === -1) {
        return prevState
      }
      attachments[attachmentIndex].status = "uploading"
      uploadAttachments[channelId] = attachments
      return {uploadAttachments}
    })
  }

  private handleUploadAttachmentUploadComplete({
    channelId,
    attachmentId,
    uploadURL,
    uploadFilename,
  }: {
    channelId: string
    attachmentId: number
    uploadURL: string
    uploadFilename: string
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const attachments = [...(uploadAttachments[channelId] || [])]
      const attachmentIndex = attachments.findIndex((attachment) => attachment.id === attachmentId)
      if (attachmentIndex === -1) {
        return prevState
      }
      attachments[attachmentIndex].status = "completed"
      attachments[attachmentIndex].uploadURL = uploadURL
      attachments[attachmentIndex].uploadFilename = uploadFilename
      uploadAttachments[channelId] = attachments
      return {uploadAttachments}
    })
  }

  private handleUploadAttachmentUploadFailed({
    channelId,
    attachmentId,
  }: {
    channelId: string
    attachmentId: number
  }) {
    this.setState((prevState) => {
      const uploadAttachments = {...prevState.uploadAttachments}
      const attachments = [...(uploadAttachments[channelId] || [])]
      const attachmentIndex = attachments.findIndex((attachment) => attachment.id === attachmentId)
      if (attachmentIndex === -1) {
        return prevState
      }
      attachments[attachmentIndex].status = "failed"
      uploadAttachments[channelId] = attachments
      return {uploadAttachments}
    })
  }
}

export default new UploadAttachmentStore()
