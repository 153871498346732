import {type StatusType, StatusTypes} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {SpaceReadyData} from "~/records/SpaceRecord"
import type {UserPartial, UserPrivate} from "~/records/UserRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import LocalPresenceStore from "~/stores/LocalPresenceStore"

export type Presence = {
  space_id: string
  user: UserPartial
  status: StatusType
}

type PartialPresence = Pick<Presence, "status"> & {
  timestamp: number
}

type State = {
  presencesForSpaces: Record<string, Record<string, PartialPresence>>
  statuses: Record<string, StatusType>
}

const initialState: State = {
  presencesForSpaces: {},
  statuses: {},
}

class PresenceStore extends Store<State> {
  constructor() {
    super(initialState)
    this.syncWith([LocalPresenceStore], () => {
      const userId = AuthenticationStore.getId()
      this.setState((prevState) => {
        const newStatuses = {...prevState.statuses}
        newStatuses[userId] = LocalPresenceStore.getStatus()
        return {
          ...prevState,
          statuses: newStatuses,
        }
      })
    })
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "PRESENCE_UPDATE":
        return this.handlePresenceUpdate(action.presence)
      case "SPACE_CREATE":
        return this.handleSpaceCreate(action.space)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      default:
        return false
    }
  }

  getStatus(userId: string): StatusType {
    return this.state.statuses[userId] ?? StatusTypes.OFFLINE
  }

  getPresenceCount(spaceId: string): number {
    return (
      (LocalPresenceStore.getStatus() === StatusTypes.OFFLINE ? 0 : 1) +
      Object.values(this.state.presencesForSpaces).filter(
        (presences) => presences[spaceId] != null && presences[spaceId].status !== StatusTypes.OFFLINE,
      ).length
    )
  }

  useUserStatus(userId: string, defaultStatus: StatusType = StatusTypes.OFFLINE): StatusType {
    const {statuses} = this.useStore()
    return statuses[userId] ?? defaultStatus
  }

  private handleConnectionOpen({user, spaces}: {user: UserPrivate; spaces: Array<SpaceReadyData>}) {
    this.setState(() => ({
      presencesForSpaces: {},
      statuses: {[user.id]: LocalPresenceStore.getStatus()},
    }))
    for (const space of spaces) {
      if (!space.unavailable) {
        this.handleSpaceCreate(space)
      }
    }
  }

  private handleSpaceCreate(space: SpaceReadyData) {
    if (space.unavailable) {
      return
    }
    this.setState((prevState) => {
      const newPresencesForSpaces = {...prevState.presencesForSpaces}
      delete newPresencesForSpaces[space.id]
      return {
        ...prevState,
        presencesForSpaces: newPresencesForSpaces,
      }
    })
    for (const presence of space.presences) {
      this.handlePresenceUpdate({...presence, space_id: space.id})
    }
  }

  private handleSpaceDelete({spaceId}: {spaceId: string}) {
    this.setState((prevState) => {
      const newPresencesForSpaces = {...prevState.presencesForSpaces}
      for (const userId in newPresencesForSpaces) {
        if (newPresencesForSpaces[userId]) {
          delete newPresencesForSpaces[userId][spaceId]
          if (Object.keys(newPresencesForSpaces[userId]).length === 0) {
            delete newPresencesForSpaces[userId]
          }
        }
      }
      return {
        ...prevState,
        presencesForSpaces: newPresencesForSpaces,
      }
    })
    for (const userId of Object.keys(this.state.presencesForSpaces)) {
      this.mergePresence(userId)
    }
  }

  private handlePresenceUpdate(presence: Presence) {
    const {space_id: spaceId, user, status} = presence
    if (user.id === AuthenticationStore.getId()) {
      return
    }
    this.setState((prevState) => {
      const newPresencesForSpaces = {...prevState.presencesForSpaces}
      let presencesForUser = newPresencesForSpaces[user.id]

      if (presencesForUser) {
        presencesForUser[spaceId] = {status, timestamp: Date.now()}
      } else {
        if (status === StatusTypes.OFFLINE) {
          return prevState
        }
        presencesForUser = {[spaceId]: {status, timestamp: Date.now()}}
        newPresencesForSpaces[user.id] = presencesForUser
      }
      return {
        ...prevState,
        presencesForSpaces: newPresencesForSpaces,
      }
    })
    this.flattenPresence(user.id)
  }

  private flattenPresence(userId: string) {
    this.setState((prevState) => {
      const newStatuses = {...prevState.statuses}
      const presencesForUser = prevState.presencesForSpaces[userId]

      if (presencesForUser) {
        const presenceArray = Object.values(presencesForUser)
        const latestPresence = presenceArray[0]

        if (!latestPresence) {
          return prevState
        }

        if (latestPresence.status === StatusTypes.OFFLINE) {
          if (presenceArray.every((presence) => presence.status === StatusTypes.OFFLINE)) {
            delete newStatuses[userId]
            const newPresencesForSpaces = {...prevState.presencesForSpaces}
            delete newPresencesForSpaces[userId]
            return {
              ...prevState,
              presencesForSpaces: newPresencesForSpaces,
              statuses: newStatuses,
            }
          }
        } else {
          newStatuses[userId] = latestPresence.status
        }
      }
      return {
        ...prevState,
        statuses: newStatuses,
      }
    })
  }

  private mergePresence(userId: string) {
    this.setState((prevState) => {
      const newStatuses = {...prevState.statuses}
      const presencesForUser = prevState.presencesForSpaces[userId]

      if (presencesForUser) {
        const latestPresence = Object.values(presencesForUser).reduce((a, b) => (a.timestamp > b.timestamp ? a : b))
        if (latestPresence.status !== StatusTypes.OFFLINE) {
          newStatuses[userId] = latestPresence.status
        }
      }

      return {
        ...prevState,
        statuses: newStatuses,
      }
    })
  }
}

export default new PresenceStore()
