import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {LruCache} from "~/lib/LruCache"
import type {Channel} from "~/records/ChannelRecord"
import type {Space} from "~/records/SpaceRecord"
import type {UserPartial} from "~/records/UserRecord"

export type Invite = {
  code: string
  space: Space
  channel: Channel
  inviter?: UserPartial
  member_count: number
  presence_count: number
  expires_at: number | null
  uses?: number
  max_uses?: number
  max_age?: number
}

type State = {
  invites: LruCache<string, Invite>
}

const initialState: State = {
  invites: new LruCache(100),
}

class InviteStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "INVITE_CREATE":
        return this.handleInviteCreate(action)
      default:
        return false
    }
  }

  getInvite(code: string) {
    return this.state.invites.get(code) ?? null
  }

  private handleInviteCreate({invite}: {invite: Invite}) {
    this.setState((prevState) => {
      prevState.invites.set(invite.code, invite)
      return {invites: prevState.invites}
    })
  }
}

export default new InviteStore()
