import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"

export type DeveloperOptionsState = {
  bypassSplashScreen: boolean
  debugLoggingEnabled: boolean
  forceFailUploads: boolean
  forceRenderPlaceholders: boolean
  forceUpdateReady: boolean
  showMyselfTyping: boolean
  slowAttachmentUpload: boolean
  slowMessageLoad: boolean
  slowMessageSend: boolean
  slowProfileLoad: boolean
}

const initialState: DeveloperOptionsState = {
  bypassSplashScreen: false,
  debugLoggingEnabled: false,
  forceFailUploads: false,
  forceRenderPlaceholders: false,
  forceUpdateReady: false,
  showMyselfTyping: false,
  slowAttachmentUpload: false,
  slowMessageLoad: false,
  slowMessageSend: false,
  slowProfileLoad: false,
}

class DeveloperOptionsStore extends PersistedStore<DeveloperOptionsState> {
  constructor() {
    super(initialState, "DeveloperOptionsStore", 1)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "DEVELOPER_OPTIONS_UPDATE":
        return this.handleDeveloperOptionsUpdate(action)
      default:
        return false
    }
  }

  getBypassSplashScreen(): boolean {
    return this.state.bypassSplashScreen
  }

  getDebugLoggingEnabled(): boolean {
    return this.state.debugLoggingEnabled
  }

  getForceFailUploads(): boolean {
    return this.state.forceFailUploads
  }

  getForceRenderPlaceholders(): boolean {
    return this.state.forceRenderPlaceholders
  }

  getForceUpdateReady(): boolean {
    return this.state.forceUpdateReady
  }

  getShowMyselfTyping(): boolean {
    return this.state.showMyselfTyping
  }

  getSlowAttachmentUpload(): boolean {
    return this.state.slowAttachmentUpload
  }

  getSlowMessageLoad(): boolean {
    return this.state.slowMessageLoad
  }

  getSlowMessageSend(): boolean {
    return this.state.slowMessageSend
  }

  getSlowProfileLoad(): boolean {
    return this.state.slowProfileLoad
  }

  private handleDeveloperOptionsUpdate({key, value}: {key: keyof DeveloperOptionsState; value: boolean}) {
    this.setState((prevState) => {
      prevState[key] = value
      return {...prevState}
    })
  }
}

export default new DeveloperOptionsStore()
