import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type SpaceMember, SpaceMemberRecord} from "~/records/SpaceMemberRecord"
import type {SpaceReadyData} from "~/records/SpaceRecord"

type Members = Record<string, SpaceMemberRecord>

type State = {
  members: Record<string, Members>
}

const initialState: State = {
  members: {},
}

class SpaceMemberStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
        return this.handleSpaceCreate(action.space)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "SPACE_MEMBER_ADD":
      case "SPACE_MEMBER_UPDATE":
        return this.handleMemberAdd(action)
      case "SPACE_MEMBER_REMOVE":
        return this.handleMemberRemove(action)
      case "SPACE_ROLE_DELETE":
        return this.handleSpaceRoleDelete(action)
      default:
        return false
    }
  }

  getMember(spaceId: string, userId: string): SpaceMemberRecord | null {
    return this.state.members[spaceId]?.[userId] || null
  }

  getMembers(spaceId: string): Array<SpaceMemberRecord> {
    return Object.values(this.state.members[spaceId] || {})
  }

  getMemberCount(spaceId: string): number {
    return Object.keys(this.state.members[spaceId] || {}).length
  }

  useMembers(spaceId: string): Array<SpaceMemberRecord> {
    const {members} = this.useStore()
    return Object.values(members[spaceId] || {})
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}) {
    this.setState(initialState)
    for (const space of spaces) {
      this.handleSpaceCreate(space)
    }
  }

  private handleSpaceCreate(space: SpaceReadyData) {
    if (space.unavailable) {
      return
    }
    this.setState((prevState) => {
      const members = {...prevState.members}
      const spaceMembers: Members = {}
      for (const member of space.members) {
        spaceMembers[member.user.id] = new SpaceMemberRecord(space.id, member)
      }
      members[space.id] = spaceMembers
      return {members}
    })
  }

  private handleSpaceDelete({spaceId}: {spaceId: string}) {
    this.setState((prevState) => {
      const members = {...prevState.members}
      delete members[spaceId]
      return {members}
    })
  }

  private handleMemberAdd({spaceId, member}: {spaceId: string; member: SpaceMember}) {
    this.setState((prevState) => {
      const members = {...prevState.members}
      const spaceMembers = members[spaceId] || {}
      spaceMembers[member.user.id] = new SpaceMemberRecord(spaceId, member)
      members[spaceId] = spaceMembers
      return {members}
    })
  }

  private handleMemberRemove({spaceId, userId}: {spaceId: string; userId: string}) {
    this.setState((prevState) => {
      const members = {...prevState.members}
      const spaceMembers = members[spaceId]
      if (spaceMembers) {
        delete spaceMembers[userId]
        if (Object.keys(spaceMembers).length === 0) {
          delete members[spaceId]
        } else {
          members[spaceId] = spaceMembers
        }
      }
      return {members}
    })
  }

  private handleSpaceRoleDelete({spaceId, roleId}: {spaceId: string; roleId: string}) {
    this.setState((prevState) => {
      const members = {...prevState.members}
      const spaceMembers = members[spaceId]
      if (spaceMembers) {
        for (const memberId in spaceMembers) {
          const member = spaceMembers[memberId]
          if (member.roles.has(roleId)) {
            member.roles.delete(roleId)
            spaceMembers[memberId] = member
          }
        }
      }
      return {members}
    })
  }
}

export default new SpaceMemberStore()
