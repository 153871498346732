import clsx from "clsx"
import {AnimatePresence, motion} from "motion/react"
import React from "react"
import * as ReactionActionCreators from "~/actions/ReactionActionCreators"
import styles from "~/components/channel/MessageReactions.module.css"
import {ReactionTooltipPopout} from "~/components/popouts/ReactionTooltip"
import {Popout} from "~/components/uikit/Popout/Popout"
import {useHover} from "~/hooks/useHover"
import type {MessageReaction, MessageRecord} from "~/records/MessageRecord"
import markupStyles from "~/styles/Markup.module.css"
import {getEmojiName, getReactionKey, useEmojiURL} from "~/utils/ReactionUtils"

const MessageReactionItem = ({message, reaction}: {message: MessageRecord; reaction: MessageReaction}) => {
  const [hoverRef, isHovering] = useHover()
  const [isOpen, setIsOpen] = React.useState(false)
  const [prevCount, setPrevCount] = React.useState(reaction.count)

  React.useEffect(() => {
    if (prevCount !== reaction.count) {
      setPrevCount(reaction.count)
    }
  }, [reaction.count, prevCount])

  const handleClick = () => {
    if (reaction.me) {
      ReactionActionCreators.removeReaction(message.channelId, message.id, reaction.emoji)
    } else {
      ReactionActionCreators.addReaction(message.channelId, message.id, reaction.emoji)
    }
  }

  const emojiName = getEmojiName(reaction.emoji)
  const emojiUrl = useEmojiURL({emoji: reaction.emoji, isHovering})

  const variants = {
    up: {y: -20, opacity: 0},
    down: {y: 20, opacity: 0},
    center: {y: 0, opacity: 1},
  }

  return (
    <div className={clsx(styles.reaction, reaction.me && styles.reactionMe)} ref={hoverRef}>
      <Popout
        uniqueId={`reaction-tooltip-${getReactionKey(message.id, reaction.emoji)}`}
        render={() => <ReactionTooltipPopout message={message} reaction={reaction} />}
        position="top"
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        hoverDelay={500}
      >
        <div
          className={styles.reactionInner}
          aria-expanded={isOpen}
          aria-disabled={false}
          aria-label={`${emojiName}: ${reaction.count} reaction${reaction.count === 1 ? "" : "s"}, ${reaction.me ? "press to remove" : "press to add"} reaction`}
          aria-pressed={reaction.me}
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={(event) => event.key === "Enter" && handleClick()}
        >
          <img src={emojiUrl} alt={emojiName} draggable={false} className={clsx(markupStyles.emoji, styles.emoji)} />
          <div className={styles.reactionCount} style={{position: "relative", height: "20px", overflow: "hidden"}}>
            <AnimatePresence initial={false}>
              <motion.div
                key={reaction.count}
                initial={reaction.count > prevCount ? "up" : "down"}
                animate="center"
                exit={reaction.count > prevCount ? "down" : "up"}
                variants={variants}
                transition={{duration: 0.2}}
                style={{position: "absolute", width: "100%"}}
              >
                {reaction.count}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </Popout>
    </div>
  )
}

export const MessageReactions = ({message}: {message: MessageRecord}) => (
  <div className={styles.reactions}>
    {message.reactions.map((reaction) => (
      <MessageReactionItem key={getReactionKey(message.id, reaction.emoji)} message={message} reaction={reaction} />
    ))}
  </div>
)
