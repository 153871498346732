import type {Action} from "~/flux/ActionTypes"
import Dispatcher from "~/flux/Dispatcher"
import {Store} from "~/flux/Store"

const IDLE_DURATION = 1000 * (window.ENV.NODE_ENV === "development" ? 10 : 60 * 10)

type State = {
  idle: boolean
  idleSince: number
}

const initialState: State = {
  idle: false,
  idleSince: Date.now(),
}

class IdleStore extends Store<State> {
  constructor() {
    super(initialState)
    setInterval(() => this.checkIdle(), IDLE_DURATION * 0.25)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "IDLE":
        return this.handleIdle(action)
      case "WINDOW_FOCUS":
        return this.handleWindowFocus()
      default:
        return false
    }
  }

  isIdle(): boolean {
    return this.state.idle
  }

  getIdleSince(): number {
    return this.state.idle ? this.state.idleSince : 0
  }

  private handleIdle({idle}: {idle: boolean}) {
    this.setState((prevState) => ({...prevState, idle}))
  }

  private handleWindowFocus() {
    this.setState((prevState) => ({...prevState, idleSince: Date.now()}))
    this.checkIdle()
  }

  private checkIdle() {
    if (Date.now() - this.state.idleSince > IDLE_DURATION) {
      if (!this.state.idle) {
        Dispatcher.dispatch({
          type: "IDLE",
          idle: true,
          idleSince: this.state.idleSince,
        })
      }
    } else if (this.state.idle) {
      Dispatcher.dispatch({
        type: "IDLE",
        idle: false,
      })
    }
  }
}

export default new IdleStore()
