import {Endpoints} from "~/Endpoints"
import * as HttpClient from "~/lib/HttpClient"
import type {SpaceMember} from "~/records/SpaceMemberRecord"

export const update = async (spaceId: string, userId: string, params: Partial<SpaceMember>) => {
  await HttpClient.patch({url: Endpoints.SPACE_MEMBER(spaceId, userId), body: params})
}

export const addRole = async (spaceId: string, userId: string, roleId: string) => {
  await HttpClient.put({url: Endpoints.SPACE_MEMBER_ROLE(spaceId, userId, roleId)})
}

export const removeRole = async (spaceId: string, userId: string, roleId: string) => {
  await HttpClient.del({url: Endpoints.SPACE_MEMBER_ROLE(spaceId, userId, roleId)})
}

export const updateMe = async (spaceId: string, params: Partial<SpaceMember>) => {
  await HttpClient.patch({url: Endpoints.SPACE_MEMBER(spaceId), body: params})
}
