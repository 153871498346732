import Dispatcher from "~/flux/Dispatcher"

export const open = (popout: Record<string, any>) => Dispatcher.dispatch({type: "POPOUT_OPEN", popout})

export const close = (key?: string | number) => Dispatcher.dispatch({type: "POPOUT_CLOSE", key})

export const closeAll = () => Dispatcher.dispatch({type: "POPOUT_CLOSE_ALL"})

export const rerender = (key: string | number) => Dispatcher.dispatch({type: "POPOUT_NEEDS_RERENDER", key})

export const didRerender = (key: string | number) => Dispatcher.dispatch({type: "POPOUT_DID_RERENDER", key})
