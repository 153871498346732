import {clsx} from "clsx"
import React from "react"
import {thumbHashToDataURL} from "thumbhash"
import {useHover} from "~/hooks/useHover"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as ImageCacheUtils from "~/utils/ImageCacheUtils"
import * as StringUtils from "~/utils/StringUtils"

export const SpaceIcon = ({
  id,
  name,
  icon,
  className,
}: {
  id: string
  name: string
  icon: string | null
  className?: string
}) => {
  const iconUrl = AvatarUtils.getSpaceIconURL({id, icon})
  const hoverIconUrl = AvatarUtils.getSpaceIconURL({id, icon}, true)
  const parsedAvatar = icon ? AvatarUtils.parseAvatar(icon) : null

  const [hoverRef, isHovering] = useHover()
  const [isStaticLoaded, setIsStaticLoaded] = React.useState(ImageCacheUtils.hasImage(iconUrl))
  const [isAnimatedLoaded, setIsAnimatedLoaded] = React.useState(ImageCacheUtils.hasImage(hoverIconUrl))
  const [shouldPlayAnimated, setShouldPlayAnimated] = React.useState(false)

  React.useEffect(() => {
    ImageCacheUtils.loadImage(iconUrl, () => setIsStaticLoaded(true))
    if (isHovering) {
      ImageCacheUtils.loadImage(hoverIconUrl, () => setIsAnimatedLoaded(true))
    }
  }, [iconUrl, hoverIconUrl, isHovering])

  React.useEffect(() => {
    setShouldPlayAnimated(isHovering && isAnimatedLoaded)
  }, [isHovering, isAnimatedLoaded])

  return (
    <div
      aria-label={name}
      className={clsx(
        className,
        "flex flex-shrink-0 items-center justify-center rounded-full bg-center bg-cover font-medium text-[75%] text-text-tertiary",
        !icon && "bg-background-navbar-surface",
      )}
      key={id}
      ref={hoverRef}
      style={{
        backgroundImage: isStaticLoaded
          ? `url(${shouldPlayAnimated && isAnimatedLoaded ? hoverIconUrl : iconUrl})`
          : parsedAvatar
            ? `url(${thumbHashToDataURL(Uint8Array.from(atob(parsedAvatar.placeholder), (c) => c.charCodeAt(0)))})`
            : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "background-image 0.3s ease-in-out",
      }}
    >
      {!icon && StringUtils.getInitialsFromName(name)}
    </div>
  )
}
