import {clsx} from "clsx"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"
import {ChannelTypes, MessageEmbedTypes, type MessageState, MessageStates, MessageTypes, UserTypes} from "~/Constants"
import * as MessageActionCreators from "~/actions/MessageActionCreators"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import * as ReadStateActionCreators from "~/actions/ReadStateActionCreators"
import {Invite} from "~/components/channel/Invite"
import {MessageActionBar} from "~/components/channel/MessageActionBar"
import {MessageReactions} from "~/components/channel/MessageReactions"
import {UserTag} from "~/components/channel/UserTag"
import {Embed} from "~/components/channel/embeds/Embed"
import {Attachment} from "~/components/channel/embeds/attachments/Attachment"
import {ConfirmModal} from "~/components/modals/ConfirmModal"
import {UserProfilePopout} from "~/components/popouts/UserProfilePopout"
import {Avatar} from "~/components/uikit/Avatar"
import {Popout} from "~/components/uikit/Popout/Popout"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import Dispatcher from "~/flux/Dispatcher"
import {useHover} from "~/hooks/useHover"
import {i18n} from "~/i18n"
import {ArrowRightIcon} from "~/icons/ArrowRightIcon"
import {MiniReplyIcon} from "~/icons/MiniReplyIcon"
import {PinIcon} from "~/icons/PinIcon"
import {ComponentDispatch} from "~/lib/ComponentDispatch"
import type {ChannelRecord} from "~/records/ChannelRecord"
import type {MessageRecord} from "~/records/MessageRecord"
import type {SpaceRecord} from "~/records/SpaceRecord"
import type {UserRecord} from "~/records/UserRecord"
import MessageEditStore from "~/stores/MessageEditStore"
import MessageReplyStore from "~/stores/MessageReplyStore"
import MessageStore from "~/stores/MessageStore"
import ReadStateStore, {type ReadState} from "~/stores/ReadStateStore"
import ReferencedMessageStore from "~/stores/ReferencedMessageStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import UserSettingsStore, {type UserSettings} from "~/stores/UserSettingsStore"
import UserStore from "~/stores/UserStore"
import markupStyles from "~/styles/Markup.module.css"
import styles from "~/styles/Message.module.css"
import * as DateUtils from "~/utils/DateUtils"
import * as MarkupUtils from "~/utils/MarkupUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"
import * as ReadStateUtils from "~/utils/ReadStateUtils"
import {SystemMessageUtils} from "~/utils/SystemMessageUtils"

const MessageStateToClassName: Record<MessageState, string> = {
  [MessageStates.SENT]: styles.messageSent,
  [MessageStates.SENDING]: styles.messageSending,
  [MessageStates.FAILED]: styles.messageFailed,
}

const useMessageAuthor = (author: UserRecord, isWebhook: boolean) => {
  const user = UserStore.useUser(author.id) as UserRecord
  return isWebhook ? author : user
}

const UserMessage = ({
  message,
  channel,
  handleDelete,
  isHovering,
  shouldGroup,
  isPreview,
}: {
  message: MessageRecord
  channel: ChannelRecord
  handleDelete: (bypassConfirm?: boolean) => void
  isHovering: boolean
  shouldGroup: boolean
  isPreview?: boolean
}) => {
  const [animateEmoji, setAnimateEmoji] = React.useState(UserSettingsStore.getAnimateEmoji())
  const [value, setValue] = React.useState("")
  const author = useMessageAuthor(message.author, message.webhookId != null)
  const formattedDate = DateUtils.getRelativeDateString(message.createdAt)
  const isEditing = MessageEditStore.useIsEditing(message.channelId, message.id)
  const referencedMessage = ReferencedMessageStore.useCachedMessage(message.messageReference?.message_id ?? "")
  const space = channel.getSpace()
  const member = SpaceMemberStore.getMember(space.id, author.id)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const shouldHideContent =
    UserSettingsStore.getRenderEmbeds() &&
    message.embeds.length > 0 &&
    message.embeds.every((embed) => embed.type === MessageEmbedTypes.IMAGE || embed.type === MessageEmbedTypes.GIFV) &&
    URL.canParse(message.content) &&
    !message.shouldSuppressEmbeds

  const shouldAppearAuthorless = channel.type === ChannelTypes.SPACE_DOCUMENT && !isPreview

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useLayoutEffect(() => {
    if (isEditing) {
      setValue(message.content)
      textareaRef.current?.focus()
      textareaRef.current?.setSelectionRange(value.length, value.length)
    } else {
      setValue("")
    }
  }, [isEditing])

  const onSubmit = React.useCallback(() => {
    const content = value.trim()
    if (!content) {
      return handleDelete()
    }
    MessageActionCreators.edit(channel.id, message.id, {content}).then(() => {
      Dispatcher.dispatch({type: "MESSAGE_EDIT_STOP", channelId: channel.id})
    })
  }, [channel.id, handleDelete, message.id, value])

  const jumpToRepliedMessage = React.useCallback(() => {
    message.messageReference &&
      ComponentDispatch.dispatch("MESSAGE_JUMP", {messageId: message.messageReference.message_id})
  }, [message.messageReference])

  React.useEffect(() => {
    if (animateEmoji) {
      return
    }

    const emojiImgs = document.querySelectorAll(
      `img[data-message-id="${message.id}"][data-animated="true"]`,
    ) as NodeListOf<HTMLImageElement>

    for (const img of emojiImgs) {
      const src = img.src
      if (isHovering) {
        img.src = src.replace(".webp", ".gif")
      } else {
        img.src = src.replace(".gif", ".webp")
      }
    }
  }, [animateEmoji, isHovering, message.id])

  React.useEffect(() => {
    const handleUserSettingsUpdate = (userSettings: UserSettings) => {
      setAnimateEmoji(userSettings.animate_emoji)
      if (userSettings.animate_emoji) {
        const emojiImgs = document.querySelectorAll(
          `img[data-message-id="${message.id}"][data-animated="true"]`,
        ) as NodeListOf<HTMLImageElement>

        for (const img of emojiImgs) {
          const src = img.src
          img.src = src.replace(".webp", ".gif")
        }
      }
    }
    return UserSettingsStore.subscribe(handleUserSettingsUpdate)
  }, [message.id])

  return (
    <>
      {message.messageReference && (
        <div
          className={clsx(
            "relative flex select-none items-center whitespace-pre text-sm text-text-primary-muted leading-[1.125rem]",
            styles.repliedMessage,
          )}
        >
          {referencedMessage.status === "loaded" ? (
            <Popout
              render={(popoutId) => (
                <UserProfilePopout
                  popoutId={popoutId}
                  user={referencedMessage.message.author}
                  isWebhook={referencedMessage.message.webhookId != null}
                  spaceId={space.id}
                />
              )}
              position="right-start"
            >
              <Avatar
                user={referencedMessage.message.author}
                size={16}
                className="active:translate-z-0 mr-[.25rem] flex-none transform-gpu cursor-pointer active:translate-y-px"
              />
            </Popout>
          ) : (
            <div className="mr-[.25rem] flex h-4 w-4 items-center justify-center rounded-full bg-background-tertiary text-text-primary">
              <MiniReplyIcon className="h-[7.2px] w-[10.8px]" />
            </div>
          )}

          {referencedMessage.status === "loaded" && (
            <Popout
              render={(popoutId) => (
                <UserProfilePopout
                  popoutId={popoutId}
                  user={referencedMessage.message.author}
                  isWebhook={referencedMessage.message.webhookId != null}
                  spaceId={space.id}
                />
              )}
              position="right-start"
            >
              <span
                className="relative mr-[.25rem] inline flex-shrink-0 cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[inherit] opacity-[.64] hover:underline"
                style={{
                  color: SpaceMemberStore.getMember(
                    space.id,
                    referencedMessage.message.author.id,
                  )?.getHighestRoleColor(),
                }}
              >
                {message.mentions.some((mention) => mention.id === referencedMessage.message.author.id) && "@"}
                {NicknameUtils.getNickname(referencedMessage.message.author, space.id)}
              </span>
            </Popout>
          )}

          <div
            className={clsx(
              "flex max-h-[1.25em] flex-initial cursor-default text-text-chat-muted",
              styles.repliedTextPreview,
              referencedMessage.status === "loaded" && "cursor-pointer hover:text-text-chat",
            )}
            role="button"
            tabIndex={0}
            onClick={jumpToRepliedMessage}
            onKeyDown={(event) => event.key === "Enter" && jumpToRepliedMessage()}
          >
            {referencedMessage.status === "loaded" ? (
              referencedMessage.message.content ? (
                <span className={clsx(styles.repliedTextContent, markupStyles.markup, styles.messageContent)}>
                  {MarkupUtils.parseInlineReply(referencedMessage.message.content, undefined, {
                    inReply: true,
                    messageId: referencedMessage.message.id,
                    channelId: channel.id,
                    disableAnimatedEmoji: !animateEmoji,
                  })}
                </span>
              ) : (
                <span className={clsx(styles.repliedTextContent, "pr-[2px] italic")}>
                  {i18n.Messages.MESSAGE_CONTAINS_ATTACHED_MEDIA}
                </span>
              )
            ) : referencedMessage.status === "deleted" ? (
              <span className="pr-[2px] italic">{i18n.Messages.ORIGINAL_MESSAGE_DELETED}</span>
            ) : (
              <span className="pr-[2px] italic">{i18n.Messages.ORIGINAL_MESSAGE_FAILED_TO_LOAD}</span>
            )}
          </div>
        </div>
      )}

      <div className="static ml-0 pl-0 indent-0">
        {!shouldGroup && (
          <>
            <Popout
              render={(popoutId) => (
                <UserProfilePopout
                  popoutId={popoutId}
                  user={author}
                  isWebhook={message.webhookId != null}
                  spaceId={space.id}
                />
              )}
              position="right-start"
            >
              <Avatar
                user={author}
                size={40}
                className="active:translate-z-0 absolute left-4 z-[1] mt-[.125rem] transform-gpu cursor-pointer active:translate-y-px"
                forceAnimate={isHovering}
              />
            </Popout>

            <h3 className="relative min-h-[1.375rem] overflow-hidden whitespace-break-spaces text-text-chat-muted leading-[1.375rem]">
              <span className="mr-1 items-center">
                <Popout
                  render={(popoutId) => (
                    <UserProfilePopout
                      popoutId={popoutId}
                      user={author}
                      isWebhook={message.webhookId != null}
                      spaceId={space.id}
                    />
                  )}
                  position="right-start"
                >
                  <span
                    className="relative inline cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
                    style={{color: member?.getHighestRoleColor()}}
                  >
                    {NicknameUtils.getNickname(author, space.id)}
                  </span>
                </Popout>

                {author.type === UserTypes.AUTOMATED && <UserTag className="mt-[.2em]" />}
              </span>

              <span className="ml-1 inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
                <Tooltip
                  delay={750}
                  text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)}
                  maxWidth="none"
                >
                  <time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
                    <i className="absolute inline-block not-italic opacity-0">{" — "}</i>
                    {formattedDate}
                  </time>
                </Tooltip>

                {author.pronouns && (
                  <div
                    aria-hidden={true}
                    className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
                  />
                )}

                {author.pronouns && (
                  <Tooltip delay={750} text={i18n.Messages.PRONOUNS}>
                    <span>{author.pronouns}</span>
                  </Tooltip>
                )}
              </span>
            </h3>
          </>
        )}

        {!shouldAppearAuthorless && shouldGroup && !isEditing && (
          <span
            className={clsx(
              "absolute left-0 z-[1] h-[1.375rem] w-[56px] select-none text-right text-[.6875rem] text-text-chat-muted leading-[1.375rem]",
              styles.timestampVisibleOnHover,
            )}
          >
            <Tooltip delay={750} text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)} maxWidth="none">
              <time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
                <i className="absolute inline-block not-italic opacity-0">{"["}</i>
                {DateUtils.getFormattedTime(message.createdAt)}
                <i className="absolute inline-block not-italic opacity-0">{"]"}</i>
              </time>
            </Tooltip>
          </span>
        )}

        <div
          className={clsx(
            "relative select-text overflow-hidden whitespace-break-spaces break-words pl-[72px] indent-0 leading-[1.375rem]",
            MessageStateToClassName[message.state],
            shouldAppearAuthorless ? "-ml-[120px]" : "-ml-[72px]",
          )}
        >
          {isEditing && !isPreview ? (
            <div className="relative">
              <TextareaAutosize
                autoFocus={true}
                className="no-scrollbar relative mt-2 flex h-full max-h-[50vh] min-h-[44px] w-full resize-none overflow-x-hidden overflow-y-scroll whitespace-pre-wrap break-words rounded-md bg-background-textarea p-[11px] text-text-chat leading-[1.375rem] caret-text-chat"
                maxLength={channel.type === ChannelTypes.SPACE_DOCUMENT ? undefined : 4000}
                onChange={(event) => setValue(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault()
                    onSubmit()
                  } else if (event.key === "Escape") {
                    event.preventDefault()
                    Dispatcher.dispatch({type: "MESSAGE_EDIT_STOP", channelId: message.channelId})
                  }
                }}
                ref={textareaRef}
                value={value}
              />

              <div className="py-2 text-text-chat text-xs">
                {i18n.format(i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_CANCEL, {
                  cancel: (
                    <button
                      type="button"
                      className="text-text-link hover:underline"
                      key="cancel"
                      onClick={() => Dispatcher.dispatch({type: "MESSAGE_EDIT_STOP", channelId: message.channelId})}
                    >
                      {i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_CANCEL_TEXT}
                    </button>
                  ),
                })}
                <div
                  aria-hidden={true}
                  className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
                />
                {i18n.format(i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_SAVE, {
                  save: (
                    <button type="button" className="text-text-link hover:underline" key="save" onClick={onSubmit}>
                      {i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_SAVE_TEXT}
                    </button>
                  ),
                })}
              </div>
            </div>
          ) : shouldHideContent ? null : (
            <div className={clsx(markupStyles.markup, markupStyles.message)}>
              {message.contentParsed}
              {message.editedAt && (
                <span className="ml-px inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
                  <Tooltip
                    delay={750}
                    text={DateUtils.getFormattedDateTimeWithSeconds(message.editedAt)}
                    maxWidth="none"
                  >
                    <time
                      aria-label={`Edited ${DateUtils.getRelativeDateString(message.editedAt)}`}
                      dateTime={new Date(message.editedAt).toISOString()}
                    >
                      <span className="select-none font-normal text-[.625rem] leading-none">
                        {" "}
                        {i18n.Messages.EDITED}
                      </span>
                    </time>
                  </Tooltip>
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={clsx(styles.container, shouldAppearAuthorless && "-ml-[48px]")}>
        {message.invites.length > 0 && message.invites.map((code) => <Invite code={code} key={code} />)}

        {message.attachments.length > 0 &&
          message.attachments.map((attachment) => (
            <Attachment attachment={attachment} key={attachment.id} isPreview={isPreview} />
          ))}

        {UserSettingsStore.getRenderEmbeds() &&
          !message.shouldSuppressEmbeds &&
          message.embeds.length > 0 &&
          message.embeds.map((embed) => <Embed embed={embed} key={embed.id} message={message} />)}

        {UserSettingsStore.getRenderReactions() && message.reactions.length > 0 && (
          <MessageReactions message={message} />
        )}
      </div>
    </>
  )
}

const SystemMessageUsername = ({author, space}: {author: UserRecord; space: SpaceRecord}) => {
  const member = SpaceMemberStore.getMember(space.id, author.id)
  return (
    <Popout
      render={(popoutId) => (
        <UserProfilePopout popoutId={popoutId} user={author} isWebhook={false} spaceId={space.id} />
      )}
      position="right-start"
    >
      <span className="relative inline cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[1.375rem]">
        <span className="flex items-center gap-1">
          <Avatar user={author} size={16} />
          <span className="hover:underline" style={{color: member?.getHighestRoleColor()}}>
            {NicknameUtils.getNickname(author, space.id)}
          </span>
        </span>
      </span>
    </Popout>
  )
}

const SystemMessage = ({
  icon: Icon,
  iconClassname,
  message,
  messageContent,
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  iconClassname?: string
  message: MessageRecord
  messageContent: React.ReactNode
}) => {
  const formattedDate = DateUtils.getRelativeDateString(message.createdAt)
  return (
    <>
      <div className="static ml-0 pl-0 indent-0">
        <div className="-ml-[72px] relative select-text overflow-hidden whitespace-nowrap break-words pl-[72px] indent-0 text-text-chat leading-[1.375rem]">
          <div className="relative flex items-start py-[.125rem] text-text-primary-muted">
            <div className="absolute right-full flex w-[4.5rem] items-center justify-center pt-[.175rem]">
              <Icon className={clsx("h-[18px] w-[18px]", iconClassname)} />
            </div>

            <div className="-ml-[72px] relative flex select-text flex-wrap items-center overflow-hidden whitespace-break-spaces break-words pl-[72px] indent-0 text-text-chat leading-[1.375rem]">
              {messageContent}

              <span className="ml-1 inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
                <Tooltip
                  delay={750}
                  text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)}
                  maxWidth="none"
                >
                  <time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
                    <i className="absolute inline-block not-italic opacity-0">{" — "}</i>
                    {formattedDate}
                  </time>
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        {UserSettingsStore.getRenderReactions() && message.reactions.length > 0 && (
          <MessageReactions message={message} />
        )}
      </div>
    </>
  )
}

const SpaceJoinMessage = ({message}: {message: MessageRecord}) => {
  const author = UserStore.useUser(message.author.id) as UserRecord
  const i18nMessage = SystemMessageUtils.getSpaceJoinMessage(message.id)
  const messageContent = i18n.format(i18nMessage, {
    username: <SystemMessageUsername author={author} space={message.getChannel().getSpace()} key={author.id} />,
  })
  return (
    <SystemMessage
      icon={ArrowRightIcon}
      iconClassname="text-green-500"
      message={message}
      messageContent={messageContent}
    />
  )
}

const PinSystemMessage = ({message}: {message: MessageRecord}) => {
  const author = UserStore.useUser(message.author.id) as UserRecord

  const jumpToMessage = React.useCallback(() => {
    ComponentDispatch.dispatch("MESSAGE_JUMP", {
      messageId: message.messageReference?.message_id ?? "",
    })
  }, [message.messageReference?.message_id])

  const openPins = React.useCallback(() => {
    ComponentDispatch.dispatch("CHANNEL_PINS_OPEN")
  }, [])

  const messageContent = i18n.format(i18n.Messages.PIN_SYSTEM_MESSAGE, {
    username: <SystemMessageUsername key={author.id} author={author} space={message.getChannel().getSpace()} />,
    message: (
      <span
        key={`pin-${message.id}`}
        className="relative inline cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
        role="button"
        tabIndex={0}
        onClick={jumpToMessage}
        onKeyDown={(event) => event.key === "Enter" && jumpToMessage()}
      >
        {i18n.Messages.PIN_SYSTEM_MESSAGE_1}
      </span>
    ),
    all: (
      <span
        key={`pin-all-${message.id}`}
        className="relative inline cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
        role="button"
        tabIndex={0}
        onClick={openPins}
        onKeyDown={(event) => event.key === "Enter" && openPins()}
      >
        {i18n.Messages.PIN_SYSTEM_MESSAGE_2}
      </span>
    ),
  })

  return <SystemMessage icon={PinIcon} message={message} messageContent={messageContent} />
}

const MESSAGE_GROUP_INTERVAL = 10 * 60 * 1000

const isSameAuthor = (message: MessageRecord, prevMessage: MessageRecord) => {
  if (message.webhookId) {
    const isSameHandle = message.author.handle === prevMessage.author.handle
    const isSameAvatar = message.author.avatar === prevMessage.author.avatar
    return isSameHandle && isSameAvatar
  }
  return message.author.id === prevMessage.author.id
}

const isWithinGroupInterval = (prevMessage: MessageRecord, message: MessageRecord) =>
  prevMessage.createdAt + MESSAGE_GROUP_INTERVAL > message.createdAt

const shouldGroupMessages = (channel: ChannelRecord, message: MessageRecord, prevMessage?: MessageRecord) => {
  if (channel.type === ChannelTypes.SPACE_DOCUMENT) {
    return true
  }

  if (prevMessage != null) {
    const isDefaultMessage = message.type === MessageTypes.DEFAULT
    const isPrevUserMessage = prevMessage.isUserMessage()
    const isSameAuthorCheck = isSameAuthor(message, prevMessage)
    const isWithinInterval = isWithinGroupInterval(prevMessage, message)

    if (isDefaultMessage && isPrevUserMessage && isSameAuthorCheck && isWithinInterval) {
      return true
    }

    const isMessageNonUser = !message.isUserMessage()
    const isPrevNonUser = !prevMessage.isUserMessage()
    const isSameType = message.type === prevMessage.type

    if (isMessageNonUser && isPrevNonUser && isSameType) {
      return true
    }
  }

  return false
}

const isFirstMessageOfDay = (current: MessageRecord, previous?: MessageRecord) => {
  if (!previous) {
    return true
  }

  const currentDate = new Date(current.createdAt)
  const previousDate = new Date(previous.createdAt)

  return (
    currentDate.getDate() !== previousDate.getDate() ||
    currentDate.getMonth() !== previousDate.getMonth() ||
    currentDate.getFullYear() !== previousDate.getFullYear()
  )
}

const handleAltClickEvent = (event: React.MouseEvent, message: MessageRecord, prevMessage?: MessageRecord) => {
  if (event.altKey) {
    const messageId = ReadStateUtils.getPreviousMessageId(message, prevMessage ?? null)
    ReadStateActionCreators.ack({
      channelId: message.channelId,
      messageId,
      mentionCount: MessageStore.getCountBelowMessage(message.channelId, messageId, true),
      manual: true,
    })
  }
}

const handleDeleteMessage = (bypassConfirm: boolean, message: MessageRecord) => {
  if (bypassConfirm) {
    MessageActionCreators.remove(message.channelId, message.id)
  } else {
    ModalActionCreators.push(() => (
      <ConfirmModal
        title={i18n.Messages.DELETE_MESSAGE}
        description={i18n.Messages.DELETE_MESSAGE_CONFIRM_DESCRIPTION}
        message={message}
        primaryText={i18n.Messages.DELETE}
        onPrimary={() => MessageActionCreators.remove(message.channelId, message.id)}
      />
    ))
  }
}

const getMessageComponent = (
  channel: ChannelRecord,
  message: MessageRecord,
  handleDelete: (bypassConfirm?: boolean) => void,
  isHovering: boolean,
  shouldGroup: boolean,
  isPreview?: boolean,
) => {
  switch (message.type) {
    case MessageTypes.USER_JOIN:
      return <SpaceJoinMessage message={message} />
    case MessageTypes.CHANNEL_PINNED_MESSAGE:
      return <PinSystemMessage message={message} />
    default:
      return (
        <UserMessage
          channel={channel}
          message={message}
          handleDelete={handleDelete}
          isHovering={isHovering}
          shouldGroup={shouldGroup}
          isPreview={isPreview}
        />
      )
  }
}

const renderFirstMessageOfDay = (channel: ChannelRecord, message: MessageRecord, prevMessage?: MessageRecord) => {
  if (channel.type !== ChannelTypes.SPACE_DOCUMENT && isFirstMessageOfDay(message, prevMessage)) {
    return (
      <div className="pointer-events-none relative right-auto left-auto z-10 mt-[1.5rem] mr-[.875rem] mb-[.5rem] ml-[1rem] flex h-0 items-center justify-center border-background-header-secondary border-t-[2px]">
        <span className="-mt-px flex-none rounded-md bg-background-chat-primary px-[4px] py-[2px] font-semibold text-text-chat-muted text-xs leading-[13px]">
          {DateUtils.getFormattedDateWithFullMonth(message.createdAt)}
        </span>
      </div>
    )
  }
  return null
}

const renderReadSeparator = (
  message: MessageRecord,
  prevMessage: MessageRecord | null,
  readState: ReadState | null,
  shouldGroup: boolean,
) => {
  if (ReadStateUtils.shouldRenderSeparator(message, prevMessage, readState)) {
    return (
      <div
        className={clsx(
          "pointer-events-none relative z-[1] mr-[.875rem] ml-4 flex h-0 items-center justify-end border-status-danger border-t",
          shouldGroup ? "my-1" : "top-2",
        )}
      >
        <div className="relative flex items-center justify-center rounded-full bg-status-danger px-1 py-0.5 font-medium text-[10px] text-brand-primary-fill uppercase leading-none">
          {i18n.Messages.NEW}
        </div>
      </div>
    )
  }
  return null
}

const renderFirstMessageOfDayWithReadState = (message: MessageRecord) => (
  <div className="pointer-events-none relative right-auto left-auto z-10 mt-[1.5rem] mr-[.875rem] mb-[.5rem] ml-[1rem] flex h-0 items-center border-status-danger border-t-[2px]">
    <div className="-translate-x-1/2 -top-[14px] absolute left-1/2 transform">
      <span className="-mt-px flex-none rounded-md bg-background-chat-primary px-[4px] py-[2px] font-semibold text-status-danger text-xs leading-[13px]">
        {DateUtils.getFormattedDateWithFullMonth(message.createdAt)}
      </span>
    </div>
    <div className="relative ml-auto flex items-center justify-center rounded-full bg-status-danger px-1 py-0.5 font-medium text-[10px] text-brand-primary-fill uppercase leading-none">
      {i18n.Messages.NEW}
    </div>
  </div>
)

export const Message = ({
  channel,
  message,
  prevMessage,
  isPreview,
}: {
  channel: ChannelRecord
  message: MessageRecord
  prevMessage?: MessageRecord
  isPreview?: boolean
}) => {
  const [hoverRef, isHovering] = useHover()
  const isEditing = MessageEditStore.useIsEditing(message.channelId, message.id)
  const isReplying = MessageReplyStore.useIsReplying(message.channelId, message.id)
  const isHighlight = MessageReplyStore.useIsHighlight(message.id)
  const readState = ReadStateStore.useReadState(channel.id)
  const shouldGroup = shouldGroupMessages(channel, message, prevMessage)

  const handleAltClick = React.useCallback(
    (event: React.MouseEvent) => handleAltClickEvent(event, message, prevMessage),
    [message, prevMessage],
  )
  const handleDelete = React.useCallback(
    (bypassConfirm = false) => handleDeleteMessage(bypassConfirm, message),
    [message],
  )
  const renderMessageComponent = React.useCallback(
    () => getMessageComponent(channel, message, handleDelete, isHovering, shouldGroup, isPreview),
    [channel, message, handleDelete, isHovering, shouldGroup, isPreview],
  )

  const baseMessageClass = "relative select-text break-words py-[.125rem] pr-12 pl-[72px]"
  const notPreviewNotGroupedClass = isPreview || shouldGroup ? "" : "mt-[1.0625rem]"
  const notPreviewNotEditingClass =
    !(isPreview || isEditing) && channel.type !== ChannelTypes.SPACE_DOCUMENT
      ? "hover:bg-background-modifier-hover"
      : ""
  const beforeStyles =
    !isPreview && (message.isMentioned() || isReplying || isHighlight)
      ? "before:pointer-events-none before:absolute before:top-0 before:bottom-0 before:left-0 before:block before:w-[2px] hover:bg-opacity-[.08]"
      : ""
  const replyOrHighlightClass =
    isReplying || isHighlight
      ? "bg-blue-500/10 transition-colors duration-200 ease-in-out before:bg-blue-500 hover:bg-blue-500"
      : ""
  const mentionedClass = message.isMentioned() ? "bg-yellow-500/10 before:bg-yellow-500 hover:bg-yellow-500" : ""

  const combinedClasses = clsx(
    styles.message,
    baseMessageClass,
    notPreviewNotGroupedClass,
    notPreviewNotEditingClass,
    beforeStyles,
    isPreview ? null : replyOrHighlightClass || mentionedClass,
  )

  const firstMessageOfDay = renderFirstMessageOfDay(channel, message, prevMessage)
  const readSeparator = renderReadSeparator(message, prevMessage ?? null, readState, shouldGroup)

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div onClick={handleAltClick}>
      {isPreview ? null : firstMessageOfDay && readSeparator ? (
        renderFirstMessageOfDayWithReadState(message)
      ) : (
        <>
          {firstMessageOfDay}
          {readSeparator}
        </>
      )}
      <div id={`message-${channel.id}-${message.id}`} className={combinedClasses} ref={hoverRef}>
        {renderMessageComponent()}
        {!isPreview && message.state !== MessageStates.SENDING && !isEditing && (
          <MessageActionBar message={message} handleDelete={handleDelete} />
        )}
      </div>
    </div>
  )
}
