import React from "react"

type SliderProps = {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
}

export const Slider = ({value, onChange, min = 0, max = 10, step = 1}: SliderProps) => {
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const thumbRef = React.useRef<HTMLDivElement>(null)

  const calculateValue = (clientX: number) => {
    if (!sliderRef.current) {
      return
    }
    const rect = sliderRef.current.getBoundingClientRect()
    const newValue = Math.min(Math.max(((clientX - rect.left) / rect.width) * (max - min) + min, min), max)
    onChange(Math.round(newValue / step) * step)
  }

  const handleMouseMove = (event: MouseEvent) => {
    calculateValue(event.clientX)
  }

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove)
    document.removeEventListener("mouseup", handleMouseUp)
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (event.target === thumbRef.current) {
      document.addEventListener("mousemove", handleMouseMove)
      document.addEventListener("mouseup", handleMouseUp)
    } else {
      calculateValue(event.clientX)
      document.addEventListener("mousemove", handleMouseMove)
      document.addEventListener("mouseup", handleMouseUp)
    }
  }

  const handleTouchMove = (event: TouchEvent) => {
    calculateValue(event.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove)
    document.removeEventListener("touchend", handleTouchEnd)
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (event.target === thumbRef.current) {
      document.addEventListener("touchmove", handleTouchMove)
      document.addEventListener("touchend", handleTouchEnd)
    } else {
      calculateValue(event.touches[0].clientX)
      document.addEventListener("touchmove", handleTouchMove)
      document.addEventListener("touchend", handleTouchEnd)
    }
  }

  return (
    <div
      ref={sliderRef}
      className="relative h-3 w-full cursor-pointer rounded-md bg-gray-200"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <div
        className="absolute h-full rounded-md bg-brand-primary"
        style={{width: `${((value - min) / (max - min)) * 100}%`}}
      />
      <div
        ref={thumbRef}
        className="-translate-y-1/2 absolute top-1/2 h-8 w-4 transform cursor-grab rounded-md bg-brand-primary-fill shadow-md active:cursor-grabbing"
        style={{
          left: `${((value - min) / (max - min)) * 100}%`,
          transform: "translate(-50%, -50%)",
        }}
        onMouseDown={(e) => handleMouseDown(e)}
        onTouchStart={(e) => handleTouchStart(e)}
      />
    </div>
  )
}
