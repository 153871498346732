import clsx from "clsx"
import React from "react"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {MessageReactionsModal} from "~/components/modals/MessageReactionsModal"
import styles from "~/components/popouts/ReactionTooltip.module.css"
import type {MessageReaction, MessageRecord} from "~/records/MessageRecord"
import type {UserRecord} from "~/records/UserRecord"
import MessageReactionsStore from "~/stores/MessageReactionsStore"
import markupStyles from "~/styles/Markup.module.css"
import {getReactionTooltip, useEmojiURL} from "~/utils/ReactionUtils"

export const ReactionTooltipPopout = ({message, reaction}: {message: MessageRecord; reaction: MessageReaction}) => {
  const [reactors, setReactors] = React.useState<Array<UserRecord>>([])

  React.useEffect(() => {
    const handleReactions = () => {
      setReactors(MessageReactionsStore.getReactions(message.channelId, message.id, reaction.emoji))
    }
    const unsubscribe = MessageReactionsStore.subscribe(handleReactions)
    handleReactions()
    return unsubscribe
  }, [message.channelId, message.id, reaction.emoji])

  const emojiUrl = useEmojiURL({emoji: reaction.emoji, isHovering: true})
  const tooltipText = reactors.length > 0 ? getReactionTooltip(message, reaction.emoji) : ""

  const handleClick = () => {
    ModalActionCreators.push(() => (
      <MessageReactionsModal channelId={message.channelId} messageId={message.id} openToReaction={reaction} />
    ))
  }

  return (
    <div className={styles.reactionTooltip}>
      <div
        className={styles.reactionTooltipInner}
        onClick={handleClick}
        onKeyDown={(event) => event.key === "Enter" && handleClick()}
        role="button"
        tabIndex={0}
      >
        <img
          src={emojiUrl}
          alt={reaction.emoji.name}
          draggable={false}
          className={clsx(markupStyles.emoji, styles.reactionTooltipEmoji, markupStyles.jumboable)}
        />
        <div className={styles.reactionTooltipText}>{tooltipText}</div>
      </div>
    </div>
  )
}
