import {type StatusType, StatusTypes, type ThemeType, ThemeTypes} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"

export type FavoriteGif = {
  url: string
  src: string
  proxy_src: string
  width: number
  height: number
}

export type SpaceFolder = {
  id: number | null
  name: string | null
  color: number | null
  space_ids: Array<string>
}

export type CustomStatus = {
  text: string | null
  expires_at: number | null
  emoji_id: string | null
  emoji_name: string | null
}

export type UserSettings = {
  status: StatusType
  theme: ThemeType
  custom_css: string | null
  space_positions: Array<string>
  favorite_gifs: Array<FavoriteGif>
  locale: string
  restricted_spaces: Array<string>
  default_spaces_restricted: boolean
  inline_attachment_media: boolean
  inline_embed_media: boolean
  gif_auto_play: boolean
  render_embeds: boolean
  render_reactions: boolean
  animate_emoji: boolean
  message_display_compact: boolean
  friend_source_flags: number
  space_folders: Array<SpaceFolder>
  custom_status: CustomStatus | null
}

const initialState: UserSettings = {
  status: StatusTypes.ONLINE,
  theme: ThemeTypes.DARK,
  custom_css: null,
  space_positions: [],
  favorite_gifs: [],
  locale: "en_US",
  restricted_spaces: [],
  default_spaces_restricted: false,
  inline_attachment_media: true,
  inline_embed_media: true,
  gif_auto_play: true,
  render_embeds: true,
  render_reactions: true,
  animate_emoji: true,
  message_display_compact: false,
  friend_source_flags: 0,
  space_folders: [],
  custom_status: null,
}

class UserSettingsStore extends PersistedStore<UserSettings> {
  constructor() {
    super(initialState, "UserSettingsStore", 1, ["theme", "custom_css"])
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
      case "USER_SETTINGS_UPDATE":
        return this.handleUserSettingsUpdate(action)
      default:
        return false
    }
  }

  getStatus() {
    return this.state.status
  }

  getTheme() {
    return this.state.theme
  }

  getCustomCSS() {
    return this.state.custom_css
  }

  getSpacePositions() {
    return this.state.space_positions
  }

  getFavoriteGifs() {
    return this.state.favorite_gifs
  }

  getLocale() {
    return this.state.locale
  }

  getRestrictedSpaces() {
    return this.state.restricted_spaces
  }

  getDefaultSpacesRestricted() {
    return this.state.default_spaces_restricted
  }

  getInlineAttachmentMedia() {
    return this.state.inline_attachment_media
  }

  getInlineEmbedMedia() {
    return this.state.inline_embed_media
  }

  getGifAutoPlay() {
    return this.state.gif_auto_play
  }

  getRenderEmbeds() {
    return this.state.render_embeds
  }

  getRenderReactions() {
    return this.state.render_reactions
  }

  getAnimateEmoji() {
    return this.state.animate_emoji
  }

  getMessageDisplayCompact() {
    return this.state.message_display_compact
  }

  getFriendSourceFlags() {
    return this.state.friend_source_flags
  }

  getSpaceFolders() {
    return this.state.space_folders
  }

  getCustomStatus() {
    return this.state.custom_status
  }

  private handleUserSettingsUpdate({userSettings}: {userSettings: UserSettings}) {
    this.setState(userSettings)
  }
}

export default new UserSettingsStore()
