import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {Channel} from "~/records/ChannelRecord"
import {type Message, type MessagePartial, MessageRecord} from "~/records/MessageRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import type {ReactionEmoji} from "~/utils/ReactionUtils"

type State = {
  savedMessages: Array<MessageRecord>
  fetched: boolean
}

const initialState: State = {
  savedMessages: [],
  fetched: false,
}

class SavedMessagesStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "SAVED_MESSAGES_FETCH_SUCCESS":
        return this.handleSavedMessagesFetchSuccess(action)
      case "SAVED_MESSAGES_FETCH_ERROR":
        return this.handleSavedMessagesFetchError()
      case "CHANNEL_DELETE":
        return this.handleChannelDelete(action)
      case "MESSAGE_UPDATE":
        return this.handleMessageUpdate(action)
      case "MESSAGE_DELETE":
        return this.handleMessageDelete(action)
      case "SAVED_MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      case "SAVED_MESSAGE_DELETE":
        return this.handleMessageDelete(action)
      case "MESSAGE_REACTION_ADD":
        return this.handleMessageReactionAdd(action)
      case "MESSAGE_REACTION_REMOVE":
        return this.handleMessageReactionRemove(action)
      case "MESSAGE_REACTION_REMOVE_ALL":
        return this.handleMessageReactionRemoveAll(action)
      case "MESSAGE_REACTION_REMOVE_EMOJI":
        return this.handleMessageReactionRemoveEmoji(action)
      default:
        return false
    }
  }

  useSavedMessage(messageId: string): boolean {
    const {savedMessages} = this.useStore()
    return savedMessages.some((message) => message.id === messageId)
  }

  private handleSavedMessagesFetchSuccess({messages}: {messages: Array<Message>}) {
    this.setState({savedMessages: messages.map((message) => new MessageRecord(message)), fetched: true})
  }

  private handleSavedMessagesFetchError() {
    this.setState(initialState)
  }

  private handleChannelDelete({channel}: {channel: Channel}) {
    this.setState((state) => {
      const savedMessages = state.savedMessages.filter((message) => message.channelId !== channel.id)
      return {...state, savedMessages}
    })
  }

  private handleMessageUpdate({message}: {message: Message | MessagePartial}) {
    this.setState((state) => {
      const savedMessages = state.savedMessages
      const existingMessageIndex = savedMessages.findIndex((m) => m.id === message.id)
      if (existingMessageIndex !== -1) {
        const existingMessage = savedMessages[existingMessageIndex]
        const updatedMessage = existingMessage.updateMessage(message)
        savedMessages[existingMessageIndex] = updatedMessage
        return {...state, savedMessages}
      }
      return state
    })
  }

  private handleMessageDelete({messageId}: {messageId: string}) {
    this.setState((state) => {
      const savedMessages = state.savedMessages.filter((message) => message.id !== messageId)
      return {...state, savedMessages}
    })
  }

  private handleMessageCreate({message}: {message: Message}) {
    this.setState((state) => {
      const savedMessages = [new MessageRecord(message), ...state.savedMessages]
      return {...state, savedMessages}
    })
  }

  private handleMessageReactionAdd({
    messageId,
    userId,
    emoji,
  }: {messageId: string; userId: string; emoji: ReactionEmoji}) {
    const savedMessages = this.state.savedMessages
    const existingMessageIndex = savedMessages.findIndex((m) => m.id === messageId)
    if (existingMessageIndex !== -1) {
      const existingMessage = savedMessages[existingMessageIndex]
      const updatedMessage = existingMessage.addReaction(emoji, userId === AuthenticationStore.getId())
      savedMessages[existingMessageIndex] = updatedMessage
      this.setState((prevState) => ({
        ...prevState,
        savedMessages: [...savedMessages],
      }))
    }
  }

  private handleMessageReactionRemove({
    messageId,
    userId,
    emoji,
  }: {messageId: string; userId: string; emoji: ReactionEmoji}) {
    const savedMessages = this.state.savedMessages
    const existingMessageIndex = savedMessages.findIndex((m) => m.id === messageId)
    if (existingMessageIndex !== -1) {
      const existingMessage = savedMessages[existingMessageIndex]
      const updatedMessage = existingMessage.removeReaction(emoji, userId === AuthenticationStore.getId())
      savedMessages[existingMessageIndex] = updatedMessage
      this.setState((prevState) => ({
        ...prevState,
        savedMessages: [...savedMessages],
      }))
    }
  }

  private handleMessageReactionRemoveAll({messageId}: {messageId: string}) {
    const savedMessages = this.state.savedMessages
    const existingMessageIndex = savedMessages.findIndex((m) => m.id === messageId)
    if (existingMessageIndex !== -1) {
      const existingMessage = savedMessages[existingMessageIndex]
      existingMessage.reactions = []
      savedMessages[existingMessageIndex] = existingMessage
      this.setState((prevState) => ({
        ...prevState,
        savedMessages: [...savedMessages],
      }))
    }
  }

  private handleMessageReactionRemoveEmoji({messageId, emoji}: {messageId: string; emoji: ReactionEmoji}) {
    const savedMessages = this.state.savedMessages
    const existingMessageIndex = savedMessages.findIndex((m) => m.id === messageId)
    if (existingMessageIndex !== -1) {
      const existingMessage = savedMessages[existingMessageIndex]
      const updatedMessage = existingMessage.removeReactionEmoji(emoji)
      savedMessages[existingMessageIndex] = updatedMessage
      this.setState((prevState) => ({
        ...prevState,
        savedMessages: [...savedMessages],
      }))
    }
  }
}

export default new SavedMessagesStore()
