import {Endpoints} from "~/Endpoints"
import * as HttpClient from "~/lib/HttpClient"
import type {BackupCode} from "~/records/UserRecord"

export const enableMfaTotp = async (secret: string, code: string) => {
  const {body} = await HttpClient.post<{backup_codes: Array<BackupCode>}>({
    url: Endpoints.USER_MFA_TOTP_ENABLE,
    body: {secret, code},
  })
  return body.backup_codes
}

export const disableMfaTotp = async (code: string, password: string) => {
  await HttpClient.post({
    url: Endpoints.USER_MFA_TOTP_DISABLE,
    body: {code, password},
  })
}

export const getBackupCodes = async (password: string, regenerate = false) => {
  const {body} = await HttpClient.post<{backup_codes: Array<BackupCode>}>({
    url: Endpoints.USER_MFA_BACKUP_CODES,
    body: {password, regenerate},
  })
  return body.backup_codes
}
