import clsx from "clsx"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"
import {UserTypes} from "~/Constants"
import * as UserNoteActionCreators from "~/actions/UserNoteActionCreators"
import * as UserSettingsModalActionCreators from "~/actions/UserSettingsModalActionCreators"
import {UserTag} from "~/components/channel/UserTag"
import {FluxerIcon} from "~/components/icons/FluxerIcon"
import * as Modal from "~/components/modals/Modal"
import {SpaceIcon} from "~/components/popouts/SpaceIcon"
import {UserProfileSection} from "~/components/popouts/UserProfilePopout"
import {Avatar} from "~/components/uikit/Avatar"
import {Button} from "~/components/uikit/Button/Button"
import {Scroller} from "~/components/uikit/Scroller"
import {Tooltip} from "~/components/uikit/Tooltip/Tooltip"
import {useForceUpdate} from "~/hooks/useForceUpdate"
import {i18n} from "~/i18n"
import {EditIcon} from "~/icons/EditIcon"
import {LocationIcon} from "~/icons/LocationIcon"
import {MessageIcon} from "~/icons/MessageIcon"
import {ScheduleIcon} from "~/icons/ScheduleIcon"
import {UserIcon} from "~/icons/UserIcon"
import type {ProfileRecord} from "~/records/ProfileRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import PresenceStore from "~/stores/PresenceStore"
import UserNoteStore from "~/stores/UserNoteStore"
import UserStore from "~/stores/UserStore"
import markupStyles from "~/styles/Markup.module.css"
import * as DateUtils from "~/utils/DateUtils"
import * as MarkupUtils from "~/utils/MarkupUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"
import * as TimezoneUtils from "~/utils/TimezoneUtils"

export const UserProfileModal = ({
  userId,
  profile,
  autoFocusNote,
}: {
  userId: string
  profile: ProfileRecord
  autoFocusNote?: boolean
}) => {
  const [isEditingNote, setIsEditingNote] = React.useState(false)
  const [localNote, setLocalNote] = React.useState<string | null>(null)
  const noteRef = React.useRef<HTMLTextAreaElement | null>(null)
  const forceUpdate = useForceUpdate()
  const status = PresenceStore.useUserStatus(userId)
  const timeAndOffset = profile?.timezoneOffset ? TimezoneUtils.getTimeAndOffset(profile.timezoneOffset) : null
  const user = UserStore.useUser(userId)!
  const userNote = UserNoteStore.useUserNote(userId)

  React.useEffect(() => {
    if (autoFocusNote && noteRef.current) {
      noteRef.current.focus()
    }
  }, [autoFocusNote])

  React.useEffect(() => {
    if (profile?.timezoneOffset == null) {
      return
    }
    const updateOnFullMinute = () => {
      forceUpdate()
      setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000))
    }
    const timeout = setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000))
    return () => clearTimeout(timeout)
  }, [forceUpdate, profile?.timezoneOffset])

  const isCurrentUser = user.id === AuthenticationStore.getId()
  return (
    <Modal.Root
      label={`User Profile: @${user.handle}`}
      size="small"
      className="flex h-[780px] w-[600px] flex-col overflow-hidden rounded-md border-4 border-brand-primary bg-background-primary"
    >
      <header>
        <div className="h-[210px] w-[600px] bg-brand-primary" />

        <div className="relative min-h-[38px] px-4 pt-4">
          <div className="-top-[68px] absolute left-[14px] z-0 rounded-full border-[8px] border-background-primary bg-background-primary">
            <Avatar forceAnimate={true} size={120} status={status} user={user} />
          </div>

          <div className="flex h-[38px] min-h-[38px] items-center justify-end gap-2">
            {isCurrentUser ? (
              <Button
                variant="brand"
                small={true}
                leftIcon={<EditIcon className="h-4 w-4" />}
                onClick={() => UserSettingsModalActionCreators.openUserSettingsTab("edit_profile")}
              >
                {i18n.Messages.EDIT_PROFILE}
              </Button>
            ) : (
              <Button variant="brand" small={true} leftIcon={<MessageIcon className="h-4 w-4" />}>
                {i18n.Messages.MESSAGE}
              </Button>
            )}
          </div>
        </div>
      </header>

      <div className="z-elevated-1 m-4 flex h-full flex-col gap-[12px] overflow-hidden">
        <div className="select-text">
          <div className="flex items-center gap-0.5">
            <span className="inline whitespace-normal break-words break-all align-middle font-bold text-2xl text-text-primary leading-[1.25]">
              {NicknameUtils.getNickname(user)}
            </span>
          </div>

          <div className="block overflow-hidden font-medium text-[14px] text-text-tertiary leading-[18px]">
            <span className="inline overflow-auto whitespace-normal break-all align-middle">@{user.handle}</span>

            {user.pronouns && (
              <div
                aria-hidden={true}
                className="ml-1 inline-block h-1 w-1 rounded-full bg-text-primary-muted align-middle"
              />
            )}

            {user.pronouns && (
              <Tooltip text={i18n.Messages.PRONOUNS}>
                <span className="ml-1 align-middle">{user.pronouns}</span>
              </Tooltip>
            )}

            <div className="inline">{user.type === UserTypes.AUTOMATED && <UserTag className="mt-[.3em]" />}</div>
          </div>
        </div>

        {profile && profile.badges.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {profile.badges.map((badge) => (
              <span
                key={badge.id}
                className="inline-flex items-center gap-1 rounded-md border border-brand-primary-light px-2 py-1 font-semibold text-brand-primary-light text-xs"
              >
                <UserIcon className="h-3 w-3" aria-hidden={true} />
                <span>{badge.name}</span>
                <span aria-hidden={true} className="h-1 w-1 rounded-full bg-brand-primary-light" />
                <span className="font-medium opacity-75">{profile.domain}</span>
              </span>
            ))}
          </div>
        )}

        <div className="h-full overflow-auto rounded-md border border-background-header-secondary bg-background-primary">
          <Scroller className="relative flex h-full min-h-0 flex-auto flex-col gap-5 p-4">
            {(profile?.userProfile.biography || profile?.userProfile.location || timeAndOffset) && (
              <div className="flex flex-col gap-2">
                {profile?.userProfile.biography && (
                  <div className={clsx(markupStyles.markup, markupStyles.biography)}>
                    {MarkupUtils.parseProfileBio(profile.userProfile.biography)}
                  </div>
                )}

                {(profile.userProfile.location || timeAndOffset) && (
                  <div className="flex flex-col gap-0.5">
                    {profile.userProfile.location && (
                      <UserProfileSection icon={LocationIcon} label={i18n.Messages.LOCATION}>
                        <span className="select-text text-sm text-text-primary">{profile.userProfile.location}</span>
                      </UserProfileSection>
                    )}

                    {timeAndOffset && (
                      <UserProfileSection icon={ScheduleIcon} label={i18n.Messages.LOCAL_TIME}>
                        <span className="select-text text-sm text-text-primary">
                          {timeAndOffset.time} <span className="text-text-primary-muted">— {timeAndOffset.offset}</span>
                        </span>
                      </UserProfileSection>
                    )}
                  </div>
                )}
              </div>
            )}

            {profile?.space && profile.spaceMember ? (
              <div className="flex flex-col gap-1">
                <span className="font-semibold text-sm text-text-primary">{i18n.Messages.MEMBER_SINCE}</span>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-1">
                    <Tooltip text="Fluxer">
                      <div className="cursor-pointer">
                        <FluxerIcon className="h-4 w-4 text-text-chat" />
                      </div>
                    </Tooltip>
                    <span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Tooltip text={profile.space.name}>
                      <div className="cursor-pointer">
                        <SpaceIcon
                          id={profile.space.id}
                          name={profile.space.name}
                          icon={profile.space.icon}
                          className="h-4 w-4 text-[8px]"
                        />
                      </div>
                    </Tooltip>
                    <span className="text-sm text-text-chat">
                      {DateUtils.getFormattedShortDate(profile.spaceMember.joinedAt)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <span className="font-semibold text-sm text-text-primary">{i18n.Messages.FLUXER_MEMBER_SINCE}</span>
                <span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
              </div>
            )}

            <div className="flex flex-col gap-1">
              <span className="font-semibold text-sm text-text-primary">{i18n.Messages.NOTE}</span>
              <TextareaAutosize
                ref={noteRef}
                aria-label={i18n.Messages.NOTE}
                className={clsx(
                  "no-scrollbar relative flex max-h-[88px] min-h-[44px] w-full resize-none overflow-x-hidden overflow-y-scroll whitespace-pre-wrap break-words rounded-md p-1 text-[14px] leading-[16px]",
                  isEditingNote
                    ? "border border-background-header-secondary bg-background-secondary"
                    : "border border-transparent bg-transparent",
                )}
                defaultValue={userNote ?? undefined}
                maxLength={256}
                onBlur={() => {
                  if (localNote != null && localNote !== userNote) {
                    UserNoteActionCreators.update(user.id, localNote)
                  }
                  setIsEditingNote(false)
                }}
                onChange={(event) => setLocalNote(event.target.value)}
                onFocus={() => {
                  setIsEditingNote(true)
                  if (noteRef.current) {
                    noteRef.current.setSelectionRange(noteRef.current.value.length, noteRef.current.value.length)
                  }
                }}
                placeholder={isEditingNote ? undefined : i18n.Messages.NOTE_PLACEHOLDER}
              />
            </div>
          </Scroller>
        </div>
      </div>
    </Modal.Root>
  )
}
