import {Endpoints} from "~/Endpoints"
import * as ModalActionCreators from "~/actions/ModalActionCreators"
import {AttachmentUploadFailedModal} from "~/components/alerts/AttachmentUploadFailedModal"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import DeveloperOptionsStore from "~/stores/DeveloperOptionsStore"
import type {UploadAttachment} from "~/stores/UploadAttachmentStore"
import UploadAttachmentStore from "~/stores/UploadAttachmentStore"

const FILENAME_SPOILER_REGEX = /^SPOILER_/

let nextAttachmentId = 1

const isImage = (file: File) => file.type.startsWith("image/")
const isVideo = (file: File) => file.type.startsWith("video/")
const isImageOrVideo = (file: File) => isImage(file) || isVideo(file)

const getImageDimensions = async (file: File) => {
  const url = URL.createObjectURL(file)
  const img = new Image()
  img.src = url
  await img.decode()
  const {naturalWidth: width, naturalHeight: height} = img
  URL.revokeObjectURL(url)
  return {width, height}
}

const getVideoDimensions = (file: File) =>
  new Promise<{width: number; height: number}>((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const video = document.createElement("video")

    video.addEventListener("loadedmetadata", () => {
      const {videoWidth: width, videoHeight: height} = video
      URL.revokeObjectURL(url)
      resolve({width, height})
    })

    video.addEventListener("error", (e) => {
      URL.revokeObjectURL(url)
      reject(e)
    })

    video.src = url
  })

const createAttachments = async (channelId: string, files: Array<File>): Promise<Array<UploadAttachment>> =>
  Promise.all(
    files.map(async (file) => {
      let width = 0
      let height = 0

      try {
        if (isImage(file)) {
          ;({width, height} = await getImageDimensions(file))
        } else if (isVideo(file)) {
          ;({width, height} = await getVideoDimensions(file))
        }
      } catch (error) {
        console.error("Error getting file dimensions:", error)
      }

      return {
        id: nextAttachmentId++,
        channelId,
        file,
        filename: file.name.replace(FILENAME_SPOILER_REGEX, ""),
        spoiler: file.name.startsWith("SPOILER_"),
        previewURL: isImageOrVideo(file) ? URL.createObjectURL(file) : null,
        status: "pending",
        width,
        height,
      }
    }),
  )

const startUpload = async (channelId: string, attachment: UploadAttachment) => {
  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_START_UPLOAD",
    channelId,
    attachmentId: attachment.id,
  })

  try {
    if (DeveloperOptionsStore.getSlowAttachmentUpload()) {
      await new Promise((resolve) => setTimeout(resolve, 5000))
    }

    const response = await fetch(attachment.uploadURL!, {
      method: "PUT",
      body: await attachment.file.arrayBuffer(),
      headers: {
        "Content-Type": "application/octet-stream",
        "Content-Length": attachment.file.size.toString(),
      },
    })

    if (!response.ok) {
      throw new Error(`Upload failed for attachment ${attachment.file.name}`)
    }

    Dispatcher.dispatch({
      type: "UPLOAD_ATTACHMENT_UPLOAD_COMPLETE",
      channelId,
      attachmentId: attachment.id,
      uploadURL: attachment.uploadURL!,
      uploadFilename: attachment.uploadFilename!,
    })
  } catch (error) {
    console.error("Upload failed:", error)
    ModalActionCreators.push(() => <AttachmentUploadFailedModal />)
    Dispatcher.dispatch({
      type: "UPLOAD_ATTACHMENT_UPLOAD_FAILED",
      channelId,
      attachmentId: attachment.id,
    })
  }
}

export const upload = async ({channelId, files}: {channelId: string; files: Array<File>}) => {
  const attachments = await createAttachments(channelId, files)

  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_CREATE",
    channelId,
    attachments,
  })

  try {
    if (DeveloperOptionsStore.getForceFailUploads()) {
      throw new Error("Forced upload failure")
    }

    const response = await HttpClient.post<{
      attachments: Array<{id: number; upload_url: string; upload_filename: string}>
    }>({
      url: Endpoints.CHANNEL_ATTACHMENTS(channelId),
      body: {
        files: attachments.map((attachment) => ({
          id: attachment.id,
          filename: attachment.file.name,
          file_size: attachment.file.size,
        })),
      },
    })

    if (response.status !== 200) {
      throw new Error("Failed to request upload URLs")
    }

    const {attachments: uploadUrLs} = response.body
    const updatedAttachments = attachments.map((attachment) => {
      const uploadData = uploadUrLs.find((a: any) => a.id === attachment.id)
      if (uploadData) {
        return {
          ...attachment,
          uploadURL: uploadData.upload_url,
          uploadFilename: uploadData.upload_filename,
        }
      }
      return attachment
    })

    for (const attachment of updatedAttachments) {
      Dispatcher.dispatch({
        type: "UPLOAD_ATTACHMENT_UPDATE",
        channelId,
        attachmentId: attachment.id,
        patch: attachment,
      })
      startUpload(channelId, attachment)
    }
  } catch (error) {
    console.error("Failed to request upload URLs:", error)
    ModalActionCreators.push(() => <AttachmentUploadFailedModal />)
    for (const attachment of attachments) {
      Dispatcher.dispatch({
        type: "UPLOAD_ATTACHMENT_UPLOAD_FAILED",
        channelId,
        attachmentId: attachment.id,
      })
    }
  }
}

export const update = ({
  channelId,
  attachmentId,
  filename,
  spoiler,
}: {
  channelId: string
  attachmentId: number
  filename?: string
  spoiler?: boolean
}) =>
  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_UPDATE",
    channelId,
    attachmentId,
    patch: {filename, spoiler},
  })

export const remove = ({channelId, attachmentId}: {channelId: string; attachmentId: number}) =>
  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_DELETE",
    channelId,
    attachmentId,
  })

export const replace = ({channelId, attachments}: {channelId: string; attachments: Array<UploadAttachment>}) =>
  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_REPLACE",
    channelId,
    attachments,
  })

export const retryUpload = async ({channelId, attachmentId}: {channelId: string; attachmentId: number}) => {
  const attachment = UploadAttachmentStore.getUploadAttachments(channelId).find((a) => a.id === attachmentId)

  if (!attachment) {
    console.error("Attachment not found")
    return
  }

  Dispatcher.dispatch({
    type: "UPLOAD_ATTACHMENT_UPDATE",
    channelId,
    attachmentId,
    patch: {status: "pending"},
  })

  startUpload(channelId, attachment)
}
