import {i18n} from "~/i18n"
import * as UnicodeEmojis from "~/lib/UnicodeEmojis"
import type {UnicodeEmoji} from "~/lib/UnicodeEmojis"
import type {MessageRecord} from "~/records/MessageRecord"
import ChannelStore from "~/stores/ChannelStore"
import MessageReactionsStore from "~/stores/MessageReactionsStore"
import UserSettingsStore from "~/stores/UserSettingsStore"
import * as AvatarUtils from "~/utils/AvatarUtils"
import * as EmojiUtils from "~/utils/EmojiUtils"
import * as NicknameUtils from "~/utils/NicknameUtils"

export type ReactionEmoji = {id?: string; name: string; animated?: boolean}

export const getReactionTooltip = (message: MessageRecord, emoji: ReactionEmoji) => {
  const channel = ChannelStore.getChannel(message.channelId)!
  const spaceId = channel.spaceId
  const users = MessageReactionsStore.getReactions(message.channelId, message.id, emoji)
    .slice(0, 3)
    .map((user) => NicknameUtils.getNickname(user, spaceId))

  if (users.length === 0) {
    return ""
  }

  const reaction = message.getReaction(emoji)
  const othersCount = Math.max(0, (reaction?.count || 0) - users.length)
  const emojiName = getEmojiName(emoji)

  if (users.length === 1) {
    if (othersCount > 0) {
      return i18n.format(i18n.Messages.REACTION_TOOLTIP_1_N, {a: users[0], n: othersCount, emojiName})
    }
    return i18n.format(i18n.Messages.REACTION_TOOLTIP_1, {a: users[0], emojiName})
  }

  if (users.length === 2) {
    if (othersCount > 0) {
      return i18n.format(i18n.Messages.REACTION_TOOLTIP_2_N, {a: users[0], b: users[1], n: othersCount, emojiName})
    }
    return i18n.format(i18n.Messages.REACTION_TOOLTIP_2, {a: users[0], b: users[1], emojiName})
  }

  if (users.length === 3) {
    if (othersCount > 0) {
      return i18n.format(i18n.Messages.REACTION_TOOLTIP_3_N, {
        a: users[0],
        b: users[1],
        c: users[2],
        n: othersCount,
        emojiName,
      })
    }
    return i18n.format(i18n.Messages.REACTION_TOOLTIP_3, {a: users[0], b: users[1], c: users[2], emojiName})
  }

  return i18n.format(i18n.Messages.REACTION_TOOLTIP_N, {n: othersCount, emojiName})
}

const isCustomEmoji = (emoji: UnicodeEmoji | ReactionEmoji): emoji is ReactionEmoji => (emoji as any).id != null

export const toReactionEmoji = (emoji: UnicodeEmoji | ReactionEmoji) =>
  isCustomEmoji(emoji) ? emoji : {name: emoji.surrogatePair}

export const emojiEquals = (reactionEmoji: ReactionEmoji, emoji: UnicodeEmoji | ReactionEmoji) =>
  isCustomEmoji(emoji) ? emoji.id === reactionEmoji.id : reactionEmoji.id == null && emoji.name === reactionEmoji.name

export const getReactionKey = (messageId: string, emoji: ReactionEmoji) =>
  `${messageId}:${emoji.name}:${emoji.id || ""}`

export const getEmojiName = (emoji: ReactionEmoji) =>
  emoji.id == null ? UnicodeEmojis.convertSurrogateToName(emoji.name) : `:${emoji.name}:`

export const useEmojiURL = ({
  emoji,
  isHovering = false,
  size = 64,
}: {
  emoji: ReactionEmoji
  isHovering?: boolean
  size?: number
}) => {
  const {animate_emoji: animateEmoji} = UserSettingsStore.useStore()

  let shouldAnimate = false
  if (animateEmoji) {
    shouldAnimate = emoji.animated ?? false
  } else if (emoji.animated) {
    shouldAnimate = isHovering
  }

  if (emoji.id == null) {
    return EmojiUtils.getURL(emoji.name)
  }

  const url = AvatarUtils.getEmojiURL({id: emoji.id, animated: shouldAnimate})
  return `${url}?size=${size}&quality=lossless`
}
