import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  notes: Record<string, string>
}

const initialState: State = {
  notes: {},
}

class UserNoteStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "USER_NOTE_UPDATE":
        return this.handleUserNoteUpdate(action)
      default:
        return false
    }
  }

  useUserNote(userId: string): string {
    const {notes} = this.useStore()
    return notes[userId] || ""
  }

  private handleConnectionOpen({notes}: {notes: Record<string, string>}) {
    this.setState(() => ({notes}))
  }

  private handleUserNoteUpdate({userId, note}: {userId: string; note: string}) {
    this.setState((prevState) => ({
      notes: {
        ...prevState.notes,
        [userId]: note,
      },
    }))
  }
}

export default new UserNoteStore()
