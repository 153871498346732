import {EmojiPicker} from "~/components/channel/EmojiPicker"
import type {Emoji} from "~/stores/EmojiStore"

export const EmojiPickerPopout = ({
  channelId,
  handleSelect,
}: {
  channelId: string
  handleSelect: (emoji: Emoji) => void
}) => (
  <div className="relative grid h-[498px] w-[498px] grid-rows-[auto] gap-2 overflow-hidden rounded-md border border-background-header-secondary bg-background-primary pt-4">
    <div className="relative grid grid-rows-[1fr_auto] overflow-hidden rounded-md">
      <EmojiPicker channelId={channelId} handleSelect={handleSelect} />
    </div>
  </div>
)
