import type {ModalRender} from "~/actions/ModalActionCreators"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type Modal = {
  modal: ModalRender
  props?: Record<string, any>
  key: string
}

type State = {
  modals: Array<Modal>
}

const initialState: State = {
  modals: [],
}

class ModalStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "MODAL_PUSH":
        return this.handleModalPush(action)
      case "MODAL_UPDATE":
        return this.handleModalUpdate(action)
      case "MODAL_POP":
        return this.handleModalPop(action)
      case "MODAL_POP_ALL":
        return this.handleModalPopAll()
      default:
        return false
    }
  }

  getModal() {
    return this.state.modals.at(-1)
  }

  hasModalOpen() {
    return this.state.modals.length > 0
  }

  isModalOpen(modalType: ModalRender) {
    return this.state.modals.some((modal) => modal.modal === modalType)
  }

  private handleModalPush({
    modal,
    props,
    key,
  }: {
    modal: ModalRender
    props?: Record<string, any>
    key: string | number
  }) {
    this.setState((prevState) => {
      prevState.modals.push({modal, props, key: key.toString()})
      return {...prevState}
    })
  }

  private handleModalUpdate({
    key,
    props,
    partial,
  }: {
    key: string | number
    props: Record<string, any>
    partial: boolean
  }) {
    this.setState((prevState) => {
      const modal = prevState.modals.find((modal) => modal.key === key)
      if (!modal) {
        return prevState
      }

      if (partial) {
        modal.props = {...modal.props, ...props}
      } else {
        modal.props = props
      }

      return {...prevState}
    })
  }

  private handleModalPop({key}: {key?: string | number} = {}) {
    this.setState((prevState) => {
      if (key) {
        prevState.modals = prevState.modals.filter((modal) => modal.key !== key)
      } else {
        prevState.modals.pop()
      }

      return {...prevState}
    })
  }

  private handleModalPopAll() {
    this.setState((prevState) => {
      prevState.modals = []
      return {...prevState}
    })
  }
}

export default new ModalStore()
