import type {CSSProperties} from "react"

/**
 * Represents the dimensions of a media element
 */
export type MediaDimensions = {
  width: number
  height: number
}

/**
 * Configuration options for dimension calculations
 */
export type DimensionOptions = {
  /** Maximum width constraint */
  maxWidth?: number
  /** Maximum height constraint */
  maxHeight?: number
  /** Whether to maintain original dimensions */
  preserve?: boolean
  /** Force scaling regardless of dimensions */
  forceScale?: boolean
  /** Apply specific aspect ratio */
  aspectRatio?: boolean
  /** Enable responsive behavior */
  responsive?: boolean
}

/**
 * Result of dimension calculations including CSS properties
 */
export type DimensionResult = {
  /** Calculated CSS properties */
  style: CSSProperties
  /** Scaled dimensions */
  dimensions: MediaDimensions
  /** Scaling factor applied */
  scale: number
}

/**
 * Default configuration for dimension calculations
 */
const DEFAULT_OPTIONS: Required<DimensionOptions> = {
  maxWidth: 400,
  maxHeight: 300,
  preserve: false,
  forceScale: false,
  aspectRatio: true,
  responsive: true,
}

/**
 * Media dimension calculator for handling various media types
 */
export class MediaDimensionCalculator {
  private options: Required<DimensionOptions>

  constructor(options?: DimensionOptions) {
    this.options = {...DEFAULT_OPTIONS, ...options}
  }

  /**
   * Calculate dimensions and styling for media elements
   */
  public calculate(dimensions: MediaDimensions, options?: DimensionOptions): DimensionResult {
    const config = {...this.options, ...options}

    if (config.preserve) {
      return this.preserveDimensions(dimensions)
    }

    if (config.forceScale) {
      return this.forceScaleDimensions(dimensions, config)
    }

    return this.calculateResponsiveDimensions(dimensions, config)
  }

  /**
   * Specialized calculation for images
   */
  public calculateImage(dimensions: MediaDimensions, options?: Omit<DimensionOptions, "forceScale">): DimensionResult {
    return this.calculate(dimensions, {...options, forceScale: false})
  }

  /**
   * Specialized calculation for videos
   */
  public calculateVideo(dimensions: MediaDimensions, options?: Omit<DimensionOptions, "preserve">): DimensionResult {
    return this.calculate(dimensions, {...options, preserve: false})
  }

  /**
   * Calculate dimensions while preserving original size
   */
  private preserveDimensions(dimensions: MediaDimensions): DimensionResult {
    return {
      style: {
        width: dimensions.width,
        height: dimensions.height,
      },
      dimensions: {...dimensions},
      scale: 1,
    }
  }

  /**
   * Force scale dimensions to fit within constraints
   */
  private forceScaleDimensions(dimensions: MediaDimensions, options: Required<DimensionOptions>): DimensionResult {
    const scale = Math.min(1, options.maxWidth / dimensions.width, options.maxHeight / dimensions.height)

    const scaledDimensions = {
      width: Math.round(dimensions.width * scale),
      height: Math.round(dimensions.height * scale),
    }

    return {
      style: {
        width: scaledDimensions.width,
        height: scaledDimensions.height,
        maxWidth: "100%",
        ...(options.aspectRatio && {
          aspectRatio: `${dimensions.width}/${dimensions.height}`,
        }),
      },
      dimensions: scaledDimensions,
      scale,
    }
  }

  /**
   * Calculate responsive dimensions based on orientation
   */
  private calculateResponsiveDimensions(
    dimensions: MediaDimensions,
    options: Required<DimensionOptions>,
  ): DimensionResult {
    const isPortrait = dimensions.height > dimensions.width
    let style: CSSProperties
    let scaledDimensions: MediaDimensions
    let scale: number

    if (isPortrait) {
      const targetWidth = Math.round((options.maxHeight * dimensions.width) / dimensions.height)
      const maxAllowedWidth = Math.min(options.maxWidth, targetWidth)
      scale = maxAllowedWidth / dimensions.width

      scaledDimensions = {
        width: maxAllowedWidth,
        height: Math.round(dimensions.height * scale),
      }

      style = {
        maxWidth: `${maxAllowedWidth}px`,
        width: options.responsive ? "100%" : maxAllowedWidth,
        ...(options.aspectRatio && {
          aspectRatio: `${maxAllowedWidth}/${options.maxHeight}`,
        }),
      }
    } else {
      scale = Math.min(1, options.maxWidth / dimensions.width)

      scaledDimensions = {
        width: Math.round(dimensions.width * scale),
        height: Math.round(dimensions.height * scale),
      }

      style = {
        maxWidth: `${options.maxWidth}px`,
        width: options.responsive ? "100%" : scaledDimensions.width,
        ...(options.aspectRatio && {
          aspectRatio: `${dimensions.width}/${dimensions.height}`,
        }),
      }
    }

    return {style, dimensions: scaledDimensions, scale}
  }

  /**
   * Helper method for legacy support
   */
  public static scale(
    width: number,
    height: number,
    maxWidth = DEFAULT_OPTIONS.maxWidth,
    maxHeight = DEFAULT_OPTIONS.maxHeight,
  ): [number, number] {
    const calculator = new MediaDimensionCalculator()
    const result = calculator.calculate({width, height}, {maxWidth, maxHeight, forceScale: true})
    return [result.dimensions.width, result.dimensions.height]
  }
}

/**
 * Create a pre-configured calculator instance
 */
export const createCalculator = (options?: DimensionOptions): MediaDimensionCalculator => {
  return new MediaDimensionCalculator(options)
}
