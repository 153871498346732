import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"

export type UserSettingsTabType =
  | "my_account"
  | "edit_profile"
  | "beta_codes"
  | "privacy_safety"
  | "devices"
  | "connections"
  | "friend_requests"
  | "appearance"
  | "accessibility"
  | "chat_settings"
  | "notifications"
  | "developer_options"
  | "component_gallery"

type State = {
  selectedTab: UserSettingsTabType
}

const initialState: State = {
  selectedTab: "my_account",
}

class UserSettingsModalStore extends PersistedStore<State> {
  constructor() {
    super(initialState, "UserSettingsModalStore", 1)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "USER_SETTINGS_MODAL_TAB_SELECT":
        return this.handleTabSelect(action)
      default:
        return false
    }
  }

  handleTabSelect({tab}: {tab: UserSettingsTabType}) {
    this.setState({selectedTab: tab})
  }
}

export default new UserSettingsModalStore()
