import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import type {AuthSession} from "~/records/AuthSessionRecord"

export const fetch = async () => {
  Dispatcher.dispatch({type: "AUTH_SESSIONS_FETCH_PENDING"})
  try {
    const {body} = await HttpClient.get<Array<AuthSession>>({url: Endpoints.AUTH_SESSIONS})
    Dispatcher.dispatch({
      type: "AUTH_SESSIONS_FETCH_SUCCESS",
      authSessions: body,
    })
  } catch (error) {
    Dispatcher.dispatch({type: "AUTH_SESSIONS_FETCH_ERROR", error})
    throw error
  }
}

export const logout = async (sessionIdHashes: Array<string>, password: string) => {
  Dispatcher.dispatch({type: "AUTH_SESSIONS_LOGOUT_PENDING", sessionIdHashes})
  try {
    await HttpClient.post({
      url: Endpoints.AUTH_SESSIONS_LOGOUT,
      body: {session_id_hashes: sessionIdHashes, password},
    })
    Dispatcher.dispatch({type: "AUTH_SESSIONS_LOGOUT_SUCCESS", sessionIdHashes})
  } catch (error) {
    Dispatcher.dispatch({type: "AUTH_SESSIONS_LOGOUT_ERROR", sessionIdHashes, error})
    throw error
  }
}
