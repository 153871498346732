import {ChannelFlags, MessageStates} from "~/Constants"
import type {ChannelRecord} from "~/records/ChannelRecord"
import type {MessageRecord} from "~/records/MessageRecord"
import AuthenticationStore from "~/stores/AuthenticationStore"
import type {ReadState} from "~/stores/ReadStateStore"
import SpaceMemberStore from "~/stores/SpaceMemberStore"
import * as SnowflakeUtils from "~/utils/SnowflakeUtils"

export const getPreviousMessageId = (message: MessageRecord, prevMessage: MessageRecord | null) =>
  prevMessage?.id ?? (BigInt(message.id) - 1n).toString()

export const shouldRenderSeparator = (
  message: MessageRecord,
  prevMessage: MessageRecord | null,
  readState: ReadState | null,
) =>
  message.state === MessageStates.SENT &&
  readState &&
  (readState.message_id === prevMessage?.id || readState.message_id === getPreviousMessageId(message, prevMessage))

export const hasUnreadMessages = (channel: ChannelRecord, readState: ReadState | null) => {
  if (channel.flags & ChannelFlags.MUTED) {
    return false
  }

  if (!channel.lastMessageId) {
    return false
  }

  if (channel.spaceId) {
    const member = SpaceMemberStore.getMember(channel.spaceId, AuthenticationStore.getId())
    if (!member) {
      return false
    }

    const lastMessageAt = SnowflakeUtils.extractTimestamp(channel.lastMessageId)
    if (member.joinedAt > lastMessageAt) {
      return false
    }
  }

  if (!readState?.message_id) {
    return true
  }

  return BigInt(readState.message_id) < BigInt(channel.lastMessageId)
}
