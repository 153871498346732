import * as ToastActionCreators from "~/actions/ToastActionCreators"
import {i18n} from "~/i18n"

export const copy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    ToastActionCreators.createToast({
      type: "success",
      children: i18n.Messages.COPIED_TO_CLIPBOARD,
    })
  } catch (error) {
    console.error(error)
    ToastActionCreators.createToast({
      type: "error",
      children: i18n.Messages.FAILED_TO_COPY_TO_CLIPBOARD,
    })
  }
}
